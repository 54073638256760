<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h2>Diagnóstico de inhibidores</h2>
            <h3>Programa diagnóstico temprano de inhibidores en hemofilia A</h3>
            <template v-if="loading">
                <Loading/>
            </template>
            <template v-else>
                <i-form class="doctor-service-form" ref="form" :model="form" :rules="ruleInline" inline >
                    <br>
                    <h3>Datos del Médico</h3>
                    <br>
                    <!-- Doctor name -->
                    <FormItem label="Nombre completo:" prop="doctor_name">
                        <i-input type="text" v-model="form.doctor_name" disabled placeholder="" style="width: 100%"></i-input>
                    </FormItem>

                    <!-- Doctor Professional ID -->
                    <FormItem label="Cédula profesional:"  prop="doctor_professional_id">
                        <i-input type="text" v-model="form.doctor_professional_id" placeholder="" style="width: 100%"></i-input>
                    </FormItem>

                    <!-- Doctor phone -->
                    <FormItem label="Teléfono:"  prop="doctor_phone">
                        <i-input ref="doctor_phone" type="number" v-model="form.doctor_phone" placeholder="" style="width: 100%"></i-input>
                    </FormItem>

                    <!-- Doctor email -->
                    <FormItem label="E-mail:"  prop="doctor_email">
                        <i-input type="text" v-model="form.doctor_email" disabled placeholder="" style="width: 100%"></i-input>
                    </FormItem>

                    <!-- Doctor hospital -->
                    <FormItem style="margin-top: -5px" label="Hospital en el que labora:" class="block"  prop="doctor_hospital">
                        <i-input type="text" v-model="form.doctor_hospital" placeholder="" style="width: 100%"></i-input>
                    </FormItem>
                    <hr>
                    <br>

                    
                    <h3>Datos del Paciente</h3>
                    <p style="margin-top: 10px; font-size: 0.9em">Estimado médico le agradecemos proporcionar la información de su paciente para coordinar la realización de este estudio. </p>
                    <br>

                    <!-- Patient initials -->
                    <Row :gutter="16">
                        <i-col :xs="5" :sm="5" :md="3" :lg="2">
                            <FormItem label="Iniciales" ></FormItem>
                        </i-col>
                        <i-col :xs="6" :sm="6" :md="4" :lg="4">
                            <FormItem label="" prop="patient_initials_first_last_name" class="with-footer-legend">
                                <i-input type="text" v-model="form.patient_initials_first_last_name" placeholder="" style="width: 100%"></i-input>
                                <p class="form-footer-legend center">Apellido paterno</p>
                            </FormItem>
                        </i-col>
                        <i-col :xs="6" :sm="6" :md="4" :lg="4">
                            <FormItem label="" prop="patient_initials_second_last_name" class="with-footer-legend">
                                <i-input type="text" v-model="form.patient_initials_second_last_name" placeholder="" style="width: 100%"></i-input>
                                <p class="form-footer-legend center">Apellido materno</p>
                            </FormItem>
                        </i-col>
                        <i-col :xs="6" :sm="6" :md="4" :lg="4">
                            <FormItem label="" prop="patient_initials_name" class="with-footer-legend">
                                <i-input type="text" v-model="form.patient_initials_name" placeholder="" style="width: 100%"></i-input>
                                <p class="form-footer-legend center">Nombre(s)</p>
                            </FormItem>
                        </i-col>
                    </Row>

                    <!-- Patient name -->
                    <FormItem label="Nombre completo:"  prop="patient_name">
                        <i-input type="text" v-model="form.patient_name" disabled placeholder="" style="width: 100%"></i-input>
                    </FormItem>

                    <!-- Patient phone 1 -->
                    <FormItem label="Teléfono de contacto 1:"  prop="patient_phone_1">
                        <i-input type="text" v-model="form.patient_phone_1" placeholder="" style="width: 100%"></i-input>
                    </FormItem>

                    <!-- Patient phone 2 -->
                    <FormItem label="Teléfono de contacto 2:"  prop="patient_phone_2">
                        <i-input type="text" v-model="form.patient_phone_2" placeholder="" style="width: 100%"></i-input>
                    </FormItem>

                    <!-- Patient email -->
                    <FormItem label="E-mail de contacto:"  prop="patient_email">
                        <i-input type="text" v-model="form.patient_email" disabled placeholder="" style="width: 100%"></i-input>
                    </FormItem>
                    <div style="height: 5px;"></div>

                    <!-- Patient gender -->
                    <Row :gutter="16">
                        <i-col :xs="5" :sm="5" :md="3" :lg="2">
                            <FormItem label="Género" ></FormItem>
                        </i-col>
                        <i-col span="12">
                            <FormRadioGroup @callback="validateGenderRadio">
                                <FormRadio v-model="form.patient_gender" mvalue="F" label="F" />
                                <FormRadio v-model="form.patient_gender" mvalue="M" label="M" />
                            </FormRadioGroup>
                            <div style="margin-top: -5px" class="radio-group-error-text" v-if="radio_validation.gender == false">Requerido</div>
                        </i-col>
                    </Row>
                    <div style="height: 5px;"></div>

                    <!-- Patient birthday -->
                    <Row :gutter="16">
                        <i-col :xs="11" :sm="11" :md="6" :lg="4">
                            <FormItem label="Fecha de nacimiento"></FormItem>
                        </i-col>
                        <i-col span="4">
                            <FormItem label="" prop="patient_birth_day" class="with-footer-legend">
                                <InputNumber :max="31" v-model="form.patient_birth_day" :formatter="formatWithZero" placeholder="DD" style="width: 100%"></InputNumber>
                                <p class="form-footer-legend center">Día</p>
                            </FormItem>
                        </i-col>
                        <i-col span="4">
                            <FormItem label="" prop="patient_birth_month" class="with-footer-legend">
                                <InputNumber :max="12" v-model="form.patient_birth_month" :formatter="formatWithZero" placeholder="MM" style="width: 100%"></InputNumber>
                                <p class="form-footer-legend center">Mes</p>
                            </FormItem>
                        </i-col>
                        <i-col span="5">
                            <FormItem label="" prop="patient_birth_year" class="with-footer-legend">
                                <i-input type="text" v-model="form.patient_birth_year" placeholder="AAAA" style="width: 100%"></i-input>
                                <p class="form-footer-legend center">Año</p>
                            </FormItem>
                        </i-col>
                    </Row>
                    <div style="height: 5px;"></div>

                    <!-- Patient hemophilia type -->
                    <Row>
                        <i-col :xs="9" :sm="9" :md="6" :lg="4">
                            <FormItem label="Tipo de hemofilia" ></FormItem>
                        </i-col>
                        <i-col :xs="15" :sm="15" :md="5" :lg="5">
                            <FormRadio v-model="form.patient_hemophilia_type" mvalue="Grave (<1%)" label="Grave (<1%)" label_position="right" @callback="validateHemophiliaType" />
                        </i-col>
                        <i-col :xs="13" :sm="13" :md="7" :lg="7">
                            <FormRadio v-model="form.patient_hemophilia_type" mvalue="Moderada (>1% a <5%)" label="Moderada (>1% a <5%)" label_position="right" @callback="validateHemophiliaType" />
                        </i-col>
                        <i-col :xs="10" :sm="10" :md="5" :lg="5" offset="1">
                            <FormRadio v-model="form.patient_hemophilia_type" mvalue="Leve (>5% y <40%)" label="Leve (>5% y <40%)" label_position="right" @callback="validateHemophiliaType" />
                        </i-col>
                        <i-col v-if="radio_validation.hemophilia_type == false" span="24">
                            <div class="radio-group-error-text">Requerido</div>
                        </i-col>
                    </Row>
                    <br>

                    <!-- Patient annual rate -->
                    <FormItem label="Tasa anual de sangrado (números de sangrados reportados entre número de meses):"  prop="patient_annual_rate" class="block">
                        <i-input type="text" v-model="form.patient_annual_rate" placeholder="" style="width: 100%"></i-input>
                    </FormItem>
                    <Row>
                        <i-col :xs="15" :sm="10" :md="7" :lg="5">
                            <FormItem label="Cuantificación de inhibidores:"></FormItem>
                        </i-col>
                        <i-col span="9">
                            <FormRadioGroup @callback="validatePatientQuantification">
                                <FormRadio v-model="form.patient_quantification" mvalue="Sí" label="Sí" />
                                <FormRadio v-model="form.patient_quantification" mvalue="No" label="No" />
                            </FormRadioGroup>
                        </i-col>
                        <i-col v-if="radio_validation.quantification == false" span="24">
                            <div class="radio-group-error-text" style="text-align: right; margin-right: 65px;">Requerido</div>
                            <br>
                        </i-col>
                    </Row>

                    <!-- Patient UB -->
                    <FormItem label="UB:" prop="patient_ub">
                        <i-input type="text" v-model="form.patient_ub" placeholder="" style="width: 100%"></i-input>
                    </FormItem>

                    <!-- Patient last dose -->
                   <Row :gutter="16">
                        <i-col span="24">
                            <FormItem style="margin-bottom: 10px;" label="¿Cuándo fue la última dosis de FVIII?" ></FormItem>
                        </i-col>
                        <i-col span="4">
                            <FormItem label="" prop="patient_last_dose_day" class="with-footer-legend">
                                <InputNumber :max="31" v-model="form.patient_last_dose_day" :formatter="formatWithZero" placeholder="DD" style="width: 100%"></InputNumber>
                                <p class="form-footer-legend center">Día</p>
                            </FormItem>
                        </i-col>
                        <i-col span="4">
                            <FormItem label="" prop="patient_last_dose_month" class="with-footer-legend">
                                <InputNumber :max="12" v-model="form.patient_last_dose_month" :formatter="formatWithZero"  placeholder="MM" style="width: 100%"></InputNumber>
                                <p class="form-footer-legend center">Mes</p>
                            </FormItem>
                        </i-col>
                        <i-col span="5">
                            <FormItem label="" prop="patient_last_dose_year" class="with-footer-legend">
                                <i-input type="text" v-model="form.patient_last_dose_year" placeholder="AAAA" style="width: 100%"></i-input>
                                <p class="form-footer-legend center">Año</p>
                            </FormItem>
                        </i-col>
                        <i-col span="4" offset="1">
                            <FormItem label="" prop="patient_last_dose_hour" class="with-footer-legend">
                                <InputNumber :max="23" v-model="form.patient_last_dose_hour" :formatter="formatWithZero" placeholder="HH" style="width: 100%"></InputNumber>
                                <p class="form-footer-legend center">&nbsp;</p>
                            </FormItem>
                        </i-col>
                        <i-col span="1">
                            <div style="position: relative;">
                                <p style="font-size: 23px;">:</p>
                            </div>
                        </i-col>
                        <i-col span="4">
                            <FormItem label="" prop="patient_last_dose_minutes" class="with-footer-legend">
                                <InputNumber :max="59" v-model="form.patient_last_dose_minutes" :formatter="formatWithZero" placeholder="MM" style="width: 100%"></InputNumber>
                                <p class="form-footer-legend center"></p>
                                <p style="position: absolute; top: 34px; left: -88px;" class="form-footer-legend center">Hora (Formato 24 horas)</p>
                            </FormItem>
                        </i-col>
                    </Row>

                    <!-- Patient current treatment -->
                    <FormItem label="Tratamiento actual (especifique):"  prop="patient_current_treatment" class="block">
                        <i-input type="text" v-model="form.patient_current_treatment" placeholder="" style="width: 100%"></i-input>
                    </FormItem>
                    <br>

                    <hr>
                    <br>
                    <div class="patientes-extra-s">
                        <div style="height: 10px;"></div>

                        <!-- Request date -->
                        <Row :gutter="16">
                            <i-col :xs="11" :sm="11" :md="6" :lg="4">
                                <FormItem label="Fecha de solicitud"></FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem label="" prop="request_date_day" class="with-footer-legend">
                                    <InputNumber :max="31" v-model="form.request_date_day" :formatter="formatWithZero" placeholder="DD" style="width: 100%"></InputNumber>
                                    <p class="form-footer-legend center">Día</p>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem label="" prop="request_date_month" class="with-footer-legend">
                                    <InputNumber :max="12" v-model="form.request_date_month" :formatter="formatWithZero" placeholder="MM" style="width: 100%"></InputNumber>
                                    <p class="form-footer-legend center">Mes</p>
                                </FormItem>
                            </i-col>
                            <i-col span="5">
                                <FormItem label="" prop="request_date_year" class="with-footer-legend">
                                    <i-input type="text" v-model="form.request_date_year" placeholder="AAAA" style="width: 100%"></i-input>
                                    <p class="form-footer-legend center">Año</p>
                                </FormItem>
                            </i-col>
                        </Row>
                        <p style="font-size: 0.9em; margin-top: -10px;"><strong>Marca la prueba requerida</strong></p>
                        <div style="height: 15px;"></div>

                        <!-- Type Diagnosis -->
                        <Row>
                            <i-col :xs="24" :sm="24" :md="6" :lg="4">
                                <FormItem style="margin-bottom: 10px" label="Tipo de diagnóstico:"></FormItem>
                            </i-col>
                            <i-col :xs="24" :sm="24" :md="6" :lg="4">
                                <FormRadioGroup @callback="validateTypeDiagnosis">
                                    <FormRadio v-model="form.type_diagnosis" mvalue="Inicial" label="Inicial" label_position="right" />
                                    <FormRadio v-model="form.type_diagnosis" mvalue="Seguimiento" label="Seguimiento" label_position="right" />
                                </FormRadioGroup>
                            </i-col>
                            <i-col v-if="radio_validation.type_diagnosis == false" span="24">
                                <div class="radio-group-error-text">Requerido</div>
                            </i-col>
                        </Row>
                        <br>
                        <Row>
                            <i-col :xs="17" :sm="17" :md="16" :lg="10" span="17">
                                <FormItem style="margin-bottom: 10px" label="¿El paciente recibe o ha recibido emicizumab en los últumos 6 meses?"></FormItem>
                            </i-col>
                            <i-col span="5">
                                <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
                                    <FormRadioGroup @callback="valieteEmicizumabLast6Months">
                                        <FormRadio v-model="form.emicizumab_last_6_months" mvalue="Si" label="Si" />
                                        <FormRadio v-model="form.emicizumab_last_6_months" mvalue="No" label="No" />
                                    </FormRadioGroup>
                                </div>
                            </i-col>
                            <i-col v-if="radio_validation.emicizumab_last_6_months == false" span="24">
                                <div class="radio-group-error-text" style="margin-top: -15px; text-align: right;">Requerido</div>
                            </i-col>
                        </Row>
                    </div>
                    <br>
                    <p style="font-size: 0.9em;"><strong>¿Desea que su paciente reciba otro servicio de los que se enlistan a continuación? Señale aquí abajo:</strong></p>
                    <br>
                    <p style="font-size: 0.9em;"><strong>Tipo de prueba:</strong></p>
                    <div class="patientes-extra-s">
                        <p><strong>Hematología</strong></p>
                        <div style="height: 10px"></div>
                        <CheckboxGroup class="form-services-form--checkbox-group" v-model="services_related_hema">
                            <Checkbox label="Tiempo de tromboplastina parcial activada">
                                <span class="label">Tiempo de tromboplastina parcial activada</span>
                            </Checkbox>
                            <Checkbox label="Cuantificación FVIII">
                                <span class="label">Cuantificación FVIII</span>
                            </Checkbox>
                            <Checkbox label="Cuantificador de Inhibidor FVIII">
                                <span class="label">Cuantificador de Inhibidor FVIII</span>
                            </Checkbox>
                        </CheckboxGroup>
                    </div>
                    <div class="patientes-extra-s">
                        <p><strong>Molecular</strong></p>
                        <div style="height: 10px"></div>
                        <CheckboxGroup class="form-services-form--checkbox-group" v-model="services_related_mole">
                            <Checkbox label="Inversión intrón 22 y 1">
                                <span class="label">Inversión intrón 22 y 1</span>
                            </Checkbox>
                            <Checkbox label="Secuenciación gen F8">
                                <span class="label">Secuenciación gen F8</span>
                            </Checkbox>
                            <Checkbox label="Deleción/duplicación gen F8">
                                <span class="label">Deleción/duplicación gen F8</span>
                            </Checkbox>
                        </CheckboxGroup>
                    </div>
                    <div class="doctor-service-form--table--container">
                        <table class="doctor-service-form--table">
                            <tr>
                                <td width="180" class="header">Prueba</td>
                                <td>Hematología</td>
                            </tr>
                            <tr>
                                <td class="header">Cantidad y tipo de tubos</td>
                                <td>2 tubos de tapa azul con citrato de sodio</td>
                            </tr>
                            <tr>
                                <td class="header">Temperatura</td>
                                <td>Congelación: -20ºC -30ºC</td>
                            </tr>
                            <tr>
                                <td class="header">Copia</td>
                                <td>Verde</td>
                            </tr>
                        </table>
                    </div>
                    <div class="doctor-service-form--table--container">
                        <table class="doctor-service-form--table">
                            <tr>
                                <td width="180" class="header">Prueba</td>
                                <td>Molecular</td>
                            </tr>
                            <tr>
                                <td class="header">Cantidad y tipo de tubos</td>
                                <td>2 tubos de tapa lila con EDTA</td>
                            </tr>
                            <tr>
                                <td class="header">Temperatura</td>
                                <td>Refrigeración: 4ºC</td>
                            </tr>
                            <tr>
                                <td class="header">Copia</td>
                                <td>Amarilla</td>
                            </tr>
                        </table>
                    </div>
                    <hr>
                    <br>
                    <p class="font-size: 0.9em"><strong>Expreso tanto mi consentimiento como el del paciente que recibirá el servicio para realizar los estudios necesarios y contar con el resultado de los mismos.</strong></p>
                    <br>
                    <div style="width: 100%">
                        <FormRadio v-model="accepted" :mvalue="true" label="Acepto y comprendo los términos del servicio solicitado" label_position="right" :label_style="{ 'text-align': 'left', 'line-height': '21px', 'margin-top': '-5px', 'margin-left' : '15px', 'font-size': '0.9em' }"/>
                    </div>
                    <br><br>
                    <Button :disabled="!accepted" long size="large" type="primary" :loading="is_loading" @click="handleSubmit">Finalizar y enviar solicitud</Button>
                    <div style="height: 25px"></div>
                    <Button style="background-color: #103c8d" long size="large" type="primary" @click="handleCancel">Cancelar solicitud</Button>
                    <br><br>
                    <div style="font-size: 0.6em; text-align: center;">
                        <p style="margin-bottom: 5px">MATERIAL DE APOYO DE CARÁCTER INFORMATIVO. El programa POR TI le ofrece servicios complementarios de apoyo sin costo, diseñados para dar seguimiento al tratamiento diagnosticado por su médico tratante. Estos materiales son de carácter informativo únicamente y de ninguna forma reemplazan y/o sustituyen el consejo médico. Los servicios complementarios serán de adopción voluntaria y no están condicionados a la venta, consumo o uso de productos. El médico tratante será en todo momento, quien determine el mejor tratamiento para el paciente. Le invitamos a permanecer en contacto con su médico. Material para ser entregado al paciente exclusivamente por el Profesional de la Salud. </p>
                        <p>M-MX-00002171</p>
                    </div>
                </i-form>
            </template>
        </TemplateContent>
    </div>
</template>

<script>
    import form from './form'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [form],
        data(){
            return {
                loading: true,
                patient: {},
                accepted: false,
                form: {
                    doctor_name: '',
                    doctor_professional_id: '',
                    doctor_phone: '',
                    doctor_email: '',
                    doctor_hospital: '',

                    patient_initials_name: '',
                    patient_initials_first_last_name: '',
                    patient_initials_second_last_name: '',
                    patient_name: '',
                    patient_phone_1: '',
                    patient_phone_2: '',
                    patient_email: '',
                    patient_gender: '',
                    patient_birth_day: null,
                    patient_birth_month: null,
                    patient_birth_year: null,
                    patient_hemophilia_type: '',
                    patient_annual_rate: '',
                    patient_quantification: '',
                    patient_ub: '',
                    patient_last_dose_day: null,
                    patient_last_dose_month: null,
                    patient_last_dose_year: null,
                    patient_last_dose_hour: null,
                    patient_last_dose_minutes: null,
                    patient_current_treatment: '',

                    request_date_day: null,
                    request_date_month: null,
                    request_date_year: null,
                    type_diagnosis: '',
                    emicizumab_last_6_months: '',
                },
                ruleInline: {
                    doctor_name: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                    doctor_professional_id: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                    doctor_phone: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                        
                    ],
                    doctor_email: [
                        { required: true, type: 'email', message: 'Requerido', trigger: 'change' },
                    ],
                    doctor_hospital: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_initials_first_last_name: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[A-Za-z]/ },
                    ],
                    patient_initials_second_last_name: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[A-Za-z]/ },
                        { max: 3, message: 'Máximo tres caracteres' }
                    ],
                    patient_initials_name: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[A-Za-z]/ },
                        { max: 3, message: 'Máximo tres caracteres' }
                    ],
                    patient_name: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_phone_1: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_phone_2: [
                        { required: false, trigger: 'change' },
                    ],
                    patient_email: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_birth_day: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    patient_birth_month: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    patient_birth_year: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[0-9]*$/ },
                        { min: 4, message: '4 dígitos', trigger: 'change' },
                        { max: 4, message: '4 dígitos', trigger: 'change' },
                    ],
                    patient_annual_rate: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_ub: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_last_dose_day: [
                        { required: true, type: 'number', message: 'Requerido', trigger: 'change' },
                    ],
                    patient_last_dose_month: [
                        { required: true, type: 'number', message: 'Requerido', trigger: 'change' },
                    ],
                    patient_last_dose_year: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[0-9]*$/ },
                        { min: 4, message: '4 dígitos', trigger: 'change' },
                        { max: 4, message: '4 dígitos', trigger: 'change' },
                    ],
                    patient_last_dose_hour: [
                        { required: true, type: 'number', message: 'Requerido', trigger: 'change' },
                    ],
                    patient_last_dose_minutes: [
                        { required: true, type: 'number', message: 'Requerido', trigger: 'change' },
                    ],
                    patient_current_treatment: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    request_date_day: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    request_date_month: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    request_date_year: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /[0-9]/ },
                        { min: 4, message: '4 dígitos', trigger: 'change' },
                        { max: 4, message: '4 dígitos', trigger: 'change' },
                    ],
                },
                is_loading: false,
                radio_validation: {
                    gender: null,
                    type_diagnosis: null,
                    quantification: null,
                    hemophilia_type: null,
                    emicizumab_last_6_months: null,
                },
                services_related_hema: [],
                services_related_mole: [],
            }
        },
        computed: {
            ...mapGetters([
                'patient_services',
            ])
        },
        methods: {
            getPatient(){
                window.axios.post(this.$root.API_URL+ `/doctors/get-patient/${this.$route.params.id}`)
                .then(response => {
                    if(response.data.success){
                        this.patient = response.data.patient

                        this.fillForm(response.data.doctor)

                        this.loading = false
                        this.is_loading = false

                    }
                })
            },
            validateGenderRadio(){
                this.radio_validation.gender = this.form.patient_gender != ''
            },
            validateHemophiliaType(){
                this.radio_validation.hemophilia_type = this.form.patient_hemophilia_type != ''
            },
            validatePatientQuantification(){
                this.radio_validation.quantification = this.form.patient_quantification != ''
            },
            validateTypeDiagnosis(){
                this.radio_validation.type_diagnosis = this.form.type_diagnosis != ''
            },
            valieteEmicizumabLast6Months(){
                this.radio_validation.emicizumab_last_6_months = this.form.emicizumab_last_6_months != ''
            },
            handleSubmit(){

                this.validateGenderRadio()

                this.validateHemophiliaType()

                this.validatePatientQuantification()

                this.validateTypeDiagnosis()

                this.valieteEmicizumabLast6Months()

                let radio_validate = Object.values(this.radio_validation).indexOf(false) > -1

                this.$refs['form'].validate((valid) => {
                    if (valid && !radio_validate) {
                        
                        this.is_loading = true
                        let _uuid = window.uuid()
                        let category = this.$route.query.category

                        let hema_parent_id = window._.findIndex(this.patient_services[category], (service) => {
                            return service.name == 'Hematología:'
                        })

                        let mole_parent_id = window._.findIndex(this.patient_services[category], (service) => {
                            return service.name == 'Molecular:'
                        })

                        this.resetRelatedServices()

                        // Hematología
                        window._.each(this.services_related_hema, (service_name) => {

                            if( hema_parent_id != -1 ) {
                                
                                let child_id = window._.findIndex(this.patient_services[category][hema_parent_id].services, (service) => {
                                    return service.name == service_name
                                })

                                if( child_id != -1 ){
                                    let cs = this.patient_services[category][hema_parent_id].services[child_id]
                                    let ne_cs = {
                                        ...cs,
                                        uuid: _uuid,
                                        checked: true,
                                        form_data: this.form,
                                    }

                                    this.$store.dispatch('SET_PATIENT_SERVICE', {
                                        parent_id: hema_parent_id,
                                        category: category,
                                        service: ne_cs
                                    })
                                }
                            }
                        })

                        // Molecular
                        window._.each(this.services_related_mole, (service_name) => {
                            
                            if( mole_parent_id != -1 ) {
                                let child_id = window._.findIndex(this.patient_services[category][mole_parent_id].services, (service) => {
                                    return service.name == service_name
                                })

                                if( child_id != -1 ){
                                    let cs = this.patient_services[category][mole_parent_id].services[child_id]
                                    let ne_cs = {
                                        ...cs,
                                        uuid: _uuid,
                                        checked: true,
                                        form_data: this.form,
                                    }

                                    this.$store.dispatch('SET_PATIENT_SERVICE', {
                                        parent_id: mole_parent_id,
                                        category: category,
                                        service: ne_cs
                                    })
                                }
                            }
                        })

                        // let scroll = this.$route.query.scroll || "0"
                        this.$router.push(`/doctor/my-patient/${this.patient.id}?scroll=${scroll}`)

                    } else {
                        this.$Message.error('Completa la información faltante');
                    }
                })
            },
            resetRelatedServices(){
                let category = this.$route.query.category

                let hema_parent_id = window._.findIndex(this.patient_services[category], (service) => {
                    return service.name == 'Hematología:'
                })

                let mole_parent_id = window._.findIndex(this.patient_services[category], (service) => {
                    return service.name == 'Molecular:'
                })

                if( hema_parent_id != -1 ){
                    window._.each(this.patient_services[category][hema_parent_id].services, (service) => {
                        let new_item = {
                            ...service,
                            checked: false,
                            form_data: null,
                        }

                        this.$store.dispatch('SET_PATIENT_SERVICE', {
                            parent_id: hema_parent_id,
                            category: category,
                            service: new_item
                        })
                    })
                }

                if( mole_parent_id != -1 ){
                    window._.each(this.patient_services[category][mole_parent_id].services, (service) => {
                        let new_item = {
                            ...service,
                            checked: false,
                            form_data: null,
                        }

                        this.$store.dispatch('SET_PATIENT_SERVICE', {
                            parent_id: mole_parent_id,
                            category: category,
                            service: new_item
                        })
                    })
                }

                
            },
            handleCancel(){
                let category = this.$route.query.category

                let hema_parent_id = window._.findIndex(this.patient_services[category], (service) => {
                    return service.name == 'Hematología:'
                })

                let mole_parent_id = window._.findIndex(this.patient_services[category], (service) => {
                    return service.name == 'Molecular:'
                })

                if( hema_parent_id != -1 ){
                    window._.each(this.patient_services[category][hema_parent_id].services, (service) => {

                        let new_item = {
                            ...service,
                            checked: false,
                            form_data: null,
                        }

                        this.$store.dispatch('SET_PATIENT_SERVICE', {
                            parent_id: hema_parent_id,
                            category: category,
                            service: new_item
                        })
                    })
                }

                if( mole_parent_id != -1 ){
                    window._.each(this.patient_services[category][mole_parent_id].services, (service) => {

                        let new_item = {
                            ...service,
                            checked: false,
                            form_data: null,
                        }

                        this.$store.dispatch('SET_PATIENT_SERVICE', {
                            parent_id: mole_parent_id,
                            category: category,
                            service: new_item
                        })
                    })
                }

                this.$router.push(`/doctor/my-patient/${this.patient.id}`)
            },
            fillForm(doctor = null){
                let category = this.$route.query.category
                let form = null

                let hema_parent_id = window._.findIndex(this.patient_services[category], (service) => {
                    return service.name == 'Hematología:'
                })

                let mole_parent_id = window._.findIndex(this.patient_services[category], (service) => {
                    return service.name == 'Molecular:'
                })

                if( hema_parent_id != -1 ){
                    window._.each(this.patient_services[category][hema_parent_id].services, (service) => {
                        if( service.checked == true ){
                            if( form == null ) form = service.form_data
                            if( ! this.services_related_hema.includes(service.name) ) this.services_related_hema.push(service.name)
                        }                        
                    })
                }

                if( mole_parent_id != -1 ){
                    window._.each(this.patient_services[category][mole_parent_id].services, (service) => {
                        if( service.checked == true ){
                            if( form == null ) form = service.form_data
                            if( ! this.services_related_mole.includes(service.name) ) this.services_related_mole.push(service.name)
                        }                        
                    })
                }

                if( form != null ){
                    this.form = form
                    this.accepted = true
                }
                else {
                    this.form.doctor_name = doctor.name
                    this.form.doctor_professional_id = doctor.professional_id
                    this.form.doctor_phone = doctor.phone
                    this.form.doctor_email = doctor.user.email

                    this.form.patient_name = this.patient.name
                    this.form.patient_phone_1 = this.patient.phone
                    this.form.patient_phone_2 = this.patient.additional_phone
                    this.form.patient_email = this.patient.email
                }
            }
        },
        mounted(){
            this.getPatient()

            // set source
            // if( this.$route.query.source && this.$route.query.source != '' ){
            //     let source = this.$route.query.source
            //     this.services_related.push(source)
            //     this.source_service = source
            // }
        },
    }
</script>