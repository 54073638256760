<template>
    <div class="form-item--radio" :class="{ 'to-right': label_position == 'right', 'disabled': disabled == true }" @click="emit">
        <div class="form-item--radio--box" :class="{ 'checked': value == mvalue }">
            <Icon type="md-checkbox" color="#008FD5" size="30" />
        </div>
        <p class="form-item--radio--label" :style="label_style" v-html="label"></p>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            label: { required: true, type: String },
            value: { required: true },
            mvalue: { required: true },
            disabled: { required: false, type: Boolean, default: false, },
            label_position: { required: false, type: String, default: 'bottom'},
            label_style: { required: false, type: Object, default: () => { return {} } },
        },
        methods: {
            emit(){
                if( this.disabled == false ){
                    let is_checked = this.mvalue == this.value
                    this.$emit('change', is_checked ? '' : this.mvalue)
                    this.$parent.$emit('callback')
                    this.$emit('callback')
                }
            }
        }
    }
</script>

<style lang="scss">
    .form-item--radio {
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &--box{
            width: 28px;
            min-width: 28px;
            max-width: 28px;
            height: 28px;
            min-height: 28px;
            max-height: 28px;
            background-color: white;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            & .ivu-icon{
                display: none;
            }
            &.checked{
                /* background-color: red; */
                & .ivu-icon{
                    display: block;
                    margin-left: 1px;
                    margin-top: 1px;
                }
            }
        }
        &--label{
            font-size: 0.8em;
            font-weight: bold;
            text-align: center;
            margin-top: 10px;
            /* height: 20px; */
            line-height: 12px;
        }
        &.to-right{
            flex-direction: row;
            align-items: center;
            & .ivu-icon{
                margin-top: 0;
            }
            & .form-item--radio--label{
                margin-left: 10px;
                margin-top: 8px;
            }
        }
        &.disabled{
            & .ivu-icon{
                color: #e0e0e0 !important;
            }
        }
    }
</style>