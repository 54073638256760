<template>
    <div class="loading-spinner-container" :class="{ 'black': black }">
        <Spin fix style="margin-top: 25px">
            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
        </Spin>
        <p style="font-size: 0.8em;">Cargando...</p>
    </div>
</template>

<script>
    export default {
        props: {
            black: { required: false, type: Boolean, default: false, }
        }
    }
</script>

<style lang="sass">
    .loading-spinner-container
        position: relative
        width: 100%
        min-height: 50vh
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        &.black
            color: black
            & .ivu-spin
                color: black !important
    
    .demo-spin-icon-load
        animation: ani-demo-spin 1s linear infinite
    
    @keyframes ani-demo-spin
        from 
            transform: rotate(0deg)
        50%  
            transform: rotate(180deg)
        to
            transform: rotate(360deg)

</style>