<template>

    <div>

        <div @click="toggleContent('welcomeKitCollapse', 'adherenceContent')" class="pathology-menu-button" style="background: #000084; border:none;">
            <img class="scale-3" src="/images/icons/services/kit-de-bienvenida.png" alt="">
            <p>Kit de bienvenida que contiene información general del programa</p>
            <Icon type="ios-arrow-down" size="20" color="white" />
        </div>
        <div ref="welcomeKitCollapse" class="prg-service-content" aria-expanded="false">
            <div class="prg-service-content--wrapper">
                <img style="width: 100%" src="/images/icons/services/kit-de-bienvenida-postal.png" alt="">
                <br>
                <p style="color: white; font-size: 0.8em; text-align: center;">Imagen con fines informativos de manera general, el
                    contenido o diseño puede variar</p>
            </div>
        </div>

    </div>

</template>

<script>
    /* global $ */

    export default {
        data(){
            return {
                collapse: '',
            }
        },
        methods: {
            toggleContent(ref, affect_parent = null) {
                let target = $(this.$refs[ref])
                let target_wrapper = target.children('.prg-service-content--wrapper')
                let is_expanded = window.$(this.$refs[ref]).attr('aria-expanded') || "false"
                let target_h = target_wrapper[0].clientHeight || 0
                if (is_expanded == "true") {
                    target[0].style.height = "0px"
                    $(target[0]).attr('aria-expanded', 'false')
                    $(target[0]).toggleClass('active')
                    if (affect_parent) $(this.$parent.$parent.$refs[affect_parent]).first().height(`-=${target_h}`)
                } else {
                    target[0].style.height = `${target_h}px`
                    $(target[0]).attr('aria-expanded', 'true')
                    $(target[0]).toggleClass('active')
                    if (affect_parent) $(this.$parent.$parent.$refs[affect_parent]).first().height(`+=${target_h}`)
                }
            },
        }
    }
</script>