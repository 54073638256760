<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h1 class="section-title">Servicios de Diagnóstico</h1>

            <p>Fomentamos el diagnóstico con:</p>
            <br>

            <div @click="$router.push('/doctor/services/patients/aditionals/adherencia/apoyo-diagnostico')" class="pathology-menu-button">
                <img class="" src="/images/menu/doctors/apoyo-diagnostigo-seg.png" alt="">
                <p>Apoyo en el diagóstico y seguimiento</p>
            </div>

            <br>
            <hr>
            <br>

            <div v-for="service in Services" :key="'additional_service_'+service.id">
                <template v-if="service.category == 'legend'">
                    <div class="service-list-item">
                        <img :src="service.icon" alt="">
                        <p>{{ getLegendTitle(service.name) }}</p>
                    </div>

                    <div v-for="child in getChildsToList(service)" :key="'additional_service_'+service.id+'_child_'+child.id" style="margin-left: 70px" class="service-list-item">
                        <img :src="child.icon" alt="">
                        <p v-html="child.name"></p>
                    </div>
                </template>

                <template v-else>
                    <div class="service-list-item">
                        <img :src="service.icon" alt="">
                        <p v-html="service.name"></p>
                    </div>
                </template>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/Estudios.png" alt="">
                <p>Exámenes médicos:</p>
            </div>

            <div style="margin-left: 70px" class="service-list-item">
                <img src="/images/doctors-icons/Resonancia.png" alt="">
                <p style="font-size: 0.9em">Resonancia magnética</p>
            </div>

            <div style="margin-left: 70px" class="service-list-item">
                <img src="/images/doctors-icons/Volumetri.png" alt="">
                <p style="font-size: 0.9em">Volumetría</p>
            </div>
            
            <!-- <div class="service-list-item to-left">
                <img src="/images/doctors-icons/ALK.png" alt="">
                <p>Detección inmunohistoquímica de marcador ALK</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/LNH.png" alt="">
                <p>Pruebas inmunohistoquímica LNH</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/LLC.png" alt="">
                <p>Citometrías de flujo para LLC</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/DiagnosticoMolecular.png" alt="">
                <p>Diagnóstico molecular</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/Inversion.png" alt="">
                <p>Inversión intrón 22 y 1</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/Secuencia.png" alt="">
                <p>Secuenciación gen F8</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/Delencion.png" alt="">
                <p>Deleción/duplicación gen F8</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/PHer.png" alt="">
                <p>Pruebas de inmunohistoquímica para marcadores HER2 y SISH</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/Pruebas_HER2.png" alt="">
                <p>Pruebas de inmunohistoquímica para marcadores HER2</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/Receptores_Hor.png" alt="">
                <p>Receptores hormonales estrógenos y progesterona</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/SISH.png" alt="">
                <p>SISH (solo en caso necesario)</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/PCloro.png" alt="">
                <p>Pruebas de cloro en sudor</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/USG.png" alt="">
                <p>Ultrasonidos (USGs)</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/Quantiferon.png" alt="">
                <p>Quantiferón</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/Hepatitis.png" alt="">
                <p>Hepatitis ABC</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/VIH_12.png" alt="">
                <p>VIH-1/VIH-2 anticuerpos</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/TLT_1P.png" alt="">
                <p>TLT 1 posición</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/TLT_2P.png" alt="">
                <p>TLT 2 posiciones</p>
            </div>

            <div class="service-list-item to-left">
                <img src="/images/doctors-icons/Estudios.png" alt="">
                <p>Exámenes médicos:</p>
            </div>

            <div style="margin-left: 50px" class="service-list-item">
                <img style="width: 25px" src="/images/doctors-icons/Resonancia.png" alt="">
                <p style="font-size: 0.9em">Resonancia magnética</p>
            </div>

            <div style="margin-left: 50px" class="service-list-item">
                <img style="width: 25px" src="/images/doctors-icons/Volumetri.png" alt="">
                <p style="font-size: 0.9em">Volumetría</p>
            </div> -->

            <br>
            <p>Servicios adicionales establecidos con base a cada servicio disponible para cada línea terapéutica y vigencia del programa.</p>
            
        </TemplateContent>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                services: [],
            }
        },
        computed: {
            Services(){
                return window._.sortBy(this.services, 'wa_list_order')
            }
        },
        methods: {
            getServices(){
                window.axios.post(this.$root.API_URL+ `/content/get-diagnosis`)
                .then(response => {
                    if(response.data.success){
                        this.services = response.data.services
                    }
                })
            },
            getChildsToList(parent){
                return window._.filter(parent.services, (service) => {
                    return service.can_be_listed == 1
                })
            },
            getLegendTitle(name){
                if(name == 'Hematología:') return 'Diagnóstico de hematología:'
                else if(name == 'Molecular:') return 'Diagnóstico molecular:'
                else return name
            }
        },
        mounted(){
            this.getServices()
        }
    }
</script>