<template>
    <el-dialog class="doctor-dialog" title="" :visible.sync="show" width="90%">
        <h2 style="text-align: center; word-break: break-word; color: white; font-weight: normal;">
            Estimado médico, gracias por pre-registrar a su paciente en el programa de apoyo a pacientes <b>POR TI</b>, un especialista del programa se pondrá en contacto con el paciente para solicitar receta (que indique diagnóstico médico) y firma del aviso de privacidad.
        </h2>
    </el-dialog>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
            }
        },
        mounted(){
            /*global EventBus */
            EventBus.$on("show-doctors-sign-up-patients-popup", (value) => {
                this.show = value
            })
        }
    }
</script>