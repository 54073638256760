<template>
    <div>
        <component :is="form_id" />
    </div>
</template>

<script>
    import UG from './components/UG'
    import NTRK from './components/NTRK'
    import PDL1 from './components/PDL1'
    import CloroSudor from './components/CloroSudor'
    import RoactemraUSG from './components/RoactemraUSG'
    import AlecensaDiag from './components/AlecensaDiag'
    import TecentrqDiag from './components/TecentrqDiag'
    import SISH_FISH_CISH from './components/SISH-FISH-CISH'
    import Ecocardiogramas from './components/Ecocardiogramas'
    import OcrevusGabinete from './components/OcrevusGabinete'
    import RoactemraGabinete from './components/RoactemraGabinete'
    import Inmunohistoquimica from './components/Inmunohistoquimica'
    import GazybaPruebasDiagnosticas from './components/GazybaPruebasDiagnosticas'
    import HemlibraDiagnosticoInhibidores from './components/HemlibraDiagnosticoInhibidores'
    import UltrasonidosRodillaCodoTobillo from './components/UltrasonidosRodillaCodoTobillo'

    export default {
        data(){
            return {
                form_id: '',
            }
        },
        mounted(){
            this.form_id = this.$route.params.formid
        },
        components: {
            UG,
            NTRK,
            PDL1,
            CloroSudor,
            RoactemraUSG,
            AlecensaDiag,
            TecentrqDiag,
            OcrevusGabinete,
            Ecocardiogramas,
            RoactemraGabinete,
            Inmunohistoquimica,
            GazybaPruebasDiagnosticas,
            HemlibraDiagnosticoInhibidores,
            UltrasonidosRodillaCodoTobillo,
            'SISH-FISH-CISH': SISH_FISH_CISH,
        }
    }
</script>