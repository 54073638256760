<template>
    <el-dialog class="doctor-dialog" title="" :visible.sync="show" width="90%">
        <h2 style="text-align: center; word-break: break-word; color: white; font-weight: normal;">¿Cómo se enteró del programa <strong>POR TI</strong>?</h2>
        <br>
        <Form ref="heardForm" :model="signup" :rules="ruleInline">
            <FormItem prop="reason">
                <Input size="large" type="text" v-model="signup.reason" placeholder="Escribe tu respuesta..."/>
            </FormItem>
        </Form>
        <Row :gutter="16">
            <i-col span="24">
                <Button :loading="loading" @click="goToDoctorsSignup" long type="default">Siguiente</Button>
            </i-col>
        </Row>
    </el-dialog>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
                signup: {
                    reason: "",
                },
                loading: false,
                ruleInline: {
                    reason: [
                        {
                            required: true, 
                            message: 'Respuesta requerida', 
                            trigger: 'change' 
                        }
                    ],
                },
            }
        },
        methods: {
            goToDoctorsSignup(){
                this.$refs['heardForm'].validate().then(valid => {
                    if(valid){
                        this.loading = true
                        this.$store.dispatch("SET_DOCTOR_SIGNUP", {
                            data: {
                                visited: false,
                                reason: this.signup.reason,
                            },
                        })
                        this.$router.push("/doctor/sign-up")
                    }
                }) 
            }
        },
        mounted(){
            /*global EventBus */
            EventBus.$on("show-doctors-sign-up-how-did-you-hear-about-popup", (value) => {
                this.show = value
            })
        }
    }
</script>