const getAPIToken = () => {
    return new Promise(resolve => {
        window.dexie.config.get({ name: 'API_TOKEN' }, e => {
            if (e) resolve(e.value)
            else resolve(null)
        })
    })
}

export const auth = (to, from, next) => {
    getAPIToken().then(token => {
        if (token != null) next()
        else next({ path: '/' })
    })
}

export const guest = (to, from, next) => {
   getAPIToken().then(token => {
        if (token == null) next()
        else next({ path: '/doctor/dashboard' })
    })
}

export const doctor = (to, from, next) => {
    // let isDoctor = hasRole("Doctor")
    // if(isDoctor) next()
    // else next({ path: '/' })
    
    // Since Jul 21, all users are doctors
    return next()
}