<template>
    <div style="background-color: white; color: #006fb9;">
        <ServiceContent footer_color="#006fb9">
            <div slot="header" class="image-header">
                <img style="width: 100%;" src="/images/doctor-services/RedEnfermeras.png" alt="">
            </div>
            <p class="service-title">Servicio de enfermeras</p>
            
            <Loading v-if="loading" />
            <div v-else class="ptn-dyn-cont">
                <p v-html="MainDescription"></p>
            </div>

        </ServiceContent>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                texts: [],
                loading: true,
            }
        },
        computed: {
            MainDescription(){
                let ti = window._.findIndex(this.texts, text => text.code == 'nurses_network.mainDescription')
                return ti != -1 ? this.texts[ti].content : ""
            },
        },
        methods: {
            fetchTexts(){
                window.axios.post(this.$root.API_URL+ `/content/dynamic-texts`, { code: 'nurses_network' })
                .then(response => {
                    if(response.data.success){
                        this.texts = response.data.texts
                        this.loading = false
                    }
                })
            },
        },
        mounted(){
            this.fetchTexts()
        }
    }
</script>