<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h1 class="section-title">¿Qué es POR TI?</h1>
            
            <Loading v-if="loading" />
            <template v-else>

                <!-- Patients -->
                <div style="background-color: #0c5bbd" @click="collapse" class="pathology-menu-button prd-service-btn" aria-target="patient-content">
                    <img @click="collapseParent" class="scale-3" src="/images/icons/about/patient.png" />
                    <h4 @click="collapseParent" style="flex-grow: 1;">Servicios para pacientes</h4>
                    <Icon type="ios-arrow-down" size="20" color="white" />
                </div>
                <div class="prg-service-content" id="patient-content" aria-expanded="false">
                    <div class="prg-service-content--wrapper">
                        <p style="text-align: justify;" v-html="PatientMainDescription"></p>
                    </div>
                </div>

                <!-- Doctors -->
                <div style="background-color: #005a94;" @click="collapse" class="pathology-menu-button sky prd-service-btn" aria-target="doctor-content">
                    <img @click="collapseParent" class="scale-3" src="/images/icons/about/doctor.png" />
                    <h4 @click="collapseParent" style="flex-grow: 1;">Servicios para médicos</h4>
                    <Icon type="ios-arrow-down" size="20" color="white" />
                </div>
                <div class="prg-service-content" id="doctor-content" aria-expanded="false">
                    <div class="prg-service-content--wrapper">
                        <p style="text-align: justify;" v-html="DoctorMainDescription"></p>
                    </div>
                </div>

            </template>
        </TemplateContent>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                texts: [],
                loading: true,
            }
        },
        computed: {
            PatientMainDescription(){
                let ti = window._.findIndex(this.texts, text => text.code == 'about.patients.mainDescription')
                return ti != -1 ? this.texts[ti].content : ""
            },
            DoctorMainDescription(){
                let ti = window._.findIndex(this.texts, text => text.code == 'about.doctors.mainDescription')
                return ti != -1 ? this.texts[ti].content : ""
            },
        },
         methods: {
            fetchTexts(){
                window.axios.post(this.$root.API_URL+ `/content/dynamic-texts`, { code: 'about' })
                .then(response => {
                    if(response.data.success){
                        this.texts = response.data.texts
                        this.loading = false
                    }
                })
            },
            collapse(e){
                let target_name = window.$(e.srcElement).attr('aria-target')
                let target = window.$(`#${target_name}`)

                let target_wrapper = window.$(`#${target_name} .prg-service-content--wrapper`)
                let is_expanded = window.$(target[0]).attr('aria-expanded') || "false"

                let target_h = `${target_wrapper[0].clientHeight || 0}px`

                if(is_expanded == "true"){
                    target[0].style.height = "0px"
                    window.$(target[0]).attr('aria-expanded', 'false')
                    window.$(e.srcElement).toggleClass('active')
                }
                else {
                    target[0].style.height = target_h
                    window.$(target[0]).attr('aria-expanded', 'true')
                    window.$(e.srcElement).toggleClass('active')
                }
            },
            collapseParent(e){
                let target_name = window.$(e.srcElement.parentElement).attr('aria-target')
                let target = window.$(`#${target_name}`)

                let target_wrapper = window.$(`#${target_name} .prg-service-content--wrapper`)
                let is_expanded = window.$(target[0]).attr('aria-expanded') || "false"

                let target_h = `${target_wrapper[0].clientHeight || 0}px`

                if(is_expanded == "true"){
                    target[0].style.height = "0px"
                    window.$(target[0]).attr('aria-expanded', 'false')
                    window.$(e.srcElement.parentElement).toggleClass('active')
                }
                else {
                    target[0].style.height = target_h
                    window.$(target[0]).attr('aria-expanded', 'true')
                    window.$(e.srcElement.parentElement).toggleClass('active')
                }
            }
        },
        mounted(){
            this.fetchTexts()
        }
    }
</script>

<style lang="scss">
    .about-header-title {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        margin-top: 40px;
        & h3 {
            margin-left: 15px;
        }
        & img {
            width: 30px;
            &.scale-3 {
                transform: scale(1.3);
            }
        }
    }
</style>