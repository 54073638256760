<template>
    <div>
        <div @click="toggleContent('bonusContent')" class="pathology-menu-button prd-service-btn">
            <img class="scale-3" src="/images/icons/stages/OfertaComercial.png" alt="">
            <p>Programa de bonificaciones comerciales / apego a tratamiento</p>
            <Icon type="ios-arrow-down" size="20" color="white" />
        </div>
        <div ref="bonusContent" class="prg-service-content" aria-expanded="false">
            <div class="prg-service-content--wrapper">

                <template v-if="oncopassport">
                    <img style="width: 100%; margin-bottom: 20px;" src="/images/bonificacion-oncopassport.jpg" alt="">
                    <a class="ivu-btn ivu-btn-primary ivu-btn-long" href="/images/bonificacion-oncopassport.jpg" download>Descargar</a>
                </template>

                <template v-if="integralpass">
                    <img style="width: 100%; margin-bottom: 20px;" src="/images/bonificacion-integralpass.jpg" alt="">
                    <a class="ivu-btn ivu-btn-primary ivu-btn-long" href="/images/bonificacion-integralpass.jpg" download>Descargar</a>
                </template>

                <br><br>
                <Button @click="onShowContactPopup" style="background: #0AB4FE" type="primary" size="large" long>Puntos de contacto para pacientes</Button>

            </div>
        </div>

    </div>
</template>


<script>
/* global $ */
export default {
    props:{
        oncopassport: { type: Number, default: 0 },
        integralpass: { type: Number, default: 0 },
    },
    methods: {
        toggleContent(ref){
            let target = $(this.$refs[ref])
            let target_wrapper = target.children('.prg-service-content--wrapper')
            let is_expanded = window.$( this.$refs[ref] ).attr('aria-expanded') || "false"
            let target_h = target_wrapper[0].clientHeight || 0
            if(is_expanded == "true"){
                target[0].style.height = "0px"
                $(target[0]).attr('aria-expanded', 'false')
                $(target[0]).toggleClass('active')
            }
            else {
                target[0].style.height = `${target_h}px`
                $(target[0]).attr('aria-expanded', 'true')
                $(target[0]).toggleClass('active')
            }
        },
        onShowContactPopup(){
            window.EventBus.$emit('programs-details--show-contact-popup')
        },
    }
}
</script>