<template>
    <div>
        <div class="pathology-grid">
            <div class="header" :class="{'doctor': isDoctor}"></div>
        </div>
        <TemplateContent>
            <h1 class="section-title">Notificaciones</h1>
            <template v-if="loading">
                <Loading/>
            </template>
            <template v-else>
                <template v-if="notifications.length > 0">
                    <Button :loading="loading" size="small" style="float: right;" @click="markAllAsRead" type="warning">Marcar todas como leídas</Button>
                    <div style="padding-top: 35px;">
                        <Item v-for="item in notifications" :key="item.id" :item="item" />
                    </div>
                </template>
                <template v-else>
                    <Empty icon="md-notifications" title="Parece que no tienes Notificaciones nuevas" />
                </template>
            </template>
        </TemplateContent>
    </div>
</template>

<script>
    /*global axios, _, EventBus */
    import Item from "./components/Item"
    import { mapGetters } from "vuex"

    export default {
        data(){
            return {
                loading: true,
                notifications: [],
            }
        },
        computed: {
            ...mapGetters([
                "user"
            ]),
            isDoctor(){
                let ri = _.findIndex(this.user.roles, (r) => {
                    return r.name == "Doctor"
                })
                return ri != -1 ? true : false
            }
        },
        methods: {
            getNotifications(){
                axios.post(this.$root.API_URL+"/users/get-notifications")
                .then(response => {
                    if(response.data.success){
                        this.notifications = response.data.notifications
                        this.loading = false
                    }
                })
            },
            markAllAsRead(){
                axios.post(this.$root.API_URL+"/users/notifications/mark-all")
                .then(response => {
                    if(response.data.success){
                        _.each(this.notifications, (n) => {
                            if(n.read == false){
                                n.read = true
                            }
                        })
                        this.$store.dispatch("UPDATE_USER_FIELD", {
                            field: 'notification_count',
                            value: 0,
                        })
                    }
                })
            }
        },
        mounted(){
            this.getNotifications()

            EventBus.$on("update-notification", (notification) => {
                let ni = _.findIndex(this.notifications, (n) => {
                    return n.id == notification.id
                })

                if(ni != -1){
                    this.$set(this.notifications, ni, notification)
                }
            })
        },
        components: {
            Item,
        }
    }
</script>
