<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h1 class="section-title">Nuestros puntos de contacto para médicos y pacientes</h1>

            <Loading v-if="loading"/>
            <template v-else>
                <p v-html="MainDescription"></p>
                <br>

                <Collapse class="porti" v-model="drop">
                    <Panel name="patients">
                        Pacientes
                        <template #content>
                            <p v-html="PatientDescription"></p>
                            <br>
                            <Button long size="large" type="primary" @click="show_share_popup=true">Compartir</Button>

                        </template>
                        <p slot="content"></p>
                    </Panel>
                    <Panel name="doctors">
                        Médicos
                        <template #content>
                            <p v-html="DoctorDescription"></p>
                        </template>
                    </Panel>
                </Collapse>

                <el-dialog class="doctor-dialog" title="" :visible.sync="show_share_popup" width="90%">
                    <h2 style="text-align: center; word-break: break-word; color: white; font-weight: bold;">Compartir información</h2>
                    <br>
                    <center>
                        <img style="width: 100%; max-width: 850px" src="/images/TarjetaContacto.png" alt="">
                    </center>
                    <br>
                    <Button @click="share" style="margin-bottom: 25px" long type="primary">Copiar enlace</Button>
                    <a class="ivu-btn ivu-btn-primary ivu-btn-long" href="/images/TarjetaContacto.png" download>Descargar</a>

                </el-dialog>

            </template>
            
        </TemplateContent>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                texts: [], 
                drop: '',
                loading: true,
                show_share_popup: false,
            }
        },
        computed:{
            MainDescription(){
                let ti = window._.findIndex(this.texts, text => text.code == 'contact.mainDescription')
                return ti != -1 ? this.texts[ti].content : ""
            },
            PatientDescription(){
                let ti = window._.findIndex(this.texts, text => text.code == 'contact.patients.description')
                return ti != -1 ? this.texts[ti].content : ""
            },
            DoctorDescription(){
                let ti = window._.findIndex(this.texts, text => text.code == 'contact.doctors.description')
                return ti != -1 ? this.texts[ti].content : ""
            }
        },
        methods: {
            fetchTexts(){
                window.axios.post(this.$root.API_URL+ `/content/dynamic-texts`, { code: 'contact' })
                .then(response => {
                    if(response.data.success){
                        this.texts = response.data.texts
                        this.loading = false
                    }
                })
            },
            share(){
                navigator.clipboard.writeText("https://drive.google.com/file/d/142zqgjoLpniiuetZ2dPK4gBWXNaQtHsU/view?usp=sharing")
                this.$Message.success('Enlace copiado exitosamente');
            }
        },
        mounted(){
            this.fetchTexts()

            window.$(document).on( 'click', '.prd-service-btn', function(){
                let target_name = window.$(this).attr('aria-target')
                let target = window.$(`#${target_name}`)

                let target_wrapper = window.$(`#${target_name} .prg-service-content--wrapper`)
                let is_expanded = window.$(target[0]).attr('aria-expanded') || "false"

                let target_h = `${target_wrapper[0].clientHeight || 0}px`

                if(is_expanded == "true"){
                    target[0].style.height = "0px"
                    window.$(target[0]).attr('aria-expanded', 'false')
                    window.$(this).toggleClass('active')
                }
                else {
                    target[0].style.height = target_h
                    window.$(target[0]).attr('aria-expanded', 'true')
                    window.$(this).toggleClass('active')
                }
            });

        }
    }
</script>