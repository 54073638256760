import Vue from 'vue'

var EventBus = new Vue()
window.EventBus = EventBus

import Dexie from 'dexie'

const db = new Dexie('porti')
db.version(1).stores({
    config: '++id,name,value',   
})

window.dexie = db

import Cookies from "js-cookie"
window.Cookies = Cookies

import ViewUI from 'view-design';
import locale from 'view-design/dist/locale/es-ES';
import './iview-theme/index.less'
Vue.use(ViewUI, { locale });

import ElementUI from 'element-ui';
import "./element-theme/index.scss"
Vue.use(ElementUI);

var $ = require("jquery")
var jquery = $
window.$ = $
window.jquery = jquery

import { Col } from 'view-design'
Vue.component("Column", Col)

const axios = require('axios').default;
window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// let AUTH_API_TOKEN = Cookies.get("API_TOKEN");
db.config.get({ name: 'API_TOKEN' }, e => {
    if(e) window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + e.value
})
// if(AUTH_API_TOKEN){
//     window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + AUTH_API_TOKEN;
// }

var _ = require('lodash');
window._ = _

var moment = require('moment');
window.moment = moment

moment.locale("es");

Vue.use(require('vue-moment'), {
    moment,
})

// import firebase from "firebase"
// Vue.prototype.$firebase = firebase.initializeApp({
//     apiKey: "AIzaSyAedeMGmpiciB7mzTiL5LXHOUN3JR_0Lz0",
//     authDomain: "por-ti-roche.firebaseapp.com",
//     databaseURL: "https://por-ti-roche.firebaseio.com",
//     projectId: "por-ti-roche",
//     storageBucket: "por-ti-roche.appspot.com",
//     messagingSenderId: "944765940975",
//     appId: "1:944765940975:web:5371dfc730dde24ae4d71a"
// });

const uuid = require('uuid/v4');
window.uuid = uuid;

window.ImageResize = require('image-resize')

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)


import { v4 as uuidv4 } from 'uuid';
window.uuid = uuidv4

import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)