<template>
    <li>
        <b>Acceso</b>
        <ul>
            <li v-for="(access, i) in services" :key="'a_'+i">
                {{ access.name }}
                <div v-if="access.wa_data != null" style="font-size: 0.8em; margin-top: 5px; margin-left: 20px;" v-html="access.wa_data.footer_legend"></div>
            </li>
        </ul>
    </li>
</template>

<script>
    export default {
        props: {
            services: { required: true, type: [Object, Array] }
        },
    }
</script>