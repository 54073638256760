<template>
    <Layout>
        <Header>
            <AppHeader/>
        </Header>
        <Content>
            <div id="app">
                <router-view/>
            </div>
        </Content>
    </Layout>
</template>

<script>
    import AppHeader from "./components/Header"

    export default {
        components: {
            AppHeader,
        }
    }
</script>

<style lang="scss">
	@import './iview-theme/override.sass';
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700;800&display=swap');

    #app {
        font-family: "Poppins", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #fff;
    }

    body {
        font-family: "Poppins", Helvetica, Arial, sans-serif !important;
    }

    body{
        font-size: 16px !important;
    }

    b, strong{
        font-weight: 600 !important;
    }

    .section-title {
        margin-bottom: 25px;
        font-size: 1.6em;
    }

    .service-title{
        font-size: 1.3em;
        text-align: center;
        font-weight: bold;
        margin: 20px 0;
    }

    .full-grid{
        position: relative;
        min-height: calc(100vh - 20px);
    }

    .pathology-grid {
        & .header {
            height: 75px;
            background-image: url('/images/pleca-patients.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            &.doctor {
                background-image: url('/images/pleca-doctors.png');
                background-position: 0 -110px;
            }
        }
    }

    .form-label{
        margin-top: -5px;
        line-height: 1.4;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 1rem;
    }

    ul.service {
        //list-style: inside;
        padding-left: 20px;
        & li{
            padding: 12px 0;
            & span, i, p {
                color: black;
            }
        }
    }

    .txt-black{
        color: black !important;
    }

    .txt-primary{
        color: #008FD5 !important;
    }

    .txt-center{
        text-align: center !important;
    }

    .program-title{
        display: flex;
        align-items: start;
        margin-bottom: 15px;
        & img {
            width: 27px;
            margin-top: 8px;
        }
        & h1 {
            margin-left: 5px;
            margin-bottom: 0px;
            & sup{
                margin-left: 0px;
            }
        }
    }

    .prg-service-content{
        height: 0;
        transition: height .5s;
        height: 0;
        overflow: hidden;
        &--wrapper{
            padding: 5px 15px;
        }
        &[aria-expanded="true"]{
            margin-bottom: 30px;
        }
    }

    .prg-service-item{
        display: flex;
        align-items: flex-start;
        padding: 10px;
        margin-bottom: 15px;
        & img {
            width: 30px;
            margin-top: 10px;
            transform: scale(1.3);
        }
        &--content{
            line-height: 1.6;
            margin-left: 15px;
            & p {
                &:first-child{
                    font-weight: 600;
                    font-size: 17px;
                }
                font-size: 15px;
            }
        }
    }

    .ivu-checkbox-group-item{
        display: flex !important;
        & .chk-bx-label {
            margin-top: -5px;
            margin-left: 10px;
        }
    }

    .pathology {
        position: fixed;
        left: 0;
        right: 0;
        top: 65px;
        min-height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        margin: -2px 0 30px 0;
        background-color: white;
        z-index: 51;
        & img {
            width: 50px;
            margin-right: 10px;
        }
        & p.name {
            font-weight: bold;
            color: #00AFD8;
        }
        & p.title {
            color: grey;
            font-size: 0.75em;
        }
    }
        
    .center-grid {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 79px;
        min-height: 600px;
        background-image: url('/images/dashboard-bg.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 25px;
        & .item {
            display: flex;
            align-items: center;
            min-height: 69px;
            opacity: 0.9;
                    // background: linear-gradient(45deg, #008FD5, #00AFD7)
            padding: 15px 25px;
            border-radius: 15px;
            margin-bottom: 15px;
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0), 0 1px 10px 0 rgba(0, 0, 0, 0.05);
            & img {
                width: 37px;
                margin-right: 20px;
            }
            & p {
                font-weight: bold;
                font-size: 1em;
            }
            &.one {
                background-color: #5bbecf;
            }
            &.two {
                background-color: #17b6db;
            }
            &.three {
                background-color: #1396d7;
            }
            &.four {
                background-color: #0f6ba9;
            }
            &.five {
                background-color: #2a2673;
            }
            &.six {
                background-color: #241c55;
            }
            &.seven {
                background-color: #152f55;
            }
            &.eight {
                background-color: #135086;
            }
        }
        &.doctor {
            margin-top: 0;
            background-image: url('/images/dashboard-bg-doctors.png');
            justify-content: center;
            & .item {
                &.one {
                    background-color: #5bbecf;
                }
                &.two {
                    background-color: #17b6db;
                }
                &.three {
                    background-color: #1396d7;
                }
                &.four {
                    background-color: #0f6ba9;
                }
            }
        }
    }

    .footer-schedule {
        display: flex;
        padding: 15px 0px;
        background-color: white;
        align-items: center;
        margin: 30px 0;
                // flex-wrap: wrap
        & .container {
            display: flex;
            align-items: baseline;
            flex-grow: 2;
            overflow-y: auto;
        }
        & .arrow {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            width: 50px;
            background-color: white;
        }
        & .item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            & img {
            width: 50px;
            margin-bottom: 5px;
            }
            & p {
            color: black;
            font-weight: bold;
            font-size: 0.8em;
            }
            &.min {
                max-width: 65px;
                min-width: 70px;
                margin: 10px 15px;
            & p {
                font-size: 0.63em;
                text-align: center;
            }
        }
        &:first-child {
            margin-left: 0 !important;
            }
        }
    }

    .pathology-menu-button {
        display: flex;
        align-items: center;
        min-height: 60px;
                // background: #00AFD7
        padding: 10px 15px;
        border-radius: 15px;
        margin-bottom: 30px;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0), 0 1px 10px 0 rgba(0, 0, 0, 0.05);
        border: 1px solid white;
        cursor: pointer;
        & .ivu-icon {
            transition: transform .5s;
        }
        & img {
            width: 30px;
            margin-right: 20px;
            &.scale {
            transform: scale(1.5);
            }
            &.scale-3 {
            transform: scale(1.3);
            }
        }
        & p {
            font-weight: bold;
            font-size: 1em;
            flex-grow: 1;
            margin-right: 10px;
        }
        &.sky {
            background-color: #4E9ACF;
        }
        & .end-icon {
            float: right;
        }
        &--bottom {
            padding: 15px;
            border: 1px solid rgba(white, 0.6);
            border-top: none;
            margin-top: -16px;
            margin-left: 10px;
            margin-right: 10px;
            border-radius: 0 0 15px 15px;
            font-size: 15px;
            margin-bottom: 15px;
        }
        &.active {
            & .ivu-icon {
            transform: rotate(180deg);
            }
        }
        &.no-border{
            border: none !important;
        }
        &.no-shadow{
            box-shadow: none !important;
        }
    }

    .doctor-service-form{
        .block.ivu-form-item{
            display: block !important;
            & .ivu-form-item-label{
                text-align: left;
                line-height: 21px;
            }
        }
        .ivu-form-item{
            width: 100%;
            display: flex !important;
        }
        .ivu-form-item-content{
            flex-grow: 1;
        }
        .ivu-form-item-label{
            color: white !important;
            font-weight: 600;
            text-align: left !important;
            line-height: 20px !important;
            &::before{
                content: '' !important;
            }
        }
        & .ivu-form-item-error-tip{
            color: #d91e18 !important;
            font-size: 11px!important;
            margin-left: 4px;
        }
    }

    .with-footer-legend{
        position: relative;
        & .form-footer-legend{
            font-size: 0.8em;
            font-weight: bold;
            height: 20px;
            line-height: 12px;
            margin-left: 4px;
            margin-top: 10px;
            &.center{
                text-align: center;
                margin-left: 0px;
            }
        }
    }

    .form-services-form--checkbox-group{
        & .ivu-checkbox{
            width: 28px !important;
            height: 28px !important;
            min-width: 28px !important;
            min-height: 28px !important;
            max-width: 28px !important;
            max-height: 28px !important;
            background-color: white;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            & .ivu-checkbox-inner{
                border: none !important;
                width: 22px !important;
                height: 22px !important;
                min-width: 22px !important;
                min-height: 22px !important;
                max-width: 22px !important;
                max-height: 22px !important;
                &::after{
                    top: 1px !important;
                    left: 7px !important;
                    width: 7px !important;
                    height: 16px !important;
                }
                & input {
                    width: 22px !important;
                    height: 22px !important;
                    min-width: 22px !important;
                    min-height: 22px !important;
                    max-width: 22px !important;
                    max-height: 22px !important;
                }
            }
        }
        & .ivu-checkbox-wrapper{
            align-items: center;
        }
        & .label{
            margin-left: 15px; 
            margin-top: 2px; 
            font-weight: bold;
        }
    }

    .service-request--checkbox-group{
        margin-bottom: 20px;
        & .ivu-checkbox{
            width: 18px !important;
            height: 18px !important;
            min-width: 18px !important;
            min-height: 18px !important;
            max-width: 18px !important;
            max-height: 18px !important;
            background-color: transparent;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid white;
            & .ivu-checkbox-inner{
                border: none !important;
                width: 16px !important;
                height: 16px !important;
                min-width: 16px !important;
                min-height: 16px !important;
                max-width: 16px !important;
                max-height: 16px !important;
                background-color: transparent;
                &::after{
                    top: 1px !important;
                    left: 5px !important;
                    width: 7px !important;
                    height: 12px !important;
                }
                & input {
                    width: 18px !important;
                    height: 18px !important;
                    min-width: 18px !important;
                    min-height: 18px !important;
                    max-width: 18px !important;
                    max-height: 18px !important;
                }
            }
        }
        & .ivu-checkbox-wrapper{
            align-items: center;
            padding: 10px;
            margin-bottom: -5px;
            &.ivu-checkbox-wrapper-checked{
                transition: all ease-in-out 0.1s;
                & .ivu-checkbox{
                    background-color: #008FD5;
                    border-color: #008FD5;
                    & .ivu-checkbox-inner::after{
                        border-color:#103c8d;
                    }
                }
            }
        }
        & .label{
            margin-left: 15px; 
            margin-top: 2px; 
            font-weight: bold;
        }
    }

    .doctor-service-form--table{
        border-collapse: collapse;
        border-radius: 1em;
        overflow: hidden;
        font-size: 0.9em;
        width: 100%;
        & td {
            padding: 1em;
            border-bottom: 1px solid white;
            &.header{
                background-color: #103c8d;
                font-weight: bold;
                max-width: 140px;
                border-right: 1px solid white;
            }
        }
        & tr:last-child{
            & td {
                border-bottom: none;
            }
        }
    }

    .doctor-service-form--table--container{
        border: 1px solid white;
        border-radius: 1em;
        margin-bottom: 35px;
    }

    .radio-group-error-text{
        line-height: 1;
        padding-top: 6px;
        color: #cf000f;
        font-size: 11px !important;
        margin-top: -5px;
    }

    .doctor-service-form .ivu-input-number-handler-wrap{
        display: none !important;
    }

    @media (min-width: 600px) {
        .form-footer-legend + .ivu-form-item-error-tip{
            margin-top: -8px;
        }
    }

    .form-footer-legend.center + .ivu-form-item-error-tip{
        width: 100%;
        text-align: center;
    }

    .usgs-modal .ivu-modal-body{
        background-color: #006fb9;
        color: white !important;
        border-radius: 5px;
        & .ivu-modal-close .ivu-icon.ivu-icon-ios-close{
            color: white !important;
        }
    }

    .ivu-modal-body{
        font-family: 'Poppins';
    }

    .ivu-btn {
        height: auto !important;
        min-height: 32px;
        font-weight: 600 !important;
        & span {
            white-space: break-spaces;
        }
        &.ivu-btn-large{
            min-height: 40px;
            padding: 5px 15px;
        }
    }

    .ivu-form .ivu-form-item-label{
        font-size: 13px !important;
    }

    .ivu-input{
        font-size: 12px !important;
    }

    .ivu-input-number{
        font-size: 13px !important;
    }

    .hem-region{
        display: flex !important;
        flex-direction: column;
        align-items: center;
        position: relative;
        & img {
            width: 65%;
            max-width: 180px;
            margin-bottom: 20px;
        }
        & .ivu-checkbox{
            order: 2 !important;
            margin-top: 15px;
            position: absolute;
            left: 10%;
            bottom: 0;
            & .ivu-checkbox-inner{
                width: 22px;
                height: 22px;
                &::after{
                    width: 6px;
                    height: 11px;
                    top: 3px;
                    left: 7px;
                }
            }
        }
        & .label{
            margin-left: 25px;
        }
    }

    @media (min-width: 600px) {
        .hem-region .ivu-checkbox{
            left: 20%;
        }
    }

    @media (min-width: 900px) {
        .hem-region .ivu-checkbox{
            left: 25%;
        }
    }

    @media (min-width: 1100px) {
        .hem-region .ivu-checkbox{
            left: 30%;
        }
    }

    @media (min-width: 1200px) {
        .hem-region .ivu-checkbox{
            left: 35%;
        }
    }

    .content--video-img{
        padding: 25px 10px;
        border-bottom: 1px solid white;
        &--title{
            font-weight: bold;
            font-size: 1.1em;
        }
        &--description{
            white-space: pre-wrap;
            word-break: break-all;
            & a {
                color: white;
                text-decoration: underline;
            }
        }
        &--media{
            padding: 15px;
            margin: 10px 0;
            /* border-radius: 5px; */
            background-color: #135086;
            & img {
                width: 100%;
            }
        }
        &--share{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        &:last-of-type{
            border-bottom: none;
        }
    }

    @media (min-width: 600px) {
        .el-image-viewer__wrapper{
            & img {
                max-width: 50% !important;
            }
        }
    }

    p[data-f-id] {
        display: none !important;
    }

    .white-links a{
        color: white;
    }

    .porti.ivu-collapse{
        background-color: transparent !important;
        border: none !important;
        color: white !important;
        & .ivu-collapse-item{
            margin-bottom: 20px;
            border-top: none !important;
            & .ivu-collapse-header{
                color: white;
                display: flex;
                align-items: center;
                padding-right: 16px;
                align-items: center;
                background-color: #4E9ACF;
                min-height: 60px;
                border: 1px solid white;
                border-radius: 15px;
                font-weight: bold;
                & i {
                    order: 2;
                    position: absolute;
                    right: 0;
                }
            }
            & .ivu-collapse-content{
                background-color: transparent;
                color: white;
                & a {
                    color: white;
                }
            }
        }
    }

    .invisible.ivu-collapse{
        background-color: transparent !important;
        border: none !important;
        color: white !important;
        & .ivu-collapse-item{
            margin-bottom: 0px;
            border-top: none !important;
            & .ivu-collapse-header{
                height: auto !important;
                line-height: 25px;
                padding: 0 !important;
                color: white;
                display: flex;
                align-items: center;
                border: none;
                //border-radius: 15px;
                //font-weight: bold;
                & i {
                    order: 2;
                    position: absolute;
                    right: 0;
                    top: 30%;
                    transform: rotate(90deg);
                }
            }
            & .ivu-collapse-content{
                background-color: transparent;
                color: white;
                border-top: none;
                & a {
                    color: white;
                }
            }
            &.ivu-collapse-item-active{
                & i.ivu-icon{
                    transform: rotate(270deg);
                }
            }
        }
    }

    .ivu-collapse > .ivu-collapse-item.ivu-collapse-item-active > .ivu-collapse-header{
        border-bottom: none !important;
    }

    .txt-justified{
        text-align: justify;
    }

    ul.drop-list{
        list-style: none;
        & li {
            position: relative;
            padding-left: 25px;
            margin-bottom: 15px;
            & span{
                width: 100%;
                text-align: justify;
                &:after{
                    position: absolute;
                    left: 0;
                    top: 2px;
                    content: '';
                    width: 15px;
                    height: 15px;
                    border-radius: 0 50% 50% 50%;
                    border: 3px solid #58c7d8;
                    background-color: #58c7d8;
                    transform: rotate(-134deg);
                }
            }
        }
    }

    .prgs-serv-item{
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        & img {
            width: 40px;
            //min-width: 40px;
        }
        & p {
            margin-left: 15px;
        }
    }

    .programs-attach-file{
        & input[type="file"]{
            opacity: 0;
        }
        & .attached-file{
            padding: 10px;
            border: 1px solid white;
            background-color: white;
            border-radius: 5px;
            display: flex;
            align-items: center;
            & p {
                flex-grow: 1;
                color: black;
                font-size: 0.8em;
                margin-right: 10px;
            }
            & button {
                min-width: 32px;
            }
        }
    }

    .rpm-radio-button{
        display: flex !important;
        height: auto !important;
        margin-bottom: 20px;
        flex-direction: row;
        & p {
            flex-grow: 1;
            margin-top: -3px;
            line-height: 1.5;
            margin-left: 5px;
            font-size: 15px;
            white-space: pre-line;
        }
        &.ivu-radio-wrapper-disabled{
            & .ivu-radio{
                opacity: 0.7;
            }
            & p {
                opacity: 0.7;
            }
        }
    }
    
</style>
