<template>
    <div class="full-grid">
        <PatientSignUpPopup/>
        <div class="dashboard-grid">
            <div class="center-grid doctor">
                <div @click="$router.push('/doctor/services')" class="item one">
                    <img src="/images/menu/doctors/servicio.png" alt="">
                    <p>Nuestros programas y servicios para pacientes</p>
                </div>
                <div @click="$router.push('/doctor/contact')" class="item two">
                    <img src="/images/menu/doctors/contacto.png" alt="">
                    <p>Nuestros puntos de contacto para médicos y pacientes</p>
                </div>
                <div @click="$router.push('/doctor/patient/sign-up')" class="item three">
                    <img src="/images/menu/doctors/preregistro-paciente.png" alt="">
                    <p>Pre-Registro de Pacientes</p>
                </div>
                <div @click="$router.push('/doctor/my-patients')" class="item four">
                    <img src="/images/menu/doctors/seguimiento-paciente.png" alt="">
                    <p>Seguimiento de mis Pacientes</p>
                </div>
                <div @click="$router.push('/resources')" class="item five">
                    <img src="/images/menu/doctors/more-info.png" alt="">
                    <p>Recursos adicionales para el médico</p>
                </div>
            </div>
        </div>
        <TemplateFooter/>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import PatientSignUpPopup from "./components/PatientSignupPopup"

    export default {
        data(){
            return {
            }
        },
        computed: {
            ...mapGetters([
                "user",
            ]),
        },
        methods: {
            verifyUser(){
                if(this.user){
                    if(this.user.password_reset_required) this.$router.push("/password-reset-safe")
                }
            }
        },
        mounted(){
            this.verifyUser()

            // this.$root.OneSignalInit(this.user)
        },
        components: {
            PatientSignUpPopup,
        }
    }
</script>