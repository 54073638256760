<template>
    <Grid />
</template>

<script>
    import Grid from './../components/Report/DoctorReport.vue'
    export default {
        components: {
            Grid,
        }
    }
</script>