<template>
    <TemplateContent>
        <div style="display: flex; flex-direction: column; height: 80vh;">
            <h1 class="section-title">Inicio de Sesión</h1>
            <Form ref="loginForm" :model="login" :rules="ruleInline">
                <FormItem prop="user">
                    <p class="form-label">Usuario</p>
                    <Input size="large" type="email" v-model="login.user" placeholder="ejemplo@ejemplo.com"/>
                </FormItem>
                <FormItem prop="password">
                    <p class="form-label">Contraseña</p>
                    <Input size="large" v-model="login.password" type="password" placeholder=""/>
                </FormItem>
                <FormItem>
                    <Button size="small" style="float: right; color: white;" type="text" @click="$router.push('/password-reset')">¿Olvidaste tu contraseña?</Button>
                    <!-- <Button :loading="loading" size="small" style="float: right; color: white;" type="text" @click="showRecoverUserDialog">¿Es Médico y no recuerda su usuario? </Button> -->
                    <Button :loading="loading" size="large" style="margin-top: 15px" long type="primary" @click="loginHandler">Ingresar</Button>
                </FormItem>
                <!-- <iButton style="float: right;" type="text" size="small">¿Haz olvidado tu contraseña?</iButton> -->
            </Form>
        </div>
        <!-- <RecoverUser/> -->
    </TemplateContent>
</template>

<script>
    // /* global EventBus */
    // import RecoverUser from "./components/RecoverUser"

    export default {
        data(){
            return {
                loading: false,
                login: {
                    user: "",
                    password: "",
                },
                ruleInline: {
                    user: [
                        {
                            required: true, 
                            message: 'Usuario es requerido', 
                            trigger: 'blur' 
                        }
                    ],
                    password: [
                        { 
                            required: true, 
                            message: 'Contraseña es requerida',
                            trigger: 'blur' 
                        },
                    ]
                },
            }
        },
        methods: {
            loginHandler(){
                // let self = this
                this.$refs['loginForm'].validate(valid => {
                    if(valid){
                        this.loading = true
                        /*global axios */
                        axios.post(this.$root.API_URL+"/auth/signIn/app", { email: this.login.user, password: this.login.password })
                        .then(response => {
                            if(response.data.success){
                                // Cookies.set('API_TOKEN', response.data.token, { expires: 364 })
                                window.dexie.config.add({ name: 'API_TOKEN', value: response.data.token })
                                // this.$root.OneSignalInit(response.data.user)
                                setTimeout(() => {
                                    location.reload()
                                }, 500)
                            }
                            else {
                                this.$Message.error(response.data.message)
                                this.loading = false
                            }
                        })
                        .catch(() => {
                            this.$Message.error({
                                content: "Algo salió mal",
                                duration: 6,
                            })
                            this.loading = false
                        })
                    }
                })
            },
            // showRecoverUserDialog(){
            //     EventBus.$emit("show-recover-user-dialog", true)
            // }
        },
        components: {
            // RecoverUser,
        }
    }
</script>

<style lang="sass">
    .sign-in-footer
        display: flex
        justify-content: flex-end
        flex-direction: column
        flex-grow: 1
        padding: 30px 0
        text-align: center
        font-size: 10px
        & p
            color: white
</style>