<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h1 class="section-title">Servicio de Acceso</h1>
            
            <p>Fomentamos el acceso con:</p>
            <br>

            <div class="pathology-menu-button no-border no-shadow">
                <img src="/images/doctors-icons/IntegralPass.png" alt="">
                <p style="font-weight: normal">IntegralPass</p>
            </div>

            <hr>
            <br>

            <div v-if="ShowOnco" class="access-logo">
                <img src="/images/ONCO-logo.png" alt="">
            </div>
            <br>

            <p>Roche ofrece un programa de acceso a tratamientos innovadores con beneficios personalizados, tanto a pacientes con póliza de gastos médicos limitadas, como a pacientes o pago de bolsillo.</p>
            <br>

            <p>Tipo de servicio: Adicional y definido de acuerdo a los servicio con que cuenta cada línea terapéutica.</p>
        </TemplateContent>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                program: '',
            }
        },
        computed: {
            ShowOnco(){
                if ( this.program == 'Mircera' ) return false
                else return true
            }
        },
        mounted(){
            this.program = this.$route.query.program || ''
        }
    }
</script>

<style lang="scss">
    .access-logo{
        width: 100%;
        border-radius: 15px;
        padding: 35px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        & img {
            width: 95%;
        }
    }
</style>