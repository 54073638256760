<template>
    <Grid/>
</template>

<script>
    import Grid from "./../../components/Doctor/Services/Information/components/Videos/Grid"

    export default {
        components: {
            Grid,
        }    
    }
</script>