<template>
    <el-dialog class="doctor-dialog" title="" :visible.sync="show" width="90%">
        <h2 style="text-align: center; word-break: break-word; color: white; font-weight: normal;">¿Ha sido visitado por un Asesor Roche?</h2>
        <br>
        <Row :gutter="16">
            <i-col span="12">
                <Button @click="showWhoVisitedYouPopup" long type="default">Si</Button>
            </i-col>
            <i-col span="12">
                <Button @click="showHowDidYouHearPopup" long type="default">No</Button>
            </i-col>
        </Row>
    </el-dialog>
</template>

<script>
    export default {
        data(){
            return {
                show: false,
            }
        },
        methods: {
            showWhoVisitedYouPopup(){
                this.show = false
                this.$store.dispatch("SET_DOCTOR_SIGNUP", {
                        data: {
                            visited: true,
                        },
                    })
                    this.$router.push("/doctor/sign-up")
            },
            showHowDidYouHearPopup(){
                this.show = false
                EventBus.$emit("show-doctors-sign-up-how-did-you-hear-about-popup", true)
            }
        },
        mounted(){
            /*global EventBus */
            EventBus.$on("show-doctors-sign-up-question-popup", (value) => {
                this.show = value
            })
        }
    }
</script>