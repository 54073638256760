import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import './plugins'
import './filters'

import Empty from "./components/Empty"
import Footer from "./components/Footer"
import Service from "./components/templates/Service"
import Loading from "./components/Loading"
import Content from "./components/templates/Content"
import Pathology from "./components/templates/Pathology"

import ServiceCheckbox from './components/General/ServiceCheckbox/Component'
import ServiceCheckboxGroup from './components/General/ServiceCheckbox/Group'
Vue.component('ServiceGroup', ServiceCheckboxGroup)
Vue.component('ServiceCheckbox', ServiceCheckbox)

import FormRadioGroup from './components/General/FormRadio/Group'
import FormRadioComponent from './components/General/FormRadio/Component'
Vue.component('FormRadio', FormRadioComponent)
Vue.component('FormRadioGroup', FormRadioGroup)

Vue.component("Empty", Empty)
Vue.component("Loading", Loading)
Vue.component("ServiceContent", Service)
Vue.component("TemplateFooter", Footer)
Vue.component("TemplateContent", Content)
Vue.component("PathologyContent", Pathology)

Vue.config.productionTip = false

Vue.prototype.$productNameFormat = function(name){
    if( name.startsWith('Tecentriq') ){
        let pieces = name.split(' ')
        pieces[0] = `${pieces[0]}&reg;`
        return pieces.join(' ')
    }
    else return `${name}&reg;`
}

// import OneSignalMixin from "./mixins/onesignal"

new Vue({
    router,
    store,
    // mixins: [OneSignalMixin],
    data(){
        return {
            APP_URL: process.env.VUE_APP_APP_URL,
            API_URL: process.env.VUE_APP_API_URL,
        }
    },
    watch: {
        '$route' () {
            /*global EventBus */
            EventBus.$emit("toggle-drawer", false)
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        }
    },
    methods: {
        getCookie(cname) {
            /*global Cookies */
            let cookie = Cookies.get(cname)

            if(cookie) return cookie 
            else return ""
        },
        deleteCookie(name) {
            Cookies.remove(name)
        },
        getUser(){
            /*global axios */
            axios.post(this.API_URL+"/auth/user")
            .then(response => {
                if(response.data.user){
                    this.$store.dispatch("SET_USER", {
                        user: response.data.user,
                    })
                }
            })
            .catch(() => {
                // this.deleteCookie("API_TOKEN")
                window.dexie.config.get({ name: 'API_TOKEN' }, e => {
                    if (e) window.dexie.config.delete(e.id)
                })

                this.$router.push("/")
            })
        }
    },
    mounted() {
        
        window.dexie.config.get({ name: 'API_TOKEN' }, e => {
            if (e) this.getUser()
        })

        window.$('.prd-service-btn').on('click', function(){
            let target_name = window.$(this).attr('aria-target')
            let target = window.$(`#${target_name}`)

            let target_wrapper = window.$(`#${target_name} .prg-service-content--wrapper`)
            let is_expanded = window.$(target[0]).attr('aria-expanded') || "false"

            let target_h = `${target_wrapper[0].clientHeight || 0}px`

            if(is_expanded == "true"){
                target[0].style.height = "0px"
                window.$(target[0]).attr('aria-expanded', 'false')
                window.$(this).toggleClass('active')
            }
            else {
                target[0].style.height = target_h
                window.$(target[0]).attr('aria-expanded', 'true')
                window.$(this).toggleClass('active')
            }
        })
        
    },
    render: h => h(App)
}).$mount('#app')
