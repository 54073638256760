<template>
    <div>
        <TemplateContent>
            <h1 class="section-title">Registro</h1>
            <Form ref="signupForm" :model="signup" :rules="ruleInline">
                <FormItem prop="name">
                    <p class="form-label">Nombre completo</p>
                    <Input size="large" type="text" v-model="signup.name" placeholder=""/>
                </FormItem>
                <FormItem prop="professional_id">
                    <p class="form-label">Cédula profesional</p>
                    <Input size="large" type="text" v-model="signup.professional_id" placeholder=""/>
                </FormItem>
                <FormItem prop="institute">
                    <p class="form-label">Institución</p>
                    <Select v-model="signup.institute" size="large" style="width: 100%">
                        <Option value="publico">Público</Option>
                        <Option value="privado">Privado</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <p class="form-label">Seleccione el programa de su interés</p>
                    <Select v-model="signup.pathology" size="large" placeholder="Programa" style="width: 100%">
                        <Option v-for="pathology in pathologies" :value="pathology.id" :key="pathology.id">{{ pathology.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem prop="email">
                    <p class="form-label">Correo electrónico</p>
                    <Input size="large" type="email" v-model="signup.email" placeholder=""/>
                </FormItem>
                <FormItem prop="password">
                    <p class="form-label">Contraseña</p>
                    <Input size="large" type="password" v-model="signup.password" placeholder=""/>
                </FormItem>
                <FormItem prop="phone">
                    <p class="form-label">Teléfono de contacto</p>
                    <Input size="large" type="number" v-model="signup.phone" placeholder=""/>
                </FormItem>
                <FormItem prop="days">
                    <p class="form-label">Días de preferencia para contactar</p>
                    <Select v-model="signup.days" multiple type="array" style="width: 100%">
                        <Option value="Lunes">Lunes</Option>
                        <Option value="Martes">Martes</Option>
                        <Option value="Miércoles">Miércoles</Option>
                        <Option value="Jueves">Jueves</Option>
                        <Option value="Viernes">Viernes</Option>
                        <Option value="Sábado">Sábado</Option>
                    </Select>
                </FormItem>
                <FormItem prop="times">
                    <p class="form-label">Horarios de preferencia para contactar</p>
                    <Select v-model="signup.times" type="array" multiple style="width: 100%">
                        <Option value="8am a 9am">8am a 9am</Option>
                        <Option value="9am a 10am">9am a 10am</Option>
                        <Option value="10am a 11am">10am a 11am</Option>
                        <Option value="11am a 12pm">11am a 12pm</Option>
                        <Option value="12pm a 1pm">12pm a 1pm</Option>
                        <Option value="1pm a 2pm">1pm a 2pm</Option>
                        <Option value="2pm a 3pm">2pm a 3pm</Option>
                        <Option value="4pm a 5pm">4pm a 5pm</Option>
                        <Option value="5pm a 6pm">5pm a 6pm</Option>
                    </Select>
                </FormItem>
                <FormItem prop="contact_via">
                    <p class="form-label">Medio de preferencia para contactarlo</p>
                    <Select v-model="signup.contact_via" size="large" style="width: 100%">
                        <Option value="email">Correo electrónico</Option>
                        <Option value="teléfono">Teléfono</Option>
                    </Select>
                </FormItem>
                <br>
                <FormItem>
                    <!-- <Button :loading="loading" long type="primary" @click="signupHandler">Registrarse</Button> -->
                    <InvisibleRecaptcha style="width: 100%; border: none; border-radius: 5px;" badge="none" sitekey="6Ldbd9kaAAAAAHGu0VDTisv0eYcs-NqHNsZ2b6Kr" :callback="signupHandler" :disabled="loading">
                        <Button :loading="loading" long type="primary">Registrarse</Button>
                    </InvisibleRecaptcha>
                    <br><br>
                    <p style="font-size: 0.9em; color: white; text-align: center; line-height: 1.8;">
                        Si está presentado algún problema con la plataforma al momento de crear su registro, por favor escríbanos a <a style="color: white; font-weight: bold;" href="mailto:webapporti@pen.com.mx">webapporti@pen.com.mx</a> o envíe un mensaje de WhatsApp al siguiente número 5580126835 para apoyarlo en la pronta resolución de su solicitud.
                    </p>
                    <p style="font-size: 0.9em; color: white; text-align: center; line-height: 1.8; margin-top: 5px;">
                        Horario de atención de lunes a viernes de 10:00 a 18:00 horas. Excepto días festivos. 
                    </p>
                </FormItem>
            </Form>
        </TemplateContent>
    </div>
</template>

<script>
    /* global axios, EventBus */
    import { mapGetters } from "vuex"
    import InvisibleRecaptcha from 'vue-invisible-recaptcha';

    export default {
        data(){
            return {
                signup: {
                    name: "",
                    professional_id: "",
                    institute: "publico",
                    pathology: "",
                    email: "",
                    phone: "",
                    password: "",
                    days: ["Lunes"],
                    times: ["9am a 10am"],
                    contact_via: "email",
                },
                loading: false,
                ruleInline: {
                    name: [{ required: true, message: 'Nombre es requerido', trigger: 'change' }],
                    'professional_id': [{ required: true, message: 'Cédula profesional es requerida', trigger: 'change' }],
                    institute: [{ required: true, message: 'Institución es requerida', trigger: 'change' }],
                    email: [{ required: true, message: 'Correo electrónico es requerido', trigger: 'change' }, { type: 'email', message: 'Correo electrónico no válido', trigger: 'change' }],
                    password: [{ required: true, message: 'Contraseña es requerida', trigger: 'change' }],
                    phone: [{ required: true, message: 'Teléfono es requerido', trigger: 'change' }],
                    days: [{ required: true, message: 'Información requerida' }],
                    times: [{ required: true, message: 'Información requerida' }],
                },
                pathologies: [],
            }
        },
        computed: {
            ...mapGetters([
                'doctor_signup',
            ])
        },
        methods:{
            getPathologies(){
                axios.post(this.$root.API_URL+"/pathologies/get")
                .then(response => {
                    if(response.data.success){
                        this.pathologies = response.data.pathologies
                        if(this.signup.pathology == '') this.signup.pathology = this.pathologies[0].id
                    }
                })
            },
            signupHandler(token){
                this.$refs['signupForm'].validate().then(valid => {
                    if(valid){
                        this.loading = true
                        axios.post(this.$root.API_URL+"/pre-registration/doctor", {
                            name: this.signup.name,
                            professional_id: this.signup.professional_id,
                            institute: this.signup.institute,
                            pathology: this.signup.pathology,
                            email: this.signup.email,
                            phone: this.signup.phone,
                            password: this.signup.password,
                            days: this.signup.days,
                            times: this.signup.times,
                            contact_via: this.signup.contact_via,
                            visited: this.doctor_signup.visited || false,
                            comment: this.doctor_signup.reason || null,
                            source: 'pre-registration-form-app',
                            'g-token': token,
                        }).then(response => {
                            if(response.data.success){
                                // this.$root.OneSignalInit({ id: response.data.user_id, email: this.signup.email })
                                this.$router.push("/")
                                setTimeout(() => {
                                    EventBus.$emit("show-signup-doctor-popup", true)
                                }, 500)
                            }
                            else {
                                this.$Message.error({
                                    content: response.data.message,
                                    duration: 6,
                                })
                                this.loading = false
                            }
                        })
                        .catch(() => {
                            this.$Message.error({
                                content: "Algo salió mal",
                                duration: 6,
                            })
                            this.loading = false
                        })
                    }
                })
            }
        },
        mounted(){
            this.getPathologies()
        },
        components: {
            InvisibleRecaptcha,
        }
    }
</script>

<style lang="sass">
    .sign-up-footer
        display: flex
        justify-content: flex-end
        flex-direction: column
        flex-grow: 1
        padding: 30px 0 10px 0
        text-align: center
        font-size: 8px
        & p
            color: white
            line-height: 1.6
</style>