<template>
    <div>

        <template v-if=" productName === 'Alecensa' ">

            <p class="txt-justified">Como servicios generales el programa ofrece para todos los pacientes:</p>
            <p>&nbsp;</p>

            <div style="background: #58c7d8; border:none;" @click="$router.push('/doctor/services/patients/base/atencion-pacientes')"
                 class="pathology-menu-button">
                <img class="scale" src="/images/doctors-icons/Centro.png" alt="">
                <p>Centro de atención a pacientes</p>
            </div>

            <div style="background: #19bdb4; border:none;" @click="$router.push('/doctor/services/patients/base/programa-bienestar')"
                 class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/PBienestar.png" alt="">
                <p>Plenitud Integral</p>
            </div>

            <div style="background: #0490d5; border:none;" @click="$router.push('/doctor/services/patients/base/gestoria-aseguradora')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/Gestoria.png" alt="">
                <p>Gestión de aseguradora</p>
            </div>

            <div style="background: #0032ac; border:none;" @click="$router.push('/doctor/services/patients/base/red-enfermeras')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/REnfermeras.png" alt="">
                <p>Servicio de enfermeras</p>
            </div>

            <WelcomeKitDropdown />

        </template>

        <template v-if=" productName === 'Enspryng' ">

            <p class="txt-justified">Adicional a los estudios anteriores, usted puede solicitar para su paciente los siguientes
                servicios con tan solo indicarlos en su receta médica:</p>
            <p>&nbsp;</p>

            <div class="prgs-serv-item">
                <img src="/images/icons/services/capacitacion-virtual.png" alt="">
                <p>Capacitación virtual para manejo de medicamento en casa y acompañamiento posterior</p>
            </div>
            <div class="prgs-serv-item">
                <img src="/images/icons/services/terapia-fisica.png" alt="">
                <p>Terapia física (valoración inicial presencial y sesiones de seguimiento virtual)</p>
            </div>

            <p class="txt-justified">Como servicios generales el programa ofrece para todos los pacientes:</p>
            <p>&nbsp;</p>

            <div style="background: #58c7d8; border:none;" @click="$router.push('/doctor/services/patients/base/atencion-pacientes')"
                 class="pathology-menu-button">
                <img class="scale" src="/images/doctors-icons/Centro.png" alt="">
                <p>Centro de atención a pacientes</p>
            </div>

            <div style="background: #19bdb4; border:none;" @click="$router.push('/doctor/services/patients/base/programa-bienestar')"
                 class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/PBienestar.png" alt="">
                <p>Plenitud Integral</p>
            </div>


        </template>

        <template v-if=" productName === 'Gazyva' ">

            <p class="txt-justified">Adicional a los estudios anteriores, usted puede solicitar para su paciente los siguientes
                servicios con tan solo indicarlos en su receta médica:</p>
            <p>&nbsp;</p>

            <div class="prgs-serv-item">
                <img src="/images/icons/services/aplicacion-centro-infusion.png" alt="">
                <p>Aplicación de medicamento en Centro de Infusión.</p>
            </div>
            <div class="prgs-serv-item">
                <img src="/images/icons/services/medicamento-domicilio.png" alt="">
                <p>Aplicación de medicamento en domicilio particular del paciente (previa instrucción directa del médico).</p>
            </div>
            <div class="prgs-serv-item">
                <img src="/images/icons/services/movilidad-pacientes.png" alt="">
                <p>Movilidad para pacientes mediante servicio de transportación privada (previa instrucción directa del médico).</p>
            </div>

            <p>&nbsp;</p>

            <p class="txt-justified">Como servicios generales el programa ofrece para todos los pacientes:</p>
            <p>&nbsp;</p>

            <div style="background: #58c7d8; border:none;" @click="$router.push('/doctor/services/patients/base/atencion-pacientes')"
                 class="pathology-menu-button">
                <img class="scale" src="/images/doctors-icons/Centro.png" alt="">
                <p>Centro de atención a pacientes</p>
            </div>

            <div style="background: #19bdb4; border:none;" @click="$router.push('/doctor/services/patients/base/programa-bienestar')"
                 class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/PBienestar.png" alt="">
                <p>Plenitud Integral</p>
            </div>

            <div style="background: #0490d5; border:none;" @click="$router.push('/doctor/services/patients/base/gestoria-aseguradora')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/Gestoria.png" alt="">
                <p>Gestión de aseguradora</p>
            </div>

            <div style="background: #0032ac; border:none;" @click="$router.push('/doctor/services/patients/base/red-enfermeras')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/REnfermeras.png" alt="">
                <p>Servicio de enfermeras</p>
            </div>

            <WelcomeKitDropdown />


        </template>

        <template v-if=" productName === 'Hemlibra' ">

            <p class="txt-justified">Adicional a los estudios anteriores, usted puede solicitar para su paciente los siguientes
                servicios con tan solo indicarlos en su receta médica:</p>
            <p>&nbsp;</p>

            <div class="prgs-serv-item">
                <img src="/images/icons/services/aplicacion-centro-infusion.png" alt="">
                <p>Aplicación de medicamento en Centro de Infusión.</p>
            </div>
            <div class="prgs-serv-item">
                <img src="/images/icons/services/movilidad-pacientes.png" alt="">
                <p>Movilidad para pacientes mediante servicio de transportación privada (previa instrucción directa del médico).</p>
            </div>

            <p>&nbsp;</p>

            <p class="txt-justified">Como servicios generales el programa ofrece para todos los pacientes:</p>
            <p>&nbsp;</p>

            <div style="background: #58c7d8; border:none;" @click="$router.push('/doctor/services/patients/base/atencion-pacientes')"
                 class="pathology-menu-button">
                <img class="scale" src="/images/doctors-icons/Centro.png" alt="">
                <p>Centro de atención a pacientes</p>
            </div>

            <div style="background: #19bdb4; border:none;" @click="$router.push('/doctor/services/patients/base/programa-bienestar')"
                 class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/PBienestar.png" alt="">
                <p>Plenitud Integral</p>
            </div>

            <div style="background: #0490d5; border:none;" @click="$router.push('/doctor/services/patients/base/gestoria-aseguradora')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/Gestoria.png" alt="">
                <p>Gestión de aseguradora</p>
            </div>

            <div style="background: #0032ac; border:none;" @click="$router.push('/doctor/services/patients/base/red-enfermeras')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/REnfermeras.png" alt="">
                <p>Servicio de enfermeras</p>
            </div>

            <WelcomeKitDropdown />


        </template>

        <template v-if=" productName === 'Kadcyla' ">

            <p class="txt-justified">Adicional a los estudios anteriores, usted puede solicitar para su paciente los siguientes
                servicios con tan solo indicarlos en su receta médica:</p>
            <p>&nbsp;</p>

            <div class="prgs-serv-item">
                <img src="/images/icons/services/aplicacion-centro-infusion.png" alt="">
                <p>Aplicación de medicamento en Centro de Infusión.</p>
            </div>

            <p>&nbsp;</p>

            <p class="txt-justified">Como servicios generales el programa ofrece para todos los pacientes:</p>
            <p>&nbsp;</p>

            <div style="background: #58c7d8; border:none;" @click="$router.push('/doctor/services/patients/base/atencion-pacientes')"
                 class="pathology-menu-button">
                <img class="scale" src="/images/doctors-icons/Centro.png" alt="">
                <p>Centro de atención a pacientes</p>
            </div>

            <div style="background: #19bdb4; border:none;" @click="$router.push('/doctor/services/patients/base/programa-bienestar')"
                 class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/PBienestar.png" alt="">
                <p>Plenitud Integral</p>
            </div>

            <div style="background: #0490d5; border:none;" @click="$router.push('/doctor/services/patients/base/gestoria-aseguradora')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/Gestoria.png" alt="">
                <p>Gestión de aseguradora</p>
            </div>

            <div style="background: #0032ac; border:none;" @click="$router.push('/doctor/services/patients/base/red-enfermeras')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/REnfermeras.png" alt="">
                <p>Servicio de enfermeras</p>
            </div>

            <WelcomeKitDropdown />


        </template>

        <template v-if=" productName === 'Mircera' ">

            <p class="txt-justified">Como servicios generales el programa ofrece para todos los pacientes:</p>
            <p>&nbsp;</p>

            <div style="background: #58c7d8; border:none;" @click="$router.push('/doctor/services/patients/base/atencion-pacientes')"
                 class="pathology-menu-button">
                <img class="scale" src="/images/doctors-icons/Centro.png" alt="">
                <p>Centro de atención a pacientes</p>
            </div>

            <div style="background: #19bdb4; border:none;" @click="$router.push('/doctor/services/patients/base/programa-bienestar')"
                 class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/PBienestar.png" alt="">
                <p>Plenitud Integral</p>
            </div>

            <div style="background: #0490d5; border:none;" @click="$router.push('/doctor/services/patients/base/gestoria-aseguradora')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/Gestoria.png" alt="">
                <p>Gestión de aseguradora</p>
            </div>

            <div style="background: #0032ac; border:none;" @click="$router.push('/doctor/services/patients/base/red-enfermeras')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/REnfermeras.png" alt="">
                <p>Servicio de enfermeras</p>
            </div>

            <WelcomeKitDropdown />


        </template>

        <template v-if=" productName === 'Ocrevus' ">

            <p class="txt-justified">Adicional a los estudios anteriores, usted puede solicitar para su paciente los siguientes
                servicios con tan solo indicarlos en su receta médica:</p>
            <p>&nbsp;</p>

            <div class="prgs-serv-item">
                <img src="/images/icons/services/aplicacion-centro-infusion.png" alt="">
                <p>Aplicación de medicamento en Centro de Infusión.</p>
            </div>
            <div class="prgs-serv-item">
                <img src="/images/icons/services/medicamento-domicilio.png" alt="">
                <p>Aplicación de medicamento en domicilio particular del paciente (previa instrucción directa del médico).</p>
            </div>

            <p>&nbsp;</p>

            <p class="txt-justified">Como servicios generales el programa ofrece para todos los pacientes:</p>
            <p>&nbsp;</p>

            <div style="background: #58c7d8; border:none;" @click="$router.push('/doctor/services/patients/base/atencion-pacientes')"
                 class="pathology-menu-button">
                <img class="scale" src="/images/doctors-icons/Centro.png" alt="">
                <p>Centro de atención a pacientes</p>
            </div>

            <div style="background: #19bdb4; border:none;" @click="$router.push('/doctor/services/patients/base/programa-bienestar')"
                 class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/PBienestar.png" alt="">
                <p>Plenitud Integral</p>
            </div>

            <div style="background: #0490d5; border:none;" @click="$router.push('/doctor/services/patients/base/gestoria-aseguradora')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/Gestoria.png" alt="">
                <p>Gestión de aseguradora</p>
            </div>

            <div style="background: #0032ac; border:none;" @click="$router.push('/doctor/services/patients/base/red-enfermeras')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/REnfermeras.png" alt="">
                <p>Servicio de enfermeras</p>
            </div>

            <WelcomeKitDropdown />


        </template>

        <template v-if=" productName === 'Perjeta' ">

            <p class="txt-justified">Adicional a los estudios anteriores, usted puede solicitar para su paciente los siguientes
                servicios con tan solo indicarlos en su receta médica:</p>
            <p>&nbsp;</p>

            <div class="prgs-serv-item">
                <img src="/images/icons/services/aplicacion-centro-infusion.png" alt="">
                <p>Aplicación de medicamento en Centro de Infusión.</p>
            </div>

            <p>&nbsp;</p>

            <p class="txt-justified">Como servicios generales el programa ofrece para todos los pacientes:</p>
            <p>&nbsp;</p>

            <div style="background: #58c7d8; border:none;" @click="$router.push('/doctor/services/patients/base/atencion-pacientes')"
                 class="pathology-menu-button">
                <img class="scale" src="/images/doctors-icons/Centro.png" alt="">
                <p>Centro de atención a pacientes</p>
            </div>

            <div style="background: #19bdb4; border:none;" @click="$router.push('/doctor/services/patients/base/programa-bienestar')"
                 class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/PBienestar.png" alt="">
                <p>Plenitud Integral</p>
            </div>

            <div style="background: #0490d5; border:none;" @click="$router.push('/doctor/services/patients/base/gestoria-aseguradora')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/Gestoria.png" alt="">
                <p>Gestión de aseguradora</p>
            </div>

            <div style="background: #0032ac; border:none;" @click="$router.push('/doctor/services/patients/base/red-enfermeras')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/REnfermeras.png" alt="">
                <p>Servicio de enfermeras</p>
            </div>

            <WelcomeKitDropdown />


        </template>

        <template v-if=" productName === 'Pulmozyme' ">

            <p class="txt-justified">Adicional a los estudios anteriores, usted puede solicitar para su paciente los siguientes
                servicios con tan solo indicarlos en su receta médica:</p>
            <p>&nbsp;</p>

            <div class="prgs-serv-item">
                <img src="/images/icons/services/apoyo-metabolico.png" alt="">
                <p>Programa de apoyo metabólico</p>
            </div>
            <div class="prgs-serv-item">
                <img src="/images/icons/services/rehabilitacion-pulmonar.png" alt="">
                <p>Programa de rehabilitación pulmonar</p>
            </div>

            <p>&nbsp;</p>

            <p class="txt-justified">Como servicios generales el programa ofrece para todos los pacientes:</p>
            <p>&nbsp;</p>

            <div style="background: #58c7d8; border:none;" @click="$router.push('/doctor/services/patients/base/atencion-pacientes')"
                 class="pathology-menu-button">
                <img class="scale" src="/images/doctors-icons/Centro.png" alt="">
                <p>Centro de atención a pacientes</p>
            </div>

            <div style="background: #19bdb4; border:none;" @click="$router.push('/doctor/services/patients/base/programa-bienestar')"
                 class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/PBienestar.png" alt="">
                <p>Plenitud Integral</p>
            </div>

            <div style="background: #0490d5; border:none;" @click="$router.push('/doctor/services/patients/base/gestoria-aseguradora')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/Gestoria.png" alt="">
                <p>Gestión de aseguradora</p>
            </div>

            <div style="background: #0032ac; border:none;" @click="$router.push('/doctor/services/patients/base/red-enfermeras')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/REnfermeras.png" alt="">
                <p>Servicio de enfermeras</p>
            </div>

            <WelcomeKitDropdown />


        </template>

        <template v-if=" productName === 'Roactemra' ">

            <p class="txt-justified">Adicional a los estudios anteriores, usted puede solicitar para su paciente los siguientes
                servicios con tan solo indicarlos en su receta médica:</p>
            <p>&nbsp;</p>

            <div class="prgs-serv-item">
                <img src="/images/icons/services/aplicacion-centro-infusion.png" alt="">
                <p>Aplicación de medicamento en Centro de Infusión.</p>
            </div>
            <div class="prgs-serv-item">
                <img src="/images/icons/services/medicamento-domicilio.png" alt="">
                <p>Aplicación de medicamento en domicilio particular del paciente (previa instrucción directa del médico).</p>
            </div>


            <p>&nbsp;</p>

            <p class="txt-justified">Como servicios generales el programa ofrece para todos los pacientes:</p>
            <p>&nbsp;</p>

            <div style="background: #58c7d8; border:none;" @click="$router.push('/doctor/services/patients/base/atencion-pacientes')"
                 class="pathology-menu-button">
                <img class="scale" src="/images/doctors-icons/Centro.png" alt="">
                <p>Centro de atención a pacientes</p>
            </div>

            <div style="background: #19bdb4; border:none;" @click="$router.push('/doctor/services/patients/base/programa-bienestar')"
                 class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/PBienestar.png" alt="">
                <p>Plenitud Integral</p>
            </div>

            <div style="background: #0490d5; border:none;" @click="$router.push('/doctor/services/patients/base/gestoria-aseguradora')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/Gestoria.png" alt="">
                <p>Gestión de aseguradora</p>
            </div>

            <div style="background: #0032ac; border:none;" @click="$router.push('/doctor/services/patients/base/red-enfermeras')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/REnfermeras.png" alt="">
                <p>Servicio de enfermeras</p>
            </div>

            <WelcomeKitDropdown />


        </template>

        <template v-if=" productName === 'Tecentriq - indicación mama' ">

            <p class="txt-justified">Como servicios generales el programa ofrece para todos los pacientes:</p>
            <p>&nbsp;</p>

            <div style="background: #58c7d8; border:none;" @click="$router.push('/doctor/services/patients/base/atencion-pacientes')"
                 class="pathology-menu-button">
                <img class="scale" src="/images/doctors-icons/Centro.png" alt="">
                <p>Centro de atención a pacientes</p>
            </div>

            <div style="background: #19bdb4; border:none;" @click="$router.push('/doctor/services/patients/base/programa-bienestar')"
                 class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/PBienestar.png" alt="">
                <p>Plenitud Integral</p>
            </div>

            <div style="background: #0490d5; border:none;" @click="$router.push('/doctor/services/patients/base/gestoria-aseguradora')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/Gestoria.png" alt="">
                <p>Gestión de aseguradora</p>
            </div>

            <div style="background: #0032ac; border:none;" @click="$router.push('/doctor/services/patients/base/red-enfermeras')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/REnfermeras.png" alt="">
                <p>Servicio de enfermeras</p>
            </div>

            <WelcomeKitDropdown />


        </template>

        <template v-if=" productName === 'Tecentriq - indicación pulmón' ">

            <p class="txt-justified">Adicional a los estudios anteriores, usted puede solicitar para su paciente los siguientes
                servicios con tan solo indicarlos en su receta médica:</p>
            <p>&nbsp;</p>

            <div class="prgs-serv-item">
                <img src="/images/icons/services/aplicacion-centro-infusion.png" alt="">
                <p>Aplicación de medicamento en Centro de Infusión.</p>
            </div>

            <p>&nbsp;</p>

            <p class="txt-justified">Como servicios generales el programa ofrece para todos los pacientes:</p>
            <p>&nbsp;</p>

            <div style="background: #58c7d8; border:none;" @click="$router.push('/doctor/services/patients/base/atencion-pacientes')"
                 class="pathology-menu-button">
                <img class="scale" src="/images/doctors-icons/Centro.png" alt="">
                <p>Centro de atención a pacientes</p>
            </div>

            <div style="background: #19bdb4; border:none;" @click="$router.push('/doctor/services/patients/base/programa-bienestar')"
                 class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/PBienestar.png" alt="">
                <p>Plenitud Integral</p>
            </div>

            <div style="background: #0490d5; border:none;" @click="$router.push('/doctor/services/patients/base/gestoria-aseguradora')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/Gestoria.png" alt="">
                <p>Gestión de aseguradora</p>
            </div>

            <div style="background: #0032ac; border:none;" @click="$router.push('/doctor/services/patients/base/red-enfermeras')" class="pathology-menu-button">
                <img class="scale-3" src="/images/doctors-icons/REnfermeras.png" alt="">
                <p>Servicio de enfermeras</p>
            </div>

            <WelcomeKitDropdown />


        </template>

        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
</template>

<script>
    import WelcomeKitDropdown from "@/components/Doctor/Services/Programs/components/WelcomeKitDropdown"

    export default {
        props: {
            productName: String,
        },
        components: {
            WelcomeKitDropdown,
        }
    }
</script>