<template>
    <div>
        <ServiceCheckbox v-for="(item, i) in items" :key="`service_checkbox_list_${uuid}_${i}`" :item="item" @selected="handleSelected" :product="product" :patient="patient" />
    </div>
</template>

<script>
    import ServiceCheckbox from './Component'

    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: { required: true, type: Array },
            items: { required: true, type: [Array, Object] },
            product: { required: true, type: String },
            patient: { required: true, type: [Object, Array] },
        },
        data(){
            return {
                uuid: Math.random().toString(36).substring(7),
            }
        },
        methods: {
            handleSelected(item){
                item.checked = !item.checked

                let is_selected = window._.findIndex(this.value, (v) => {
                    return v.name == item.name
                })

                let si = window._.findIndex(this.items, (i) => {
                    return i.name == item.name
                })

                this.$set(this.items, si, item)
                if( is_selected == -1 ) this.value.push(item)
                else this.value.splice(is_selected, 1)

                this.$forceUpdate()
            }
        },
        components: {
            ServiceCheckbox,
        }    
    }
</script>