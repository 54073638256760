<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h2>Solicitud de exámenes médicos</h2>
            <template v-if="loading">
                <Loading/>
            </template>
            <template v-else>
                <i-form class="doctor-service-form" ref="form" :model="form" :rules="ruleInline" inline >
                    <br>
                    <h3>Datos del Médico</h3>
                    <br>
                    <div id="doctor-form-part">
                        <!-- Doctor name -->
                        <FormItem label="Nombre completo:" prop="doctor_name">
                            <i-input type="text" v-model="form.doctor_name" disabled placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Doctor Professional ID -->
                        <FormItem label="Cédula profesional:"  prop="doctor_professional_id">
                            <i-input type="text" v-model="form.doctor_professional_id" placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Doctor phone -->
                        <FormItem label="Teléfono:"  prop="doctor_phone">
                            <i-input ref="doctor_phone" type="number" v-model="form.doctor_phone" placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Doctor phone -->
                        <FormItem label="E-mail:"  prop="doctor_email">
                            <i-input type="text" v-model="form.doctor_email" disabled placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Doctor Hospital -->
                        <FormItem style="margin-top: -5px" label="Hospital en el que labora:" class="block"  prop="doctor_hospital">
                            <i-input type="text" v-model="form.doctor_hospital" placeholder="" style="width: 100%"></i-input>
                        </FormItem>
                        <hr>
                        <br>
                    </div>
                    <h3>Datos del Paciente</h3>
                    <p style="margin-top: 10px; font-size: 0.9em">Estimado médico le agradecemos proporcionar la información de su paciente para coordinar la realización de este estudio. </p>
                    <br>

                    <div id="patient-form-part">
                        <!-- Patient name initials -->
                        <Row :gutter="16">
                            <i-col :xs="5" :sm="5" :md="3" :lg="2">
                                <FormItem label="Iniciales" ></FormItem>
                            </i-col>
                            <i-col :xs="6" :sm="6" :md="4" :lg="4">
                                <FormItem label="" prop="patient_initials_first_last_name" class="with-footer-legend">
                                    <i-input type="text" v-model="form.patient_initials_first_last_name" placeholder="" style="width: 100%"></i-input>
                                    <p class="form-footer-legend center">Apellido paterno</p>
                                </FormItem>
                            </i-col>
                            <i-col :xs="6" :sm="6" :md="4" :lg="4">
                                <FormItem label="" prop="patient_initials_second_last_name" class="with-footer-legend">
                                    <i-input type="text" v-model="form.patient_initials_second_last_name" placeholder="" style="width: 100%"></i-input>
                                    <p class="form-footer-legend center">Apellido materno</p>
                                </FormItem>
                            </i-col>
                            <i-col :xs="6" :sm="6" :md="4" :lg="4">
                                <FormItem label="" prop="patient_initials_name" class="with-footer-legend">
                                    <i-input type="text" v-model="form.patient_initials_name" placeholder="" style="width: 100%"></i-input>
                                    <p class="form-footer-legend center">Nombre(s)</p>
                                </FormItem>
                            </i-col>
                        </Row>
                        
                        <!-- Patient name -->
                        <FormItem label="Nombre completo:"  prop="patient_name">
                            <i-input type="text" v-model="form.patient_name" disabled placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Patient birthdate -->
                        <Row :gutter="16">
                            <i-col :xs="11" :sm="11" :md="6" :lg="4">
                                <FormItem label="Fecha de nacimiento"></FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem label="" prop="patient_birth_day" class="with-footer-legend">
                                    <InputNumber :max="31" v-model="form.patient_birth_day" :formatter="formatWithZero" placeholder="DD" style="width: 100%"></InputNumber>
                                    <p class="form-footer-legend center">Día</p>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem label="" prop="patient_birth_month" class="with-footer-legend">
                                    <InputNumber :max="12" v-model="form.patient_birth_month" :formatter="formatWithZero" placeholder="MM" style="width: 100%"></InputNumber>
                                    <p class="form-footer-legend center">Mes</p>
                                </FormItem>
                            </i-col>
                            <i-col span="5">
                                <FormItem label="" prop="patient_birth_year" class="with-footer-legend">
                                    <i-input type="text" v-model="form.patient_birth_year" placeholder="AAAA" style="width: 100%"></i-input>
                                    <p class="form-footer-legend center">Año</p>
                                </FormItem>
                            </i-col>
                        </Row>

                         <!-- Patient gender -->
                        <Row :gutter="16">
                            <i-col :xs="5" :sm="5" :md="3" :lg="2">
                                <FormItem label="Género" ></FormItem>
                            </i-col>
                            <i-col span="12">
                                <FormRadioGroup @callback="validateGenderRadio">
                                    <FormRadio v-model="form.patient_gender" mvalue="F" label="F" />
                                    <FormRadio v-model="form.patient_gender" mvalue="M" label="M" />
                                </FormRadioGroup>
                                <div style="margin-top: -5px" class="radio-group-error-text" v-if="radio_validation.gender == false">Requerido</div>
                            </i-col>
                        </Row>
                        <div style="height: 5px;"></div>

                        <!-- Patient phone 1 -->
                        <FormItem label="Teléfono de contacto 1:"  prop="patient_phone_1">
                            <i-input type="text" v-model="form.patient_phone_1" placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Patient phone 2 -->
                        <FormItem label="Teléfono de contacto 2:"  prop="patient_phone_2">
                            <i-input type="text" v-model="form.patient_phone_2" placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Patient email -->
                        <FormItem label="E-mail de contacto:"  prop="patient_email">
                            <i-input type="text" v-model="form.patient_email" disabled placeholder="" style="width: 100%"></i-input>
                        </FormItem>
                        <br>

                        <hr>
                        <br>
                        <p style="font-size: 0.9em; margin-bottom: -15px"><strong>Pruebas solicitadas:</strong></p>
                        <div class="patientes-extra-s">
                            <div style="height: 10px;"></div>
                            <CheckboxGroup class="form-services-form--checkbox-group" v-model="services_related" @on-change="() => { }">
                                <Checkbox @click.stop.native="fillCombo"  :disabled="DisabledComboFields" label="Hepatitis ABC">
                                    <span class="label">Hepatitis ABC</span>
                                </Checkbox>
                                <Checkbox @click.stop.native="fillCombo"  :disabled="DisabledComboFields" label="Perfil hepático I">
                                    <span class="label">Perfil hepático I</span>
                                </Checkbox>
                                <Checkbox @click.stop.native="fillCombo"  :disabled="DisabledComboFields" label="Biometría hemática">
                                    <span class="label">Biometría hemática</span>
                                </Checkbox>
                                <Checkbox @click.stop.native="fillCombo"  :disabled="DisabledComboFields" label="Química sanguínea de 4 elementos">
                                    <span class="label">Química sanguínea de 4 elementos</span>
                                </Checkbox>
                                <Checkbox :disabled="!DisabledComboFields" label="Pruebas psicométricas">
                                    <span class="label">Pruebas psicométricas</span>
                                </Checkbox>
                            </CheckboxGroup>

                            <!-- Request date -->
                            <Row :gutter="16">
                                <i-col :xs="11" :sm="11" :md="6" :lg="4">
                                    <FormItem label="Fecha de solicitud"></FormItem>
                                </i-col>
                                <i-col span="4">
                                    <FormItem label="" prop="request_date_day" class="with-footer-legend">
                                        <InputNumber :max="31" v-model="form.request_date_day" :formatter="formatWithZero" placeholder="DD" style="width: 100%"></InputNumber>
                                        <p class="form-footer-legend center">Día</p>
                                    </FormItem>
                                </i-col>
                                <i-col span="4">
                                    <FormItem label="" prop="request_date_month" class="with-footer-legend">
                                        <InputNumber :max="12" v-model="form.request_date_month" :formatter="formatWithZero" placeholder="MM" style="width: 100%"></InputNumber>
                                        <p class="form-footer-legend center">Mes</p>
                                    </FormItem>
                                </i-col>
                                <i-col span="5">
                                    <FormItem label="" prop="request_date_year" class="with-footer-legend">
                                        <i-input type="text" v-model="form.request_date_year" placeholder="AAAA" style="width: 100%"></i-input>
                                        <p class="form-footer-legend center">Año</p>
                                    </FormItem>
                                </i-col>
                            </Row>
                        </div>

                    </div>
                    <br>
                    <hr>

                    <br>
                    <p class="font-size: 0.9em"><strong>Expreso tanto mi consentimiento como el del paciente que recibirá el servicio para realizar los estudios necesarios y contar con el resultado de los mismos.</strong></p>
                    <br>
                    <div style="width: 100%">
                        <FormRadio v-model="accepted" :mvalue="true" label="Acepto y comprendo los términos del servicio solicitado" label_position="right" :label_style="{ 'text-align': 'left', 'line-height': '21px', 'margin-top': '-5px', 'margin-left' : '15px', 'font-size': '0.9em' }"/>
                    </div>
                    <br><br>
                    <Button :disabled="!accepted" long size="large" type="primary" :loading="is_loading" @click="handleSubmit">Finalizar y enviar solicitud</Button>
                    <div style="height: 25px"></div>
                    <Button style="background-color: #103c8d" long size="large" type="primary" @click="handleCancel">Cancelar solicitud</Button>
                    <br><br>
                    <div style="font-size: 0.6em; text-align: center;">
                        <p>M-MX-00002621</p>
                    </div>
                </i-form>
            </template>
        </TemplateContent>
    </div>
</template>

<script>
    import form from './form'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [form],
        data(){
            return {
                ug: true,
                loading: true,
                patient: {},
                accepted: false,
                form: {
                    doctor_name: '',
                    doctor_professional_id: '',
                    doctor_phone: '',
                    doctor_email: '',
                    doctor_hospital: '',

                    patient_initials_name: '',
                    patient_initials_first_last_name: '',
                    patient_initials_second_last_name: '',
                    patient_name: '',
                    patient_phone_1: '',
                    patient_phone_2: '',
                    patient_email: '',
                    patient_gender: '',
                    patient_birth_day: null,
                    patient_birth_month: null,
                    patient_birth_year: null,

                    request_date_day: null,
                    request_date_month: null,
                    request_date_year: null,
                },
                ruleInline: {
                    doctor_name: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                    doctor_professional_id: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                    doctor_phone: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                        
                    ],
                    doctor_email: [
                        { required: true, type: 'email', message: 'Requerido', trigger: 'change' },
                    ],
                    doctor_hospital: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_initials_first_last_name: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[A-Za-z]/ },
                    ],
                    patient_initials_second_last_name: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[A-Za-z]/ },
                        { max: 3, message: 'Máximo tres caracteres' }
                    ],
                    patient_initials_name: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[A-Za-z]/ },
                        { max: 3, message: 'Máximo tres caracteres' }
                    ],
                    patient_name: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_phone_1: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_phone_2: [
                        { required: false, trigger: 'change' },
                    ],
                    patient_email: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_birth_day: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    patient_birth_month: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    patient_birth_year: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[0-9]*$/ },
                        { min: 4, message: '4 dígitos', trigger: 'change' },
                        { max: 4, message: '4 dígitos', trigger: 'change' },
                    ],
                    request_date_day: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    request_date_month: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    request_date_year: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[0-9]*$/ },
                        { min: 4, message: '4 dígitos', trigger: 'change' },
                        { max: 4, message: '4 dígitos', trigger: 'change' },
                    ],
                },
                is_loading: false,
                radio_validation: {
                    gender: null,
                },
                services_related: [],
            }
        },
        computed: {
            ...mapGetters([
                'patient_services',
            ]),
            DisabledComboFields: {
                get(){
                    return this.$route.query.target != 'Pruebas psicométricas'
                },
                set(newValue){
                    return newValue
                }
            }
        },
        methods: {
            getPatient(){
                window.axios.post(this.$root.API_URL+ `/doctors/get-patient/${this.$route.params.id}`)
                .then(response => {
                    if(response.data.success){
                        this.patient = response.data.patient

                        this.fillForm(response.data.doctor)

                        this.loading = false
                        this.is_loading = false
                    }
                })
            },
            validateGenderRadio(){
                this.radio_validation.gender = this.form.patient_gender != ''
            },
            fillCombo(){
                if( this.services_related.includes('Hepatitis ABC') || this.services_related.includes('Biometría hemática') || this.services_related.includes('Perfil hepático I') || this.services_related.includes('Química sanguínea de 4 elementos') ){
                    let self = this
                    this.services_related = []
    
                    setTimeout(() => {
                        self.services_related = [
                            'Pruebas psicométricas',
                        ]
                    }, 200)
                }
                else {
                    let self = this
                    this.services_related = []
    
                    setTimeout(() => {
                        self.services_related = [
                            'Pruebas psicométricas',
                            'Hepatitis ABC',
                            'Biometría hemática',
                            'Perfil hepático I',
                            'Química sanguínea de 4 elementos',
                        ]
                    }, 100)
                }
            },
            handleSubmit(){

                this.validateGenderRadio()

                let radio_validate = Object.values(this.radio_validation).indexOf(false) > -1

                this.$refs['form'].validate((valid) => {
                    if (valid && !radio_validate) {
                        
                        this.is_loading = true
                        let _uuid = window.uuid()
                        let category = this.$route.query.category
                        this.resetFields()


                        // UB belongs to Service legend parent
                        let parent_id = window._.findIndex(this.patient_services[category], (service) => {
                            return service.id == this.$route.query.source
                        })

                        if( parent_id != -1 ){

                            window._.each(this.services_related, (service_name) => {

                                let sis = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                                    return service.name == service_name
                                })

                                if( sis != -1 ){
    
                                    let current_service = this.patient_services[category][parent_id].services[sis]
                                    let new_item = {
                                        ...current_service,
                                        uuid: _uuid,
                                        checked: true,
                                        form_data: this.form,
                                    }
    
                                    this.$store.dispatch('SET_PATIENT_SERVICE', {
                                        category: category,
                                        parent_id: parent_id,
                                        service: new_item
                                    })
    
                                }
                                else {
                                    this.is_loading = false
                                    this.$Message.error('No se pudo completar la acción');
                                }

                            })

                            this.$router.push(`/doctor/my-patient/${this.patient.id}`)

                        }


                    } else {
                        this.$Message.error('Completa la información faltante');
                    }
                })
            },
            handleCancel(){
                /**
                 * Hepatitis ABC
                 * Perfil hepático I
                 * Biometría hemática
                 * Química sanguínea de 4 elementos
                 * Pruebas psicométricas
                 */

                let category = this.$route.query.category
                let source = this.$route.query.source

                let parent_id = window._.findIndex(this.patient_services[category], (service) => {
                    return service.id == source
                })

                if( parent_id != -1 ){
                    let si_hepatitis = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Hepatitis ABC"
                    })
    
                    let si_perfil = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Perfil hepático I"
                    })
    
                    let si_biometria = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Biometría hemática"
                    })
    
                    let si_quimica = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Química sanguínea de 4 elementos"
                    })
    
                    let si_pruebas = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Pruebas psicométricas"
                    })
                    
                    if( si_hepatitis != -1 ){
                        let citro_current_service = this.patient_services[category][parent_id].services[si_hepatitis]
                        let new_item = {
                            ...citro_current_service,
                            checked: false,
                            form_data: null,
                        }

                        this.$store.dispatch('SET_PATIENT_SERVICE', {
                            parent_id: parent_id,
                            category: category,
                            service: new_item
                        })

                    }

                    if( si_perfil != -1 ){
                        let citro_current_service = this.patient_services[category][parent_id].services[si_perfil]
                        let new_item = {
                            ...citro_current_service,
                            checked: false,
                            form_data: null,
                        }

                        this.$store.dispatch('SET_PATIENT_SERVICE', {
                            parent_id: parent_id,
                            category: category,
                            service: new_item
                        })

                    }

                    if( si_biometria != -1 ){
                        let citro_current_service = this.patient_services[category][parent_id].services[si_biometria]
                        let new_item = {
                            ...citro_current_service,
                            checked: false,
                            form_data: null,
                        }

                        this.$store.dispatch('SET_PATIENT_SERVICE', {
                            parent_id: parent_id,
                            category: category,
                            service: new_item
                        })

                    }

                    if( si_quimica != -1 ){
                        let citro_current_service = this.patient_services[category][parent_id].services[si_quimica]
                        let new_item = {
                            ...citro_current_service,
                            checked: false,
                            form_data: null,
                        }

                        this.$store.dispatch('SET_PATIENT_SERVICE', {
                            parent_id: parent_id,
                            category: category,
                            service: new_item
                        })

                    }

                    if( si_pruebas != -1 ){
                        let citro_current_service = this.patient_services[category][parent_id].services[si_pruebas]
                        let new_item = {
                            ...citro_current_service,
                            checked: false,
                            form_data: null,
                        }

                        this.$store.dispatch('SET_PATIENT_SERVICE', {
                            parent_id: parent_id,
                            category: category,
                            service: new_item
                        })

                    }
                }

                this.$router.push(`/doctor/my-patient/${this.patient.id}`)

            },
            resetFields(){
                /**
                 * Hepatitis ABC
                 * Perfil hepático I
                 * Biometría hemática
                 * Química sanguínea de 4 elementos
                 * Pruebas psicométricas
                 */

                let category = this.$route.query.category
                let source = this.$route.query.source

                let parent_id = window._.findIndex(this.patient_services[category], (service) => {
                    return service.id == source
                })

                if( parent_id != -1 ){
                    let si_hepatitis = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Hepatitis ABC"
                    })
    
                    let si_perfil = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Perfil hepático I"
                    })
    
                    let si_biometria = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Biometría hemática"
                    })
    
                    let si_quimica = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Química sanguínea de 4 elementos"
                    })
    
                    let si_pruebas = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Pruebas psicométricas"
                    })
                    
                    if( si_hepatitis != -1 ){
                        let citro_current_service = this.patient_services[category][parent_id].services[si_hepatitis]
                        let new_item = {
                            ...citro_current_service,
                            checked: false,
                            form_data: null,
                        }

                        this.$store.dispatch('SET_PATIENT_SERVICE', {
                            parent_id: parent_id,
                            category: category,
                            service: new_item
                        })

                    }

                    if( si_perfil != -1 ){
                        let citro_current_service = this.patient_services[category][parent_id].services[si_perfil]
                        let new_item = {
                            ...citro_current_service,
                            checked: false,
                            form_data: null,
                        }

                        this.$store.dispatch('SET_PATIENT_SERVICE', {
                            parent_id: parent_id,
                            category: category,
                            service: new_item
                        })

                    }

                    if( si_biometria != -1 ){
                        let citro_current_service = this.patient_services[category][parent_id].services[si_biometria]
                        let new_item = {
                            ...citro_current_service,
                            checked: false,
                            form_data: null,
                        }

                        this.$store.dispatch('SET_PATIENT_SERVICE', {
                            parent_id: parent_id,
                            category: category,
                            service: new_item
                        })

                    }

                    if( si_quimica != -1 ){
                        let citro_current_service = this.patient_services[category][parent_id].services[si_quimica]
                        let new_item = {
                            ...citro_current_service,
                            checked: false,
                            form_data: null,
                        }

                        this.$store.dispatch('SET_PATIENT_SERVICE', {
                            parent_id: parent_id,
                            category: category,
                            service: new_item
                        })

                    }

                    if( si_pruebas != -1 ){
                        let citro_current_service = this.patient_services[category][parent_id].services[si_pruebas]
                        let new_item = {
                            ...citro_current_service,
                            checked: false,
                            form_data: null,
                        }

                        this.$store.dispatch('SET_PATIENT_SERVICE', {
                            parent_id: parent_id,
                            category: category,
                            service: new_item
                        })

                    }
                }

            },
            fillForm(doctor = null){
                let category = this.$route.query.category
                let source = this.$route.query.source
                let form = null

                /**
                 * Hepatitis ABC
                 * Perfil hepático I
                 * Biometría hemática
                 * Química sanguínea de 4 elementos
                 * Pruebas psicométricas
                 */


                let parent_id = window._.findIndex(this.patient_services[category], (service) => {
                    return service.id == source
                })

                if( parent_id != -1 ){

                    let si_hepatitis = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Hepatitis ABC"
                    })
    
                    let si_perfil = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Perfil hepático I"
                    })
    
                    let si_biometria = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Biometría hemática"
                    })
    
                    let si_quimica = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Química sanguínea de 4 elementos"
                    })
    
                    let si_pruebas = window._.findIndex(this.patient_services[category][parent_id].services, (service) => {
                        return service.name == "Pruebas psicométricas"
                    })

                    if( si_hepatitis != null ){
                        let hepatitis =this.patient_services[category][parent_id].services[si_hepatitis]
                        if( hepatitis.checked == true ){
                            if( form == null ) form = hepatitis.form_data
                            if( ! this.services_related.includes(hepatitis.name) ) this.services_related.push(hepatitis.name)
                        }
                    }

                    if( si_perfil != null ){
                        let perfil =this.patient_services[category][parent_id].services[si_perfil]
                        if( perfil.checked == true ){
                            if( form == null ) form = perfil.form_data
                            if( ! this.services_related.includes(perfil.name) ) this.services_related.push(perfil.name)
                        }
                    }

                    if( si_biometria != null ){
                        let biometria =this.patient_services[category][parent_id].services[si_biometria]
                        if( biometria.checked == true ){
                            if( form == null ) form = biometria.form_data
                            if( ! this.services_related.includes(biometria.name) ) this.services_related.push(biometria.name)
                        }
                    }

                    if( si_quimica != null ){
                        let quimica =this.patient_services[category][parent_id].services[si_quimica]
                        if( quimica.checked == true ){
                            if( form == null ) form = quimica.form_data
                            if( ! this.services_related.includes(quimica.name) ) this.services_related.push(quimica.name)
                        }
                    }

                    if( si_pruebas != null ){
                        let pruebas =this.patient_services[category][parent_id].services[si_pruebas]
                        if( pruebas.checked == true ){
                            if( form == null ) form = pruebas.form_data
                            if( ! this.services_related.includes(pruebas.name) ) this.services_related.push(pruebas.name)
                        }
                    }

                    if( form != null ){
                    this.form = form
                    this.accepted = true
                    }
                    else {
                        this.form.doctor_name = doctor.name
                        this.form.doctor_professional_id = doctor.professional_id
                        this.form.doctor_phone = doctor.phone
                        this.form.doctor_email = doctor.user.email

                        this.form.patient_name = this.patient.name
                        this.form.patient_phone_1 = this.patient.phone
                        this.form.patient_phone_2 = this.patient.additional_phone
                        this.form.patient_email = this.patient.email
                    }

                }

            }
        },
        mounted(){
            this.getPatient()

            if( this.$route.query.target  == 'Pruebas psicométricas'){
                this.services_related.push('Pruebas psicométricas')
            }
            else {
                this.services_related.push('Hepatitis ABC')
                this.services_related.push('Perfil hepático I')
                this.services_related.push('Biometría hemática')
                this.services_related.push('Química sanguínea de 4 elementos')
            }
        },
    }
</script>