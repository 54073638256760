<template>
    <div class="notification-item" @click="action">
        <Tag @click.native="action" style="float: right;" v-if="!item.read" color="warning">No leída</Tag>
        <p class="date">{{ item.created_at | moment("DD/MMMM/YYYY HH:mm") }}</p>
        <p :style="{ 'margin-right': !item.read ? '70px' : '0' }" v-if="item.title" class="title">{{ item.title }}</p>
        <p class="content">{{ item.content }}</p>
    </div>
</template>

<script>
    /*global axios, EventBus */
    import { mapGetters } from "vuex"

    export default {
        props: {
            item: { required: true, type: [Object, Array] },
        },
        computed: {
            ...mapGetters([
                "user",
            ])
        },
        methods: {
            action(){
                // mark as read
                if(!this.item.read){
                    EventBus.$emit("update-notification", {
                        ...this.item,
                        read: true,
                    })
                    this.$store.dispatch("UPDATE_USER_FIELD", {
                        field: 'notification_count',
                        value: this.user.notification_count > 1 ? this.user.notification_count -= 1 : null,
                    })
                    axios.post(this.$root.API_URL+"/users/notifications/mark/"+this.item.id)
                    .catch(() => {
                        EventBus.$emit("update-notification", {
                            ...this.item,
                            read: false,
                        })
                        this.$store.dispatch("UPDATE_USER_FIELD", {
                            field: 'notification_count',
                            value: this.user.notification_count += 1 
                        })
                    })
                }
            }
        }
    }
</script>

<style lang="sass">
    .notification-item
        width: 100%
        padding: 25px
        border-radius: 10px
        background-color: white
        color: #00AFD7
        margin-bottom: 15px
        & .date
            font-size: 0.7em
            color: grey
            margin-bottom: 5px
        & .title
            font-weight: bold
            margin-bottom: 5px
        & .content
            font-size: 0.9em
</style>