<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h1 class="section-title">Seguimiento de mis Pacientes</h1>
            
            <template v-if="loading">
                <Loading/>
            </template>
            <template v-else>
                <!-- Success Extra Services Popup -->
                
                <div style="font-size: 0.85em">
                    <p><b>Nombre del Paciente:</b></p>
                    <p>{{ patient.name | name }}</p>
                    <template v-if="patient.id == '5231'">
                        <p style=""><strong>Este paciente es ficticio y fue creado para fines ilustrativos. </strong></p>
                    </template>
                    <br>
                    <p><b>Folio del Paciente: </b> {{ patient.id_crm }}</p>
                    <br>
                    <p><b>Indicacción terapéutica:</b></p>
                    <p>{{ patient.pathology.name }}</p>
                    <br>
                    <p><b>Sector: </b> {{ patient.insurance || "" | name }}</p>
                    <br>
                    
                    <ServicesList :medicine="Medicine" />
                    
                    <LastServices :medicine="Medicine" :latest_services="latest_services" :latest_services_extra="latest_services_extra" />
                    <template v-if="patient.id == '5231'">
                        <br>
                        <p style=""><strong>Este paciente es ficticio y fue creado para fines ilustrativos. </strong></p>
                    </template>

                    <!--<ServicesRequest :patient="patient" :product="Medicine" />-->
                    <template v-if="patient.id == '5231'">
                        <p style=""><strong>Este paciente es ficticio y fue creado para fines ilustrativos. </strong></p>
                    </template>
                    
                    <el-dialog class="doctor-dialog" title="" :visible.sync="show_success_dialog" width="90%">
                        <h2 style="text-align: center; word-break: break-word; color: white; font-weight: normal;">
                            Con el objetivo de brindarle estos servicios en tiempo y forma a su paciente, el call center especializado se pondrá en contacto con él de acuerdo a su seleccion de información.
                        </h2>
                    </el-dialog>

                    <br><br>
                    <p style="font-size: 0.9em; color: white; text-align: center; line-height: 1.8;">
                        Si está presentado algún problema con la plataforma al momento de solicitar un servicio, por favor escríbanos a <a style="color: white; font-weight: bold;" href="mailto:webapporti@pen.com.mx">webapporti@pen.com.mx</a> o envíe un mensaje de WhatsApp al siguiente número 5580126835 para apoyarlo en la pronta resolución de su solicitud.
                    </p>
                    <p style="font-size: 0.9em; color: white; text-align: center; line-height: 1.8; margin-top: 10px;">
                        Horario de atención de lunes a viernes de 10:00 a 18:00 horas. Excepto días festivos. 
                    </p>
                </div>
            </template>
        </TemplateContent>
    </div>
</template>

<script>
    /* global axios */
    // import generalMixin from './../../../../mixins/general'

    import { mapGetters } from 'vuex'
    import ServicesList from './components/ServicesList/Grid'
    import LastServices from './components/LastServices'

    export default {
        // mixins: [generalMixin],
        data(){
            return {
                loading: true,
                patient: {},
                product: "",
                selected_extra: [],
                latest_services: [],
                latest_services_extra: [],
                show_success_dialog: false,
            }
        },
        computed: {
            ...mapGetters([
                'keep_services_alive',
            ]),
            Medicine(){
                return this.product
            }
        },
        methods: {
            getPatient(){
                axios.post(this.$root.API_URL+ `/doctors/get-patient/${this.$route.params.id}`)
                .then(response => {
                    if(response.data.success){

                        let services = response.data.services
                        window._.each(services.base, b => b['checked'] = false)
                        window._.each(services.access, a => a['checked'] = false)
                        window._.each(services.diagnosis, d => d['checked'] = false)
                        window._.each(services.additionals, aa => aa['checked'] = false)

                        if( this.keep_services_alive == false ) this.$store.dispatch("SET_PATIENT_SERVICES", services)

                        this.product = response.data.product
                        this.patient = response.data.patient
                        // this.services = response.data.services
                        // this.$store.dispatch("SET_PATIENT_SERVICES_SELECTED", [])
                        this.latest_services = response.data.latest_services
                        this.latest_services_extra = response.data.latest_services_extra
                        this.loading = false
                    }
                })
            },
            requestExtraServices(){
                if(this.selected_extra.length > 0){
                    this.loading_extra = true
                    axios.post(this.$root.API_URL+"/doctors/request-extra-services", { patient: this.patient.id, services: this.selected_extra })
                    .then(response => {
                        if(response.data.success){
                            this.selected_extra = []
                            this.loading_extra = false
                            this.show_success_dialog = true
                        }
                    })
                    .catch(() => {
                        this.$Message.error({
                            content: "Parece que algo salió mal. Por favor, intentalo de nuevo más tarde...",
                            duration: 6,
                        })
                    })
                }
                else {
                    this.$Message.error({
                        content: "Debes seleccionar por lo menos un servicio de la lista...",
                        duration: 6,
                    })
                }
            },
            requestOcrevusServices(){
                if(this.ocrevus_services.length > 0){
                    this.loading_ocrevus = true
                    axios.post(this.$root.API_URL+"/doctors/request-ocrevus-service", { patient: this.patient.id, services: this.ocrevus_services })
                    .then(response => {
                        if(response.data.success){
                            this.patient.checklist_services = response.data.services
                            this.ocrevus_services = []
                            this.loading_ocrevus = false
                            this.show_success_dialog = true
                        }
                    })
                    .catch(() => {
                        this.$Message.error({
                            content: "Parece que algo salió mal. Por favor, intentalo de nuevo más tarde...",
                            duration: 6,
                        })
                    })
                }
                else {
                    this.$Message.error({
                        content: "Debes seleccionar por lo menos un servicio de la lista...",
                        duration: 6,
                    })
                }
            }
        },
        mounted(){
            let self = this
            this.getPatient()

            window.EventBus.$on('my-patients-details--reset-services', () => {
                
                // If exists update value, else create config row
                window.dexie.config.get({ name: 'show_success_dialog' }, e => {
                    if(e){
                        window.dexie.config.update(e.id, { value: true })
                    }
                    else {
                        window.dexie.config.add({ name: 'show_success_dialog', value: true })
                    }
                })

                // // If exists update value, else create config row
                // window.dexie.config.get({ name: 'sdmp_screen_scroll_y' }, e => {
                //     console.log(e)
                //     if(e){
                //         window.dexie.config.update(e.id, { value: window.scrollY || 0 })
                //     }
                //     else {
                //         window.dexie.config.add({ name: 'sdmp_screen_scroll_y', value: window.scrollY || 0 })
                //     }
                // })

                setTimeout(() => {
                    window.location.reload()
                }, 1000)


            })

            // On page realod, show success dialog if sets to true
            window.dexie.config.get({ name: 'show_success_dialog' }, e => {
                if(e && e.value == true){
                   this.show_success_dialog = true
                   window.dexie.config.update(e.id, { value: false })
                }
            })

            if( this.$route.query.scroll ){
                setTimeout(() => {
                    window.scroll(0, self.$route.query.scroll || 0)
                }, 500)
            }

            // // On page realod, set window scroll position
            // window.dexie.config.get({ name: 'sdmp_screen_scroll_y' }, e => {
            //     if(e){
            //         console.log(e)
            //         window.scroll(0, e.value)
            //         setTimeout(() => {
            //             window.dexie.config.update(e.id, { value: 0 })
            //         }, 1000)
            //     }
            // })
        },
        components: {
            ServicesList,
            LastServices,
        }
    }
</script>

<style lang="sass">
    .patient-details
        & .header
            width: 100%
            padding: 10px 15px
            background-color: rgba(0,0,0,0.2)
        & .ivu-col
            height: 100%
            & div.content
                padding: 10px 15px
                background-color: rgba(0,0,0,0.2)
                height: 100%
                display: flex
                align-items: center
                &.center
                    justify-content: center
                &.corner-up
                    border-radius: 10px 10px 0 0
                &.corner-down
                    border-radius: 0 0 10px 10px
    
    .patientes-extra-s
        padding: 15px 20px
        margin: 25px 0
        border-radius: 10px
        background-color: #103c8d
    
    .ivu-checkbox-group-item
        display: block !important
        margin: 5px 0
    
    .prg-services-list
        list-style: inside
        & li
            margin-top: 10px
            border-bottom: 1px solid white
            padding-bottom: 20px
            & ul
                margin-top: 10px
                margin-left: 20px
                & li
                    margin-top: 5px
                    border-bottom: none !important
                    padding-bottom: 0px !important
</style>