<template>
    <div class="content-template">
        <Row style="margin-top: 20px; flex-grow: 1;">
            <Column span="20" offset="2">
                <slot/>
            </Column>
        </Row>
        <TemplateFooter :color="footer_color"/>
    </div>
</template>

<script>
    export default {
        props: {
            footer_color: { required: false, type: String, default: '' }
        }
    }
</script>

<style lang="sass">
    .content-template
        position: relative
        min-height: calc(100vh - 85px)
        display: flex
        flex-direction: column
        padding-bottom: 25px
</style>