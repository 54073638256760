<template>
    <TemplateContent>
        <h1 class="section-title">Actualizar contraseña</h1>
        <template v-if="error">
            <Alert type="error" show-icon>
                Código incorrecto
                <span slot="desc">
                    El código de tu link no es correcto. Por favor, contacta a soporte técnico para resolver este problema...
                </span>
            </Alert>
        </template>
        <template v-else>
            <Form ref="resetForm" :model="reset" :rules="ruleInline">
                <FormItem prop="password">
                    <p class="form-label">Nueva contraseña</p>
                    <Input size="large" type="password" v-model="reset.password" placeholder=""/>
                </FormItem>
                <FormItem>
                    <Button :loading="loading" size="large" style="margin-top: 15px" long type="primary" @click="resetPassword">Actualizar</Button>
                </FormItem>
            </Form>
        </template>
    </TemplateContent>
</template>

<script>
    export default {
        data(){
            return {
                error: false,
                loading: false,
                reset: {
                    password: "",
                },
                ruleInline: {
                    password: [
                        {
                            required: true, 
                            message: 'Contraseña es requerida', 
                            trigger: 'blur' 
                        }
                    ],
                },
            }
        },
        methods: {
            validateToken(token){
                this.loading = true
                axios.post(this.$root.API_URL+"/auth/password-reset-validate", { token: token })
                .then(response => {
                    this.loading = false
                    if(response.data.success && response.data.exists){
                        this.error = false
                    }
                    else {
                        this.error = true
                    }
                })
                .catch(() => {
                    this.loading = false
                    this.error = true
                })
            },
            resetPassword(){
                let self = this
                this.$refs['resetForm'].validate(valid => {
                    if(valid){
                        this.loading = true
                        /*global axios */
                        axios.post(this.$root.API_URL+"/auth/reset-password", { token: this.$route.params.token, password: this.reset.password })
                        .then(response => {
                            if(response.data.success){
                                this.reset.password = ""
                                this.$Message.success("Tu contraseña ha sido actualizada exitosamente. En un momento serás redireccionado para Iniciar Sesión...")
                                setTimeout(() => {
                                    self.$router.push("/sign-in")
                                }, 3000)
                            }
                            else {
                                this.$Message.error(response.data.message)
                                this.loading = false
                            }
                        })
                        .catch(() => {
                            this.$Message.error({
                                content: "Algo salió mal",
                                duration: 6,
                            })
                            this.loading = false
                        })
                    }
                })
            }
        },
        mounted(){
            this.validateToken(this.$route.params.token)
        }
    }
</script>