<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h1 class="section-title">Nuestro portafolio de soluciones y programas</h1>

            <template v-if="loading">
                <Loading />
            </template>
            <template v-else>
                <div v-for="product in products" :key="`product_id_${product.id}`" @click="$router.push(`/doctor/services/programs/${product.slug}`)" class="pathology-menu-button">
                    <p v-html="$productNameFormat(product.name)"></p>
                </div>
            </template>
            
        </TemplateContent>
    </div>
</template>

<script>
    /* global axios */
    export default {
        data(){
            return {
                loading: true,
                products: [],
            }
        },
        methods: {
            fetchProducts(){
                axios.post(this.$root.API_URL+"/content/v2/get-products").then(response => {
                    if(response.data.success){
                        this.products = response.data.products
                        this.loading = false
                    }
                })
            }
        },
        mounted() {
            this.fetchProducts()
        }
    }
</script>