<template>
    <div style="background-color: white; color: #006fb9;">
        <ServiceContent footer_color="#006fb9">
            <div slot="header" class="image-header">
                <img style="width: 100%;" src="/images/doctor-services/Programa_de_bienestar_sesiones.png" alt="">
            </div>
            <p class="service-title">Plenitud Integral</p>

            <Loading v-if="loading" />
            <template v-else>
                <p class="txt-black" v-html="MainDescription"></p>

                <template v-if="FullnessMainDescription != ''">
                    <div class="fullness-container">
                        <p class="fullness-container--title">POR MI Plenitud</p>
                        <p v-html="FullnessMainDescription"></p>
                    </div>
                </template>
                
                <div id="wellness-container">
                    <Tabs type="card">

                        <TabPane label="Lunes">
                            <div class="wellness-session-container">
                                <!--<youtube :video-id="GYkq9Rgoj8E" player-width="100%" />-->
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/va65fTgU7Os"
                                         title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </TabPane>

                        <TabPane label="Martes">
                            <div class="wellness-session-container">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/ccfTJ-3oy8I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </TabPane>

                        <TabPane label="Miércoles">
                            <div class="wellness-session-container">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/3GyK0O8pIsM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </TabPane>

                        <TabPane label="Jueves">
                            <div class="wellness-session-container">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/LiNs3JRvi0k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </TabPane>

                        <TabPane label="Viernes">
                            <div class="wellness-session-container">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/4weycuv_kyE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                            </div>
                        </TabPane>

                        <TabPane label="Sábado">
                            <div class="wellness-session-container">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/pzDN0HbsT6Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                            </div>
                        </TabPane>
                    </Tabs>
                </div>

                <br>

                <!-- Categories -->
                <div class="wellness-category" v-for="(category, i) in Categories" :key="`category_${i}`">

                    <h3>{{ category.category_name }}</h3>
                    <div class="wellness-category--item" v-for="(item, j) in category.items" :key="`category_item_${i}_${j}`">
                        <h4>{{ item.session_title }}</h4>
                        <a :href="item.session_goal" target="_blank">{{ item.session_goal }}</a>
                        <p>Código de acceso: {{ item.session_video }}</p>
                    </div>

                </div>

            </template>

        </ServiceContent>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                tab: '',
                texts: [],
                tabs: [],
                loading: true,
                //share_popup_link: 'https://google.com.mx',
                show_share_popup: false,
            }
        },
        computed:{
            MainDescription(){
                let ti = window._.findIndex(this.texts, text => text.code == 'wellness.mainDescription')
                return ti != -1 ? this.texts[ti].content : ""
            },
            FullnessMainDescription(){
                let ti = window._.findIndex(this.texts, text => text.code == 'wellness.fullness.mainDescription')
                return ti != -1 ? this.texts[ti].content : ""
            },
            CalendarLink(){
                let ti = window._.findIndex(this.texts, text => text.code == 'wellness.calendar.link')
                if( ti != -1 ){
                    let element = window.$(this.texts[ti].content)
                    return element.children('a').attr('href')
                }
                return ''
            },
            Categories(){
                let items = []
                window._.each( this.tabs, item => {
                    let ii = window._.findIndex(items, i => i.category_name === item.tab_name)
                    if( ii !== -1 ){
                        items[ii].items.push(item)
                    }
                    else {
                        let ci = {
                            category_name: item.tab_name,
                            items: []
                        }
                        ci.items.push(item)
                        items.push(ci)
                    }
                })
                return items
            },
            Tabs(){
                return window._.orderBy(this.tabs, ['order'], ['asc'])
            }
        },
        methods: {
            fetchTexts(){
                window.axios.post(this.$root.API_URL+ `/content/dynamic-texts`, { code: 'wellness' })
                .then(response => {
                    if(response.data.success){
                        this.texts = response.data.texts
                        this.fetchTabs()
                    }
                })
            },
            fetchTabs(){
                window.axios.post(this.$root.API_URL+ `/content/wellness-tabs`)
                .then(response => {
                    if(response.data.success){
                        this.tabs = response.data.tabs
                        this.loading = false
                    }
                })
            },
            watchCalendar(){
                window.open(this.CalendarLink, '_blank')
            },
            shareCalendar(){
                this.show_share_popup = true
            },
            copyToClipboard(){
                navigator.clipboard.writeText(this.CalendarLink)
                this.$Message.success('Enlace copiado exitosamente');
            },
        },
        mounted(){
            this.fetchTexts()
        }
    }
</script>

<style lang="scss">
    .el-collapse-item{
        margin-bottom: 30px;
        & .el-collapse-item__header{
            border: 1px solid rgb(0, 111, 185);
            border-radius: 10px;
            padding: 10px 15px;
            color: rgb(0, 111, 185);
            font-size: 15px;
            height: auto !important;
            min-height: 48px;
            line-height: 25px;
            &.is-active{
                border-bottom-color: rgb(0, 111, 185) !important;
            }
            & .el-collapse-item__arrow{
                transform: rotate(90deg) !important;
                &.is-active{
                    transform: rotate(270deg) !important;
                }
            }
        }
        & .el-collapse-item__wrap{
            padding: 0 15px;
            margin-top: 15px;
            border-bottom: none !important;
            & p{
                font-size: 17px;
            }
            & .el-collapse-item__content{
                line-height: 1.5 !important;
            }
        }
    }

    .ivu-carousel-dots li.ivu-carousel-active > button.radius{
        background: white !important;
    }

    .ivu-carousel-dots li button{
        background: rgba(white, 0.6) !important;
    }

    .ivu-carousel-arrow-hover{
        opacity: 1 !important;
    }

   #wellness-container{
       & .ivu-tabs-bar{
           margin-bottom: 0px;
       }

       & .ivu-tabs-nav{
           width: 100%;
           display: flex;
       }
       & .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab{
           flex: 1 1 0px;
           margin-right: 0;
           text-align: center;
       }

       .wellness-session-container{
           padding: 20px 30px;
           background: #00867d;
           color: #fff;
           text-align: center;
           margin-left: 1px;
           & h3 {
               margin-bottom: 5px;
           }
       }

       .ivu-tabs-tab.ivu-tabs-tab-active.ivu-tabs-tab-focused{
           background: #00867d;
           color: #fff;
       }

       .ivu-tabs-tab{
           font-weight: bold;
           color: #fff;
           background: #09b4fd;
           border-radius: 0;
       }
       
       .ivu-tabs-tab + .ivu-tabs-tab{
           border-left: none;
       }
   } 

   .wellness-session-footer{
       display: flex;
       justify-content: center;
       margin-top: 20px;
   }

   .fullness-container{
       margin: 40px 0;
       padding: 25px;
       border: 2px solid rgb(0, 111, 185);
       border-radius: 25px;
       background-color: rgba(0, 111, 185, 0.07);
       text-align: center;
       color: black;
       &--title{
           font-weight: bold;
           font-size: 1.1em;
           text-align: center;
           margin-bottom: 10px;
           color: rgb(0, 111, 185);
       }
   }

   .wellness-category{
       padding: 15px 0;
       & h3{
           margin-bottom: 15px;
       }
       &--item {
           margin-bottom: 15px;
           & h4 {
               color: black;
           }
           & a {
               font-size: 0.9em;
           }
           & p {
               font-size: 0.9em;
               color: grey;
               margin-top: 10px;
           }
       }
   }

    .ivu-tabs-nav-scroll{
        overflow: auto !important;
    }
</style>