<template>
    <div>
        <div @click="toggleContent('diagnosisContent')" class="pathology-menu-button prd-service-btn" aria-expanded="false">
            <img class="scale-3" src="/images/icons/stages/Diagnostico.png" alt="">
            <p>Diagnóstico y perfilamiento</p>
            <Icon type="ios-arrow-down" size="20" color="white" />
        </div>
        <div ref="diagnosisContent" class="prg-service-content" aria-expanded="false">
            <div class="prg-service-content--wrapper">

                <p class="txt-justified">Para inscribir a su paciente al programa para recibir el estudio solicitado, existen 2 opciones:</p>
                <br>

                <!-- Opción 1: Pre-registro por parte del médico -->
                <div @click="toggleContent('diagRegOpt1', 'diagnosisContent')" class="pathology-menu-button prd-service-btn" style="background-color: #58c7d8">
                    <img class="scale-3" src="/images/icons/inscripcion-por-parte-medico.png" alt="">
                    <p>Pre-registro por parte del médico tratante</p>
                    <Icon type="ios-arrow-down" size="20" color="white" />
                </div>
                <div ref="diagRegOpt1" class="prg-service-content" aria-expanded="false">
                    <div class="prg-service-content--wrapper">

                        <p class="txt-justified">De click aquí para pre-registrar a su paciente, es importante comentarle que para finalizar
                            el proceso, el paciente recibirá una llamada por parte del centro de atención a pacientes para completar su inscripción, ya
                        que le serán requeridos los siguientes documentos:</p>
                        <br>

                        <ul class="drop-list">
                            <li>
                                <span>Identificación oficial.</span>
                            </li>
                            <li>
                                <span>Comprobante de domicilio por si el programa requiere enviarle cualquier material físico.</span>
                            </li>
                            <li>
                                <span>Deberá consentir nuestro Aviso de privacidad.</span>
                            </li>
                        </ul>

                        <br>

                        <Button to="/doctor/patient/sign-up" style="background: #0AB4FE" type="primary" size="large" long>Ir</Button>

                    </div>
                </div>

                <!-- Opción 2: Inscripción directa por parte del paciente -->
                <div @click="toggleContent('diagRegOpt2', 'diagnosisContent')" class="pathology-menu-button prd-service-btn" style="background-color: #19bdb4">
                    <img class="scale-3" src="/images/icons/inscripcion-por-parte-paciente.png" alt="">
                    <p>Inscripción directa por parte del paciente</p>
                    <Icon type="ios-arrow-down" size="20" color="white" />
                </div>
                <div ref="diagRegOpt2" class="prg-service-content" aria-expanded="false">
                    <div class="prg-service-content--wrapper">

                        <!--<img style="width: 100%; margin-bottom: 15px" src="/images/TarjetaContacto.png" alt="">-->
                        <Button @click="onShowContactPopup" style="background: #0AB4FE" type="primary" size="large" long>Compartir postal con
                            instrucciones</Button>

                        <p>&nbsp;</p>
                        <hr>
                        <p>&nbsp;</p>

                        <p>Es importante comentarle que el paciente deberá tener a la mano la siguiente documentación ya que es
                            necesaria para su proceso de inscripción:</p>
                        <br>

                        <ul class="drop-list">
                            <li>
                                <span>Identificación oficial.</span>
                            </li>
                            <li>
                                <span>Comprobante de domicilio por si el programa requiere enviarle cualquier material físico.</span>
                            </li>
                            <li>
                                <span>En caso de solicitar estudios de diagnóstico o seguimiento: presentar el formato solicitud o
                                    receta de su médico tratante con la instrucción detallada de su estudio.</span>
                            </li>
                            <li>
                                <span>Deberá consentir nuestro Aviso de privacidad.</span>
                            </li>
                        </ul>

                    </div>
                </div>

                <!-- Footer content -->
                <p>&nbsp;</p>
                <hr>
                <p>&nbsp;</p>

                <p class="txt-justified" style="margin-bottom: 15px" v-html="DownloadFileDescription"></p>
                <Button @click="onDownloadDiagnosisFiles"  style="background: #0AB4FE" :disabled="!HasDiagnosisFiles"
                        type="primary" size="large" long>Descargar
                    formato - pruebas</Button>
                <p>&nbsp;</p>
                <p>&nbsp;</p>

                <p class="txt-justified" style="margin-bottom: 15px" v-html="AttachFileDescription">Texto dinámico</p>
                <div class="programs-attach-file">
                    <template v-if="attachFileStep === 'init' ">
                        <Button style="background: #0AB4FE" @click="onAttachAdherenceFile" :disabled="!HasDiagnosisFiles"
                                type="primary" size="large"
                                long>Adjuntar formato - pruebas</Button>
                    </template>
                    <template v-if="attachFileStep === 'attached' ">
                        <div class="attached-file">
                            <p>{{ attachedFile.name }}</p>
                            <Button @click="onClearAttachedFile" type="error" icon="md-trash" />
                        </div>
                    </template>
                    <input ref="attachAdherenceFile" @change="onAttachedAdherenceFile" type="file" />
                </div>

                <br>
                <p class="txt-justified">Para solicitar estos estudios para su paciente, le pedimos llenar los datos del
                    paciente.</p>
                <p>&nbsp;</p>
                <p class="txt-justified">En todos los casos ingrese datos de paciente:</p>
                <p class="txt-justified">* Campos obligatorios</p>
                <br>
                <Form ref="signupForm" :model="serviceRequest" :rules="ruleInline">
                    <FormItem prop="name">
                        <p class="form-label">* Nombre completo:</p>
                        <Input size="large" type="text" v-model="serviceRequest.name" placeholder=""/>
                    </FormItem>
                    <FormItem prop="phone">
                        <p class="form-label">* Teléfono de contacto 1:</p>
                        <Input size="large" type="number" v-model="serviceRequest.phone" placeholder=""/>
                    </FormItem>
                    <FormItem prop="phone_">
                        <p class="form-label">Teléfono de contacto 2:</p>
                        <Input size="large" type="number" v-model="serviceRequest.phone_2" placeholder=""/>
                    </FormItem>
                    <FormItem prop="email">
                        <p class="form-label">* Correo electrónico:</p>
                        <Input size="large" type="text" v-model="serviceRequest.email" placeholder=""/>
                    </FormItem>
                </Form>

                <p style="font-size: 0.8em; color: #ff8f82; padding: 10px; text-align: center">{{ error }}</p>
                <p style="font-size: 0.8em; color: #ff8f82; padding: 10px; text-align: center" class="error-message" v-for="(error, i) in errors" :key="`error_${i}`">{{ error }}</p>
                <Button @click="onDiagnosisSubmit" :loading="loading" style="background: #0AB4FE" type="primary" size="large" long>Enviar
                </Button>
                <p>&nbsp;</p>
                <br>
                <hr>
                <br>

                <Button @click="onShowContactPopup" style="background: #0AB4FE" type="primary" size="large" long>Puntos de contacto para pacientes</Button>
                <p>&nbsp;</p>
            </div>
        </div>

    </div>
</template>


<script>
    /* global _, $, axios */
    export default {
        props: {
            product: Object,
            files: Array,
            HasDiagnosisFiles: Boolean,
            DownloadFileDescription: String,
            AttachFileDescription: String,
        },
        data(){
            return {
                error: '',
                attachFileStep: 'init',
                attachedFile: null,
                serviceRequest: {
                    name: '',
                    phone: '',
                    phone_2: '',
                    email: '',
                },
                errors: [],
                loading: false,
                ruleInline: {
                    name: [{ required: true, message: 'Nombre es requerido', trigger: 'change' }],
                    email: [{ required: false, message: 'Correo electrónico es requerido', trigger: 'change' }, { type: 'email', message: 'Correo electrónico no válido', trigger: 'change' }],
                    phone: [{ required: true, message: 'Teléfono es requerido', trigger: 'change' }],
                    phone_2: [{ required: false, message: 'Teléfono es requerido', trigger: 'change' }],
                },
            }
        },
        methods: {
            toggleContent(ref, affect_parent = null){
                let target = $(this.$refs[ref])
                let target_wrapper = target.children('.prg-service-content--wrapper')
                let is_expanded = window.$( this.$refs[ref] ).attr('aria-expanded') || "false"
                let target_h = target_wrapper[0].clientHeight || 0
                if(is_expanded == "true"){
                    target[0].style.height = "0px"
                    $(target[0]).attr('aria-expanded', 'false')
                    $(target[0]).toggleClass('active')
                    if( affect_parent ) $(this.$refs[affect_parent]).first().height(`-=${target_h}`)
                }
                else {
                    target[0].style.height = `${target_h}px`
                    $(target[0]).attr('aria-expanded', 'true')
                    $(target[0]).toggleClass('active')
                    if( affect_parent ) $(this.$refs[affect_parent]).first().height(`+=${target_h}`)
                }
            },
            onDiagnosisSubmit(){
                this.errors = []
                let is_format_attached = false
                if( this.$refs.attachAdherenceFile.files[0] ) is_format_attached = true
                else this.error = 'Debes adjuntar el formato de pruebas'

                this.$refs['signupForm'].validate().then(valid => {
                    if( valid && is_format_attached ){
                        this.loading = true
                        let formData = new FormData()
                        formData.append('file', this.$refs.attachAdherenceFile.files[0])
                        formData.append('patient_name', this.serviceRequest.name)
                        formData.append('patient_phone', this.serviceRequest.phone)
                        formData.append('patient_phone_2', this.serviceRequest.phone_2)
                        formData.append('patient_email', this.serviceRequest.email)

                        axios.post(this.$root.API_URL+`/doctors/v2/request-diagnosis`,
                            formData,
                            {
                                headers: {
                                    'content-type': 'multipart/form-data'
                                }
                            })
                            .then(response => {
                                if( response.data.success ){
                                    this.reset()
                                    window.EventBus.$emit('programs-details--show-service-request-popup')
                                }
                                else {
                                    this.loading = false

                                    if( response.data.message ){
                                        this.$Message.error({
                                            content: response.data.message,
                                            duration: 6,
                                        })
                                    }

                                    if( response.data.errors ){
                                        // Catch errors
                                        _.each(response.data.errors, (error) =>{
                                            _.each(error, e => {
                                                this.errors.push(e)
                                            })
                                        })
                                    }
                                }
                            })
                            .catch( () => {
                                this.$Message.error({
                                    content: "Algo salió mal",
                                    duration: 6,
                                })
                                this.loading = false
                            })
                    }
                })
            },
            reset(){
                this.onClearAttachedFile()
                this.$refs['signupForm'].resetFields()
                this.errors = []
                this.loading = false
                this.serviceRequest = {
                    name: '',
                    phone: '',
                    phone_2: '',
                    email: '',
                }
            },
            onShowContactPopup(){
                window.EventBus.$emit('programs-details--show-contact-popup')
            },
            onDownloadDiagnosisFiles(){
                window._.each(this.files.diagnosis, (dfile) => {
                    this.download(dfile)
                })
            },
            download(file){
                window.axios.post(this.$root.API_URL+ `/content/download-product-file/${file.id}`, {}, {
                    responseType: 'blob',
                })
                    .then( response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        let download_name = file.file_name + '.' + file.attachment.split('/').pop().split('.')[1]
                        link.setAttribute('download', download_name); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        link.remove()
                    })
                    .catch(() => {
                        this.$Message.error('El archivo no pudo ser descargado');
                    })
            },
            onAttachAdherenceFile(){
                window.$(this.$refs.attachAdherenceFile).click()
            },
            onAttachedAdherenceFile(){
                this.error = ''
                let attachedFile = this.$refs.attachAdherenceFile.files[0]
                if( attachedFile ){
                    this.attachedFile = {
                        name: attachedFile.name,
                    }
                    this.attachFileStep = 'attached'
                }
            },
            onClearAttachedFile(){
                window.$(this.$refs.attachAdherenceFile).val('')
                this.attachFileStep = 'init'
                this.attachedFile = null
            }
        }
    }
</script>