<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h2>Formato de ecocardiogramas</h2>
            <template v-if="loading">
                <Loading/>
            </template>
            <template v-else>
                <i-form class="doctor-service-form" ref="form" :model="form" :rules="ruleInline" inline >
                    <br>
                    <h3>Datos del Médico</h3>
                    <br>
                    <!-- Doctor name -->
                    <FormItem label="Nombre completo:" prop="doctor_name">
                        <i-input type="text" v-model="form.doctor_name" disabled placeholder="" style="width: 100%"></i-input>
                    </FormItem>

                    <!-- Doctor Professional ID -->
                    <FormItem label="Cédula profesional:"  prop="doctor_professional_id">
                        <i-input type="text" v-model="form.doctor_professional_id" placeholder="" style="width: 100%"></i-input>
                    </FormItem>

                    <!-- Doctor phone -->
                    <FormItem label="Teléfono:"  prop="doctor_phone">
                        <i-input ref="doctor_phone" type="number" v-model="form.doctor_phone" placeholder="" style="width: 100%"></i-input>
                    </FormItem>

                    <!-- Doctor email -->
                    <FormItem label="E-mail:"  prop="doctor_email">
                        <i-input type="text" v-model="form.doctor_email" disabled placeholder="" style="width: 100%"></i-input>
                    </FormItem>

                     <!-- Doctor hospital -->
                    <FormItem style="margin-top: -15px" label="Hospital en el que labora:" class="block"  prop="doctor_hospital">
                        <i-input type="text" v-model="form.doctor_hospital" placeholder="" style="width: 100%"></i-input>
                    </FormItem>
                    <hr>
                    <br>

                    
                    <h3>Datos del Paciente</h3>
                    <p style="margin-top: 10px; font-size: 0.9em">Estimado médico le agradecemos proporcionar la información de su paciente para coordinar la realización de este estudio. </p>
                    <br>

                    <!-- Patient initials -->
                    <Row :gutter="16">
                        <i-col :xs="5" :sm="5" :md="3" :lg="2">
                            <FormItem label="Iniciales" ></FormItem>
                        </i-col>
                        <i-col :xs="6" :sm="6" :md="4" :lg="4">
                            <FormItem label="" prop="patient_initials_first_last_name" class="with-footer-legend">
                                <i-input type="text" v-model="form.patient_initials_first_last_name" placeholder="" style="width: 100%"></i-input>
                                <p class="form-footer-legend center">Apellido paterno</p>
                            </FormItem>
                        </i-col>
                        <i-col :xs="6" :sm="6" :md="4" :lg="4">
                            <FormItem label="" prop="patient_initials_second_last_name" class="with-footer-legend">
                                <i-input type="text" v-model="form.patient_initials_second_last_name" placeholder="" style="width: 100%"></i-input>
                                <p class="form-footer-legend center">Apellido materno</p>
                            </FormItem>
                        </i-col>
                        <i-col :xs="6" :sm="6" :md="4" :lg="4">
                            <FormItem label="" prop="patient_initials_name" class="with-footer-legend">
                                <i-input type="text" v-model="form.patient_initials_name" placeholder="" style="width: 100%"></i-input>
                                <p class="form-footer-legend center">Nombre(s)</p>
                            </FormItem>
                        </i-col>
                    </Row>

                    <!-- Patient name -->
                    <FormItem label="Nombre completo:"  prop="patient_name">
                        <i-input type="text" v-model="form.patient_name" disabled placeholder="" style="width: 100%"></i-input>
                    </FormItem>

                    <!-- Patient email -->
                    <FormItem label="E-mail de contacto:"  prop="patient_email">
                        <i-input type="text" v-model="form.patient_email" disabled placeholder="" style="width: 100%"></i-input>
                    </FormItem>
                    <div style="height: 5px;"></div>

                    <!-- Patient birthday -->
                    <Row :gutter="16">
                        <i-col :xs="11" :sm="11" :md="6" :lg="4">
                            <FormItem label="Fecha de nacimiento"></FormItem>
                        </i-col>
                        <i-col span="4">
                            <FormItem label="" prop="patient_birth_day" class="with-footer-legend">
                                <InputNumber :max="31" v-model="form.patient_birth_day" :formatter="formatWithZero" placeholder="DD" style="width: 100%"></InputNumber>
                                <p class="form-footer-legend center">Día</p>
                            </FormItem>
                        </i-col>
                        <i-col span="4">
                            <FormItem label="" prop="patient_birth_month" class="with-footer-legend">
                                <InputNumber :max="12" v-model="form.patient_birth_month" :formatter="formatWithZero" placeholder="MM" style="width: 100%"></InputNumber>
                                <p class="form-footer-legend center">Mes</p>
                            </FormItem>
                        </i-col>
                        <i-col span="5">
                            <FormItem label="" prop="patient_birth_year" class="with-footer-legend">
                                <i-input type="text" v-model="form.patient_birth_year" placeholder="AAAA" style="width: 100%"></i-input>
                                <p class="form-footer-legend center">Año</p>
                            </FormItem>
                        </i-col>
                    </Row>
                    <div style="height: 5px;"></div>

                    <!-- Patient gender -->
                    <Row :gutter="16">
                        <i-col :xs="5" :sm="5" :md="3" :lg="2">
                            <FormItem label="Género" ></FormItem>
                        </i-col>
                        <i-col span="12">
                            <FormRadioGroup @callback="validateGenderRadio">
                                <FormRadio v-model="form.patient_gender" mvalue="F" label="F" />
                                <FormRadio v-model="form.patient_gender" mvalue="M" label="M" />
                            </FormRadioGroup>
                            <div style="margin-top: -5px" class="radio-group-error-text" v-if="radio_validation.gender == false">Requerido</div>
                        </i-col>
                    </Row>
                    <div style="height: 5px;"></div>

                    <!-- Patient phone 1 -->
                    <FormItem label="Teléfono de contacto 1:"  prop="patient_phone_1">
                        <i-input type="text" v-model="form.patient_phone_1" placeholder="" style="width: 100%"></i-input>
                    </FormItem>

                    <!-- Patient phone 2 -->
                    <FormItem label="Teléfono de contacto 2:"  prop="patient_phone_2">
                        <i-input type="text" v-model="form.patient_phone_2" placeholder="" style="width: 100%"></i-input>
                    </FormItem>
                    <br>

                    <hr>
                    <br>
                    <p style="font-size: 0.9em; margin-bottom: -15px;"><strong>Pruebas solicitadas:</strong></p>
                    <div class="patientes-extra-s">
                        <div style="height: 10px;"></div>

                        <div style="height: 10px"></div>
                        <CheckboxGroup class="form-services-form--checkbox-group" v-model="services_related">
                            <Checkbox disabled label="Ecocardiograma">
                                <span class="label">Ecocardiograma</span>
                            </Checkbox>
                        </CheckboxGroup>
                        <div style="margin-top: -5px" class="radio-group-error-text" v-if="radio_validation.requested_tests == false">Requerido</div>

                        <!-- Request date -->
                        <Row :gutter="16">
                            <i-col :xs="11" :sm="11" :md="6" :lg="4">
                                <FormItem label="Fecha de solicitud"></FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem label="" prop="request_date_day" class="with-footer-legend">
                                    <InputNumber :max="31" v-model="form.request_date_day" :formatter="formatWithZero" placeholder="DD" style="width: 100%"></InputNumber>
                                    <p class="form-footer-legend center">Día</p>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem label="" prop="request_date_month" class="with-footer-legend">
                                    <InputNumber :max="12" v-model="form.request_date_month" :formatter="formatWithZero" placeholder="MM" style="width: 100%"></InputNumber>
                                    <p class="form-footer-legend center">Mes</p>
                                </FormItem>
                            </i-col>
                            <i-col span="5">
                                <FormItem label="" prop="request_date_year" class="with-footer-legend">
                                    <i-input type="text" v-model="form.request_date_year" placeholder="AAAA" style="width: 100%"></i-input>
                                    <p class="form-footer-legend center">Año</p>
                                </FormItem>
                            </i-col>
                        </Row>
                    </div>
                    <br>
                    <p class="font-size: 0.9em"><strong>Expreso tanto mi consentimiento como el del paciente que recibirá el servicio para realizar los estudios necesarios y contar con el resultado de los mismos.</strong></p>
                    <br>
                    <div style="width: 100%">
                        <FormRadio v-model="accepted" :mvalue="true" label="Acepto y comprendo los términos del servicio solicitado" label_position="right" :label_style="{ 'text-align': 'left', 'line-height': '21px', 'margin-top': '-5px', 'margin-left' : '15px', 'font-size': '0.9em' }"/>
                    </div>
                    <br><br>
                    <Button :disabled="!accepted" long size="large" type="primary" :loading="is_loading" @click="handleSubmit">Finalizar y enviar solicitud</Button>
                    <div style="font-size: 0.8em; padding: 2em; text-align: center;">
                        <p style="margin-bottom: 5px">Al solicitar este servicio en breve se comunicarán con usted por parte de:</p>
                        <p>PROGRAMA DIAGNOSTICO</p>
                        <p>T: (55) 52766400 Ext. 1139 / 800 200 0743</p>
                        <p>WhatsApp:56 1345 5932</p>
                        <p>programadiagnostico@signufarma.com.mx</p>
                    </div>
                    <Button style="background-color: #103c8d" long size="large" type="primary" @click="handleCancel">Cancelar solicitud</Button>
                    <br><br>
                    <div style="font-size: 0.6em; text-align: center;">
                        <p style="margin-bottom: 5px">El programa de diagnóstico POR TI le ofrece servicios diseñados para facilitar el diagnóstico oportuno en acompañamiento con su Médico Tratante, quien decidirá las pruebas de gabinete/estudios clínicos más adecuados para usted. Dichos servicios son sin costo para el paciente, médico y/o cuidador. Este material es de uso exclusivo del programa de Roche POR TI, queda prohibida su reproducción parcial o total. El médico tratante será, en todo momento, quien determine el mejor tratamiento para el paciente. Le invitamos a permanecer en contacto con su médico.</p>
                        <p>M-MX-00002632</p>
                    </div>
                </i-form>
            </template>
        </TemplateContent>
    </div>
</template>

<script>
    import form from './form'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [form],
        data(){
            return {
                loading: true,
                patient: {},
                accepted: false,
                form: {
                    doctor_name: '',
                    doctor_professional_id: '',
                    doctor_phone: '',
                    doctor_email: '',
                    doctor_hospital: '',

                    patient_initials_name: '',
                    patient_initials_first_last_name: '',
                    patient_initials_second_last_name: '',
                    patient_name: '',
                    patient_phone_1: '',
                    patient_phone_2: '',
                    patient_email: '',
                    patient_gender: '',
                    patient_birth_day: null,
                    patient_birth_month: null,
                    patient_birth_year: null,

                    request_date_day: null,
                    request_date_month: null,
                    request_date_year: null,
                },
                ruleInline: {
                    doctor_name: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                    doctor_professional_id: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                    doctor_phone: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    doctor_email: [
                        { required: true, type: 'email', message: 'Requerido', trigger: 'change' },
                    ],
                    doctor_hospital: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_initials_first_last_name: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[A-Za-z]/ },
                    ],
                    patient_initials_second_last_name: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[A-Za-z]/ },
                        { max: 3, message: 'Máximo tres caracteres' }
                    ],
                    patient_initials_name: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[A-Za-z]/ },
                        { max: 3, message: 'Máximo tres caracteres' }
                    ],
                    patient_name: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_phone_1: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_phone_2: [
                        { required: false, trigger: 'change' },
                    ],
                    patient_email: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_birth_day: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    patient_birth_month: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    patient_birth_year: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[0-9]*$/ },
                        { min: 4, message: '4 dígitos', trigger: 'change' },
                        { max: 4, message: '4 dígitos', trigger: 'change' },
                    ],
                    request_date_day: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    request_date_month: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    request_date_year: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[0-9]*$/ },
                        { min: 4, message: '4 dígitos', trigger: 'change' },
                        { max: 4, message: '4 dígitos', trigger: 'change' },
                    ],
                },
                is_loading: false,
                radio_validation: {
                    gender: null,
                    requested_tests: null,
                },
                source_service: '',
                services_related: [
                    'Ecocardiograma',
                ],
            }
        },
        computed: {
            ...mapGetters([
                'patient_services',
            ])
        },
        watch: {
            ServicesRelated(){
                this.validateRequestedTests()
                this.$$forceUpdate()
            }
        },
        methods: {
            getPatient(){
                let self = this

                window.axios.post(this.$root.API_URL+ `/doctors/get-patient/${this.$route.params.id}`)
                .then(response => {
                    if(response.data.success){
                        this.patient = response.data.patient

                        if( this.$route.query.fill ) {
                            this.fillForm()
                            this.form.doctor_phone = response.data.doctor.phone
                        }
                        else {
                            let doctor = response.data.doctor
                            this.form.doctor_name = doctor.name
                            this.form.doctor_professional_id = doctor.professional_id
                            this.form.doctor_phone = doctor.phone
                            this.form.doctor_email = doctor.user.email
    
                            this.form.patient_name = this.patient.name
                            this.form.patient_phone_1 = this.patient.phone
                            this.form.patient_phone_2 = this.patient.additional_phone
                            this.form.patient_email = this.patient.email
    
    
                        }

                        this.loading = false
                        this.is_loading = false

                        setTimeout(() => {

                            // Unlock doctor's phone
                            if( self.form.doctor_phone == null ) window.$(self.$refs['doctor_phone'].$el).find('input').removeAttr('disabled')

                        }, 1000)
                    }
                })
            },
            validateGenderRadio(){
                this.radio_validation.gender = this.form.patient_gender != ''
            },
            validateRequestedTests(){
                this.radio_validation.requested_tests = this.services_related.length >= 1
            },
            handleSubmit(){

                this.validateGenderRadio()

                this.validateRequestedTests()

                let radio_validate = Object.values(this.radio_validation).indexOf(false) > -1

                this.$refs['form'].validate((valid) => {
                    if (valid && !radio_validate) {
                        
                        this.is_loading = true
                        let _uuid = window.uuid()

                        let sis = window._.findIndex(this.patient_services.diagnosis, (service) => {
                            return service.name == "Ecocardiogramas"
                        })

                        if( sis != -1 ){
                            let current_service = this.patient_services.diagnosis[sis]
                            let new_item = {
                                ...current_service,
                                uuid: _uuid,
                                checked: true,
                                form_data: this.form,
                            }

                            this.$store.dispatch('SET_PATIENT_SERVICE', {
                                category: 'diagnosis',
                                service: new_item
                            })

                            let scroll = this.$route.query.scroll || "0"
                            this.$router.push(`/doctor/my-patient/${this.patient.id}?scroll=${scroll}`)
                        }
                        else {
                            this.is_loading = false
                            this.$Message.error('No se pudo completar la acción');
                        }

                    } else {
                        this.$Message.error('Completa la información faltante');
                    }
                })
            },
            handleCancel(){
                let category = this.$route.query.category

                let sis = window._.findIndex(this.patient_services[category], (service) => {
                    return service.name == "Ecocardiogramas"
                })

                if( sis != -1 ){
                    let current_service = this.patient_services[category][sis]
                    let new_item = {
                        ...current_service,
                        checked: false,
                        form_data: null,
                    }

                    this.$store.dispatch('SET_PATIENT_SERVICE', {
                        category: category,
                        service: new_item
                    })

                    this.$router.push(`/doctor/my-patient/${this.patient.id}`)
                }
            },
            fillForm(){
                let category = this.$route.query.category

                let sis = window._.findIndex(this.patient_services[category], (service) => {
                    return service.name == "Ecocardiogramas"
                })

                if( sis != -1 ){
                    let current_service = this.patient_services[category][sis]

                    if( current_service && current_service.form_data != null ){
                        this.form = current_service.form_data
                        // this.form.doctor_phone = 
                        this.accepted = true
                    }
                }
            }
        },
        mounted(){
            this.getPatient()
        },
    }
</script>