<template>
    <Grid/>
</template>

<script>
    import Grid from "./../../components/Doctor/Services/Patients/Base/components/CentroDeAtencionAPacientes"

    export default {
        components: {
            Grid,
        }    
    }
</script>