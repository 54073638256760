<template>
    <div class="landing-grid">
        <Row :gutter="16" style="width: 100%; flex-grow: 1; display: flex !important; flex-direction: column; justify-content: center;">
            <Column style="flex: unset !important;" span="16" offset="4">
                <iButton style="background-color: rgba(17,110,175, 0.9); border: none;" long type="primary" @click="$router.push('/sign-in')">INICIAR SESIÓN</iButton>
            </Column>
            <Column style="flex: unset !important;" span="16" offset="4">
                <iButton style="background-color: rgba(17,110,175, 0.9); margin-top: 10px; border: none;" long type="primary" @click="showButtonsM">
                    PRE-REGISTRO
                    <Icon v-if="!showButtons" type="md-arrow-dropdown" />
                    <Icon v-else type="md-arrow-dropright" />
                </iButton>
            </Column>
            <template v-if="showButtons">
                <Column style="flex: unset !important;" span="16" offset="4">
                    <iButton style="background-color: rgba(35,170,198, 0.9); border: none; margin-top: 0px;" long type="primary" @click="showDoctorQuestionPopup">
                        MÉDICO
                    </iButton>
                </Column>
                <!-- <Column style="flex: unset !important;" span="16" offset="4">
                    <iButton style="background-color: rgba(21,135,158, 0.9); border: none; margin-top: 0px;" long disabled type="primary" @click="$router.push('/sign-up')">
                        PACIENTE
                    </iButton>
                </Column> -->
            </template>
        </Row>
        <Row :gutter="16" class="landing-footer" style="width: 100%;display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <p>No. aviso COFEPRIS para Profesionales de la Salud: 203300202C4009</p>
            <p>Número de Veeva: M-MX-00001237</p>
        </Row>
        <!-- Pre-Registration Success Popup -->
        <el-dialog class="doctor-dialog" title="" :visible.sync="show_signup_patient_dialog" width="90%">
            <h2 style="text-align: center; word-break: break-word; color: white; font-weight: normal;">
                Gracias por completar su Pre-Registro en el programa de apoyo a pacientes <b>POR TI</b>, sus datos serán validados con su médico tratante y en breve un especialista del programa estará en contacto con usted.
            </h2>
        </el-dialog>
        <!-- Pre-Registration Doctor Success Popup -->
        <el-dialog class="doctor-dialog" title="" :visible.sync="show_signup_doctor_dialog" width="90%">
            <h2 style="text-align: center; word-break: break-word; color: white; font-weight: normal;">
                Estimado médico, gracias por registrarse en el programa de apoyo a pacientes <strong>POR TI</strong>.
            </h2>
            <p style="text-align: center; word-break: break-word; color: white;">Le damos la más cordial bienvenida, su registro ha sido completado</p>
        </el-dialog>
        <DoctorQuestionPopup/>
        <DoctorHowDidYouHearPopup/>
    </div>
</template>

<script>
    /*global EventBus */
    import DoctorQuestionPopup from "./../Doctor/SignUp/Popups/Question"
    import DoctorHowDidYouHearPopup from "./../Doctor/SignUp/Popups/HowDidYouHearAbout"

    export default {
        data(){
            return {
                showButtons: false,
                show_signup_doctor_dialog: false,
                show_signup_patient_dialog: false,
            }
        },
        methods: {
            showButtonsM(){
                this.showButtons = !this.showButtons
            },
            showDoctorQuestionPopup(){
                EventBus.$emit("show-doctors-sign-up-question-popup", true)
            },
        },
        mounted(){
            EventBus.$on("show-signup-patient-popup", (value) => {
                this.show_signup_patient_dialog = value
            })

            EventBus.$on("show-signup-doctor-popup", (value) => {
                this.show_signup_doctor_dialog = value
            })
        },
        components: {
            DoctorQuestionPopup,
            DoctorHowDidYouHearPopup,
        }
    }
</script>

<style lang="sass" scoped>
    .landing-grid
        position: relative
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        height: calc(100vh - 65px)
        background-color: #ececec
        background-image: url('/images/landing-bg-v2.png')
        background-repeat: no-repeat
        background-position: -250px
        background-size: cover
        height: calc(100vh - 65px)
        & .logo
            width: 250px
            margin-bottom: 15px
    
    .ivu-btn-long
        height: 42px
        font-size: 16px
    
    .landing-footer
        padding: 30px 0
        text-align: center
        font-size: 10px
        & p
            color: black
            text-align: center
    
    @media (min-width: 1072px)
        .landing-grid
            background-position: 0
        
        
</style>