<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h1 class="section-title">Recursos adicionales para el médico</h1>

            <div style="padding: 0; min-height: auto; background: transparent;" class="center-grid doctor">
                <div @click="$router.push('/resources/info')" class="item one">
                    <img src="/images/icons/comunidad.png" alt="">
                    <p>Información para nuestra comunidad médica</p>
                </div>
                <div @click="$router.push('/resources/videos')" class="item two">
                    <img src="/images/icons/video.png" alt="">
                    <p>Videos informativos</p>
                </div>
                <div @click="$router.push('/resources/with-you')" class="item three">
                    <img src="/images/icons/withyou.png" alt="">
                    <p>POR TI acompaña a sus pacientes</p>
                </div>
                <div @click="goToExternal" class="item four">
                    <img src="/images/icons/una-opcion-para-ti.png" alt="">
                    <p>Una opción para ti - Plataforma para conocer los estudios clínicos</p>
                </div>
                <div @click="$router.push('/resources/associations')" class="item five">
                    <img src="/images/icons/asociaciones.png" alt="">
                    <p>Asociaciones para pacientes</p>
                </div>
            </div>

            <!-- <p>¿Le gustaría saber más acerca de una condición médica o los estudios clínicos de <b>Roche</b>?</p>
            <br>
            <p>Busque la información aquí:</p>
            <br>
            <iButton to="https://unaopcionparati.roche.com.mx/" target="_blank" type="primary" long>UnaOpciónParaTí</iButton> -->
        </TemplateContent>
    </div>
</template>

<script>
   export default {
        methods: {
            goToExternal(){
                return window.open('https://unaopcionparati.roche.com.mx/', '_blank')
            }
        }
    }
</script>