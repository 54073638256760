<template>
    <div>
        <template v-if="!HideBase || !HideAdditionals">
            <p><strong>Servicios que ha recibido el paciente:</strong></p>
            <!-- <br> -->
            <!-- <template v-if="latest_services.length > 0">
                <Row class="patient-details" :gutter="10" type="flex">
                    <div style="width: 100%; display: flex;">
                        <i-col span="14">
                            <div class="content corner-up">
                                <p><b>Servicios base:</b></p>
                            </div>
                        </i-col>
                        <i-col span="10">
                            <div class="content corner-up">
                                <p><b>Fecha:</b></p>
                            </div>
                        </i-col>
                    </div>
                    <div v-for="(service, i) in latest_services" :key="'serv_'+service.id" style="width: 100%; display: flex;">
                        <i-col span="14">
                            <div class="content" :class="{'corner-down': latest_services.length == i+1}">
                                <p>{{ service.type }}</p>
                            </div>
                        </i-col>
                        <i-col span="10">
                            <div class="content center" :class="{'corner-down': latest_services.length == i+1}">
                                <p v-if="service.type ==  'Gestión de Aseguradora'">Autorizada</p>
                                <p v-else>{{ service.date | moment("DD/MM/YYYY") }}</p>
                            </div>
                        </i-col>
                    </div>
                </Row>
            </template>
            <template v-else>
                <Alert type="warning">El paciente no ha recibido ningún servicio base.</Alert>
            </template> -->
            <br>
            <template v-if="latest_services_extra.length > 0 && !HideAdditionals">
                <Row class="patient-details" :gutter="10" type="flex">
                    <div style="width: 100%; display: flex;">
                        <i-col span="14">
                            <div class="content corner-up">
                                <p><b>Servicios adherencia:</b></p>
                            </div>
                        </i-col>
                        <i-col span="10">
                            <div class="content corner-up">
                                <p><b>Fecha:</b></p>
                            </div>
                        </i-col>
                    </div>
                    <div v-for="(service, i) in latest_services_extra" :key="'serv_e_'+service.id" style="width: 100%; display: flex;">
                        <i-col span="14">
                            <div class="content" :class="{'corner-down': latest_services_extra.length == i+1}">
                                <p>{{ service.type }}</p>
                            </div>
                        </i-col>
                        <i-col span="10">
                            <div class="content center" :class="{'corner-down': latest_services_extra.length == i+1}">
                                <p v-if="service.type == 'Servicio de secuenciación genómico'">Autorizada</p>
                                <p v-else>{{ service.date | moment("DD/MM/YYYY") }}</p>
                            </div>
                        </i-col>
                    </div>
                </Row>
            </template>
            <template v-else>
                <template v-if="!HideAdditionals">
                    <Alert type="warning">El paciente no ha recibido ningún servicio de adherencia.</Alert>
                </template>
            </template>
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            medicine: { required: true, type: String },
            latest_services: { required: true, type: [Object, Array] },
            latest_services_extra: { required: true, type: [Object, Array] },
        },
        computed: {
            HideBase(){
                let e = ["Erivedge", "Herceptin", "Mabthera", "Tarceva", "Xovluza", "Zelboraf"]
                return e.includes(this.medicine)
            },
            HideAdditionals(){
                let e = ["Avastin", "Erivedge", "Herceptin", "Mabthera", "Perjeta", "Pulmozyme", "Tarceva", "Xovluza", "Zelboraf"]
                return e.includes(this.medicine)
            }
        }
    }
</script>