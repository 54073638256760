<template>
    <TemplateContent>
        <h1 class="section-title">Pre-Registro de Pacientes</h1>

        <template v-if="window_loading">
            <Loading />
        </template>
        <template v-else>
            <p>En todos los casos ingresa identificación del médico que registra al paciente:</p>
            <p><small>* Campos obligatorios</small></p>
            <br>

            <Form ref="signupForm" :model="nSignup" :rules="ruleInline">

                <FormItem prop="doctorName">
                    <p class="form-label">* Nombre completo del Médico:</p>
                    <Input size="large" type="text" v-model="nSignup.doctorName" placeholder=""/>
                </FormItem>

                <FormItem prop="doctorSpeciality">
                    <p class="form-label">* Especialidad:</p>
                    <Input size="large" type="text" v-model="nSignup.doctorSpeciality" placeholder=""/>
                </FormItem>

                <FormItem prop="doctorHospital">
                    <p class="form-label">* Institución - Nombre del hospital:</p>
                    <Input size="large" type="text" v-model="nSignup.doctorHospital" placeholder=""/>
                </FormItem>

                <FormItem prop="program">
                    <p class="form-label">* Seleccionar programa:</p>
                    <Select v-model="nSignup.program" size="large" style="width: 100%">
                        <Option v-for="product in products" :key="'product_'+product.id" :value="product.id">{{ product.name }}</Option>
                    </Select>
                </FormItem>

                <FormItem prop="patientType">
                    <div style="padding: 0 25px">
                        <!-- If program is not selected -->
                        <RadioGroup v-if="SelectedProgram === null" v-model="nSignup.patientType" vertical>
                            <Radio disabled size="large" class="rpm-radio-button" label="diagnóstico">
                                <p>Diagnóstico y perfilamiento</p>
                            </Radio>
                            <Radio disabled size="large" class="rpm-radio-button" label="adherencia">
                                <p>Pacientes en tratamiento (Adherencia)</p>
                            </Radio>
                            <Radio disabled size="large" class="rpm-radio-button" label="bonus">
                                <p s>Oferta comercial - Apego a tratamiento</p>
                            </Radio>
                        </RadioGroup>

                        <!-- If Program is selected -->
                        <RadioGroup v-else v-model="nSignup.patientType" vertical>
                            <Radio v-if="nSignup.program !== '' && SelectedProgram && SelectedProgram.diagnosis" size="large" class="rpm-radio-button"
                                   label="diagnóstico">
                                <p>Diagnóstico y perfilamiento</p>
                            </Radio>
                            <Radio v-if="nSignup.program !== '' && SelectedProgram && SelectedProgram.adherence" size="large" class="rpm-radio-button"
                                   label="adherencia">
                                <p>Pacientes en tratamiento (Adherencia)</p>
                            </Radio>
                            <Radio v-if="nSignup.program !== '' && SelectedProgram && SelectedProgram.bonus" size="large" class="rpm-radio-button"
                                   label="bonus">
                                <p s>Oferta comercial - Apego a tratamiento</p>
                            </Radio>
                        </RadioGroup>

                    </div>
                </FormItem>

                <br>
                <p>En todos los casos ingrese datos de paciente:</p>
                <p><small>* Campos obligatorios</small></p>
                <br>

                <FormItem prop="patientName">
                    <p class="form-label">* Nombre completo:</p>
                    <Input size="large" type="text" v-model="nSignup.patientName" placeholder=""/>
                </FormItem>

                <FormItem prop="patientPhone1">
                    <p class="form-label">* Teléfono de contacto 1:</p>
                    <Input size="large" type="number" v-model="nSignup.patientPhone1" placeholder=""/>
                </FormItem>

                <FormItem prop="patientPhone2">
                    <p class="form-label">Teléfono de contacto 2:</p>
                    <Input size="large" type="number" v-model="nSignup.patientPhone2" placeholder=""/>
                </FormItem>

                <FormItem prop="patientEmail">
                    <p class="form-label">Correo electrónico:</p>
                    <Input size="large" type="email" v-model="nSignup.patientEmail" placeholder=""/>
                </FormItem>

                <div style="position: relative">
                    <p style="position: relative; font-size: 13px;" class="ivu-form-item-error-tip" v-for="(error, i) in response_errors"
                       :key="'error_'+i">{{ error }}</p>
                </div>

                <FormItem>
                    <Button :loading="loading" long type="primary" size="large" @click="signupHandler">Enviar</Button>
                </FormItem>

            </Form>
        </template>

        <el-dialog class="doctor-dialog" title="" :visible.sync="show_success_dialog" width="90%">
            <h2 style="text-align: center; word-break: break-word; color: white; font-weight: normal;">
                Estimado médico, gracias por pre-registrar a su paciente en el programa de apoyo a pacientes <b>POR TI</b>, un
                especialista del programa se pondrá en contacto con el paciente para solicitar receta (que indique diagnóstico médico) y
                firma del aviso de privacidad.
            </h2>
        </el-dialog>


    </TemplateContent>
</template>

<script>
    /*global axios, _ */

    import { mapGetters } from "vuex"
    export default {
        data(){
            return {
                window_loading: true,
                loading: false,
                nSignup: {
                    doctorName: '',
                    doctorSpeciality: '',
                    doctorHospital: '',
                    program: '',
                    patientType: '',
                    patientName: '',
                    patientPhone1: '',
                    patientPhone2: '',
                    patientEmail: '',
                },
                response_errors: [],
                show_success_dialog: false,
                products: [],
                ruleInline: {
                    doctorName: [
                        { required: true, message: 'Nombre del médico es requerido', trigger: 'change' }
                    ],
                    doctorSpeciality: [
                        { required: true, message: 'Especialidad es requerida', trigger: 'change' }
                    ],
                    doctorHospital: [
                        { required: true, message: 'Institución es requerida', trigger: 'change' }
                    ],
                    program: [
                        { required: true, type: 'number', message: 'Programa es requerida', trigger: 'change' }
                    ],
                    patientType: [
                        { required: true, message: 'Tipo de paciente es requerido', trigger: 'change' }
                    ],
                    patientName: [
                        { required: true, message: 'Nombre del paciente es requerido', trigger: 'change' }
                    ],
                    patientPhone1: [
                        { required: true, message: 'Teléfono es requerido', trigger: 'change' },
                        { min: 10, message: '10 dígitos', trigger: 'change' },
                        { max: 10, message: '10 dígitos', trigger: 'change' }
                    ],
                    patientEmail: [
                        { required: false, message: 'Correo electrónico es requerido', trigger: 'change' },
                        { type: 'email', message: 'Correo electrónico no válido', trigger: 'change' }
                    ],
                },
            }
        },
        computed: {
            ...mapGetters([
                "user",
            ]),
            SelectedProgram(){
                if( this.nSignup.program === '' || this.nSignup.program === undefined ) return null
                else {
                    let pi = _.findIndex(this.products, p => p.id === this.nSignup.program )
                    return this.products[pi]
                }
            }
        },
        methods: {
            signupHandler(){
                this.$refs['signupForm'].validate().then(valid => {
                    if(valid){
                        this.response_errors = []
                        this.loading = true
                        axios.post(this.$root.API_URL+"/doctors/patient-signup", {
                            doctor_name: this.nSignup.doctorName,
                            doctor_speciality: this.nSignup.doctorSpeciality,
                            doctor_hospital: this.nSignup.doctorHospital,
                            patient_program_id: this.nSignup.program,
                            patient_program_name: this.SelectedProgram.name,
                            patient_type: this.nSignup.patientType,
                            patient_name: this.nSignup.patientName,
                            patient_phone1: this.nSignup.patientPhone1,
                            patient_phone2: this.nSignup.patientPhone2,
                            patient_email: this.nSignup.patientEmail,
                        })
                        .then(response => {
                            if(response.data.success){
                                this.show_success_dialog = true
                                this.init()
                            }else {
                                this.loading = false
                                this.$Message.error({
                                    content: response.data.message,
                                    duration: 6,
                                })
                            }
                        })
                        .catch((error) => {
                            this.loading = false
                            this.$Message.error({
                                    content: 'Whoops. Algo salió mal...',
                                    duration: 6,
                                })
                            if( error.response && error.response.data && error.response.data.errors ){
                                _.each(error.response.data.errors, e => {
                                    this.response_errors.push(e[0])
                                })
                            }
                        })
                    }
                })
            },
            init(){
                try {
                    this.$refs['signupForm'].resetFields()
                } catch(e){
                    //console.log(" Form couldn't be rested ")
                }
                this.response_errors = []
                this.nSignup = {
                    doctorName: '',
                    doctorSpeciality: '',
                    doctorHospital: '',
                    program: '',
                    patientType: '',
                    patientName: '',
                    patientPhone1: '',
                    patientPhone2: '',
                    patientEmail: '',
                }
                this.loading = false
            },
            getProducts(){
                axios.post(this.$root.API_URL+"/content/v2/get-products")
                .then(response => {
                    if(response.data.success){
                        this.products = response.data.products
                        this.window_loading = false
                    }
                })
            },
        },
        mounted(){
            this.getProducts()
        },
    }
</script>