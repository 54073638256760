<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h1 class="section-title">Nuestro Patient Journey</h1>

            <Loading v-if="loading" />
            <template v-else>

                <p style="text-align: justify;" v-html="MainDescription"></p>
                <br>

                <!-- Diagnóstico y perfilamiento -->
                <div style="background-color: #17b6db" @click="collapse" class="pathology-menu-button prd-service-btn" aria-target="diagnostico-content">
                    <img @click="collapseParent" class="scale-3" src="/images/icons/patients-journey/diagnostico.png" />
                    <h4 @click="collapseParent" style="flex-grow: 1;">Diagnóstico y perfilamiento</h4>
                    <Icon type="ios-arrow-down" size="20" color="white" />
                </div>
                <div class="prg-service-content" id="diagnostico-content" aria-expanded="false">
                    <div class="prg-service-content--wrapper">
                        <p style="text-align: justify;" v-html="DiagnosisMainDescription"></p>
                    </div>
                </div>

                <!-- Pacientes en tratamiento (Adherencia) -->
                <div style="background-color: #1396d7" @click="collapse" class="pathology-menu-button prd-service-btn" aria-target="adherencia-content">
                    <img @click="collapseParent" class="scale-3" src="/images/icons/patients-journey/adherencia.png" />
                    <h4 @click="collapseParent" style="flex-grow: 1;">Pacientes en tratamiento (Adherencia)</h4>
                    <Icon type="ios-arrow-down" size="20" color="white" />
                </div>
                <div class="prg-service-content" id="adherencia-content" aria-expanded="false">
                    <div class="prg-service-content--wrapper">
                        <p style="text-align: justify;" v-html="AdherenciaMainDescription"></p>
                    </div>
                </div>

                <!-- Oferta comercial - Apego a tratamiento -->
                <div style="background-color: #0f6ba9" @click="collapse" class="pathology-menu-button prd-service-btn" aria-target="oferta-content">
                    <img @click="collapseParent" class="scale-3" src="/images/icons/patients-journey/oferta-comercial.png" />
                    <h4 @click="collapseParent" style="flex-grow: 1;">Oferta comercial - Apego a tratamiento</h4>
                    <Icon type="ios-arrow-down" size="20" color="white" />
                </div>
                <div class="prg-service-content" id="oferta-content" aria-expanded="false">
                    <div class="prg-service-content--wrapper">
                        <p style="text-align: justify;" v-html="OfertaMainDescription"></p>
                    </div>
                </div>

            </template>

        </TemplateContent>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                texts: [],
                loading: true,
            }
        },
        computed: {
            MainDescription(){
                let ti = window._.findIndex(this.texts, text => text.code == 'patient_journey.mainDescription')
                return ti != -1 ? this.texts[ti].content : ""
            },
            DiagnosisMainDescription(){
                let ti = window._.findIndex(this.texts, text => text.code == 'patient_journey.diagnosis.mainDescription')
                return ti != -1 ? this.texts[ti].content : ""
            },
            AdherenciaMainDescription(){
                let ti = window._.findIndex(this.texts, text => text.code == 'patient_journey.adherencia.mainDescription')
                return ti != -1 ? this.texts[ti].content : ""
            },
            OfertaMainDescription(){
                let ti = window._.findIndex(this.texts, text => text.code == 'patient_journey.oferta.mainDescription')
                return ti != -1 ? this.texts[ti].content : ""
            },
        },
         methods: {
            fetchTexts(){
                window.axios.post(this.$root.API_URL+ `/content/dynamic-texts`, { code: 'patient_journey' })
                .then(response => {
                    if(response.data.success){
                        this.texts = response.data.texts
                        this.loading = false
                    }
                })
            },
            collapse(e){
                let target_name = window.$(e.srcElement).attr('aria-target')
                let target = window.$(`#${target_name}`)

                let target_wrapper = window.$(`#${target_name} .prg-service-content--wrapper`)
                let is_expanded = window.$(target[0]).attr('aria-expanded') || "false"

                let target_h = `${target_wrapper[0].clientHeight || 0}px`

                if(is_expanded == "true"){
                    target[0].style.height = "0px"
                    window.$(target[0]).attr('aria-expanded', 'false')
                    window.$(e.srcElement).toggleClass('active')
                }
                else {
                    target[0].style.height = target_h
                    window.$(target[0]).attr('aria-expanded', 'true')
                    window.$(e.srcElement).toggleClass('active')
                }
            },
            collapseParent(e){
                let target_name = window.$(e.srcElement.parentElement).attr('aria-target')
                let target = window.$(`#${target_name}`)

                let target_wrapper = window.$(`#${target_name} .prg-service-content--wrapper`)
                let is_expanded = window.$(target[0]).attr('aria-expanded') || "false"

                let target_h = `${target_wrapper[0].clientHeight || 0}px`

                if(is_expanded == "true"){
                    target[0].style.height = "0px"
                    window.$(target[0]).attr('aria-expanded', 'false')
                    window.$(e.srcElement.parentElement).toggleClass('active')
                }
                else {
                    target[0].style.height = target_h
                    window.$(target[0]).attr('aria-expanded', 'true')
                    window.$(e.srcElement.parentElement).toggleClass('active')
                }
            }
        },
        mounted(){
            this.fetchTexts()
        }
    }
</script>