<template>
    <div>
        <template v-if="item.category == 'legend'">
            <div class="service-checkbox">
                <div class="service-checkbox--container legend">
                    <div class="service-checkbox--content">
                        <template v-if="product == 'Hemlibra' && item.services[0].category == 'extra' && item.name == 'Hematología:'">
                            <div style="font-size: 1em; margin-bottom: 10px;">Estos estudios aplican solo durante el seguimiento al tratamiento del paciente con emicizumab:</div>
                        </template>
                        <p style="font-weight: bold; font-size: 1em; margin-top: 0px;">{{ item.name }}</p>
                    </div>
                </div>
            </div>
            <div v-for="service in item.services" :key="'req_accdt_'+service.id" class="service-checkbox" :class="{ 'button': service.form_required }" @click="emitChildSelected(item, service)">
                <div class="service-checkbox--container">
                    <div class="service-checkbox--icon" :class="{ 'checked' : service.checked == true }">
                        <div class="service-checkbox--icon--empty"/>
                        <Icon size="21" color="#008fd5" type="md-checkbox" />
                    </div>
                    <div class="service-checkbox--content">
                        <p>{{ service.name }}</p>

                        <template v-if="service.name == 'Pruebas psicométricas'">
                            <p>Aplica al solicitar centro de infusión.</p>
                        </template>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="service-checkbox" :class="{ 'button': item.form_required }" @click="emitSelected(item)">
                <div class="service-checkbox--container">
                    <div class="service-checkbox--icon" :class="{ 'checked' : item.checked == true }">
                        <div class="service-checkbox--icon--empty"/>
                        <Icon size="21" color="#008fd5" type="md-checkbox" />
                    </div>
                    <div class="service-checkbox--content">
                        <p v-html="item.name"></p>

                        <!-- Request legend -->
                        <p v-if="item.wa_data && item.wa_data.request_legend && typeof item.wa_data.request_legend == 'string'">
                            {{ item.wa_data.request_legend }}
                        </p>

                        <!-- Legends set by product name -->
                        <p v-if="item.wa_data && typeof item.wa_data.request_legend == 'object'">
                            <span v-if="item.wa_data.request_legend[product]" 
                            v-html="item.wa_data.request_legend[product]"></span>
                        </p>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            item: { required: true, type: [Object, Array] },
            product: { required: true, type: String },
            patient: { required: true, type: [Object, Array] },
        },
        computed: {
            ...mapGetters({
                services: 'patient_services',
            }),
        },
        methods: {
            emitSelected(item){
                let category = item.category
                if( item.category == 'extra' ) category = 'additionals' 

                if(item.form_required){
                    if( item.checked == false ){
                        this.$store.dispatch("SET_KEEP_SERVICES_ALIVE", true)
                        this.$router.push(`/doctor/my-patient/${this.patient.id}/form/${this.item.form_id}?scroll=${window.scrollY}&source=${item.name}&category=${category}`)
                    }
                    else {
                        this.$router.push(`/doctor/my-patient/${this.patient.id}/form/${this.item.form_id}?scroll=${window.scrollY}&source=${item.name}&fill=true&category=${category}`)
                    }
                }
                else {
                    
                    let sii = window._.findIndex(this.services[category], (service) => {
                        return service.id == item.id
                    })

                    if( sii != -1 ){
                        item.checked = !item.checked
                        this.$store.dispatch("SET_PATIENT_SERVICE", {
                            category: category,
                            service: item
                        })
                        // this.$emit('selected', item)
                        // this.$forceUpdate()
                    }


                }
            },
            emitChildSelected(parent, item){
                let category = item.category
                if( item.category == 'extra' ) category = 'additionals' 

                if(item.form_required){
                    if( item.checked == false ){
                        this.$store.dispatch("SET_KEEP_SERVICES_ALIVE", true)
                        this.$router.push(`/doctor/my-patient/${this.patient.id}/form/${item.form_id}?scroll=${window.scrollY}&source=${parent.id}&target=${item.name}&category=${category}`)
                    }
                    else {
                        this.$router.push(`/doctor/my-patient/${this.patient.id}/form/${item.form_id}?scroll=${window.scrollY}&source=${parent.id}&target=${item.name}&fill=true&category=${category}`)
                    }
                }
                else {
                    let sii = window._.findIndex(this.services[category], (service) => {
                        return service.id == parent.id
                    })
                    
                    if( sii != -1 ){

                        let ims = window._.findIndex(this.services[category][sii].services, (service) => {
                            return service.id == item.id
                        })

                        if( ims != -1 ){
                            item.checked = !item.checked
                            this.$store.dispatch("SET_PATIENT_SERVICE", {
                                category: category,
                                service: item
                            })
                        }
                        // this.$emit('selected', item)
                        // this.$forceUpdate()
                    }


                }
            }
        }
    }
</script>

<style lang="scss">
    .service-checkbox{
        &--container{
            width: 100%;
            display: flex;
            padding: 10px;
            /* background-color: rgba(255,255,255,0.05); */
            margin-bottom: 5px;
            border-radius: 5px;
            &.legend{
                padding-bottom: 0 !important;
            }
        }
        &--icon{
            position: relative;
            min-width: 15px;
            margin-top: 3px;
            margin-right: 15px;
            &--empty{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                border-radius: 3px;
                border: 2px solid white;
            }
            & i.ivu-icon{
                display: none;
            }
            &.checked{
                & .service-checkbox--icon--empty{
                    border-color: #008fd5;
                }
                & i.ivu-icon{
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    display: block;
                }
            }
        }
        &--content{
            flex-grow: 1;
            & p{
                font-size: 0.8em;
                margin-top: 3px;
                &:first-child{
                    font-weight: bold;
                    font-size: 1em;
                    margin-top: 0px;
                }
                & p {
                    font-size: 1em !important;
                }
            }
            & span {
                font-weight: normal !important;
                & p {
                    font-weight: normal !important;
                }
            }
        }
        &.button{
            & .service-checkbox--container{
                background: #008fd5;
            }
            & .service-checkbox--icon.checked i.ivu-icon{
                filter: brightness(0) invert(1);
            }
        }
    }
</style>