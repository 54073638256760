<template>
    <div style="background-color: white; color: #006fb9;">
        <ServiceContent footer_color="#006fb9">
            <div slot="header" class="image-header">
                <img style="width: 100%;" src="/images/doctor-services/CentroAtencion.png" alt="">
            </div>
            <p class="service-title">Centro de atención a pacientes</p>
            
            <Loading v-if="loading" />
            <div v-else class="ptn-dyn-cont">
                <p v-html="MainDescription"></p>
            </div>

        </ServiceContent>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                texts: [],
                loading: true,
            }
        },
        computed: {
            MainDescription(){
                let ti = window._.findIndex(this.texts, text => text.code == 'care_center.mainDescription')
                return ti != -1 ? this.texts[ti].content : ""
            },
        },
        methods: {
            fetchTexts(){
                window.axios.post(this.$root.API_URL+ `/content/dynamic-texts`, { code: 'care_center' })
                .then(response => {
                    if(response.data.success){
                        this.texts = response.data.texts
                        this.loading = false
                    }
                })
            },
        },
        mounted(){
            this.fetchTexts()
        }
    }
</script>

<style lang="scss">
    .ptn-dyn-cont{
        color: black;
        & ul {
            list-style: none;
            margin-left: 15px;
            & li{
                padding: 12px 0;
                &::before {
                    content: "\2022";
                    color: rgb(0, 111, 185);
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1em; 
                }
            }
        }
    }
</style>