<template>
    <TemplateContent>
        <h1 class="section-title">Cambia tu contraseña</h1>
        <p>Para mantener tu seguridad, actualiza tu contraseña:</p>
        <br>
        <Form ref="passwForm" :model="passw" :rules="ruleInline">
            <FormItem prop="password">
                <p class="form-label">Contraseña</p>
                <Input size="large" v-model="passw.password" type="password" placeholder=""/>
            </FormItem>
            <FormItem prop="confirm">
                <p class="form-label">Confirma contraseña</p>
                <Input size="large" v-model="passw.confirm" type="password" placeholder=""/>
            </FormItem>
            <FormItem>
                <Button :loading="loading" size="large" @click="resetPassword" style="margin-top: 15px" long type="primary">Cambiar</Button>
            </FormItem>
        </Form>
    </TemplateContent>
</template>

<script>
    /*global axios */
    export default {
        data(){
            return {
                loading: false,
                passw: {
                    password: "",
                    confirm: "",
                },
                ruleInline: {
                    password: [
                        { 
                            required: true, 
                            message: 'Contraseña es requerida',
                            trigger: 'change' 
                        },
                    ],
                    confirm: [
                        { 
                            required: true, 
                            message: 'Contraseña es requerida',
                            trigger: 'change' 
                        },
                    ]
                },
            }
        },
        methods: {
            resetPassword(){
                this.$refs['passwForm'].validate(valid => {
                    if(valid){
                        if(this.passw.password === this.passw.confirm){
                            this.loading = true
                            axios.post(this.$root.API_URL+"/auth/reset-password/safe", { password: this.passw.password })
                            .then(response => {
                                if(response.data.success){
                                    this.$Message.success({
                                        content: "Tu contraseña fue cambiada exitosamente...",
                                        duration: 6,
                                    })
                                    this.loading = false
                                    this.$store.dispatch("UPDATE_USER_FIELD", {
                                        field: 'password_reset_required',
                                        value: false
                                    })
                                    this.$router.push("/doctor/dashboard")
                                }else {
                                    this.$Message.error({
                                        content: response.data.message,
                                        duration: 6,
                                    })
                                    this.loading = false
                                }
                            })
                            .catch(() => {
                                this.$Message.error({
                                    content: "Algo salió mal. Por favor, inténtalo más tarde...",
                                    duration: 6,
                                })
                                this.loading = false
                            })
                        }
                        else {
                            this.$Message.error({
                                content: "Las contraseñas no coinciden...",
                                duration: 6,
                            })
                            this.loading = false
                        }
                    }
                }) 
            }
        }
    }
</script>