<template>
    <div class="doctor-report--container">

        <template v-if="loading">
            <Loading black />
        </template>

        <template v-else>

            <div class="doctor-report--header">
                <p>Recuerde que puede visualizar el reporte en esta pantalla o descargarlo en formato PDF si así lo requiere.</p>
                <Button @click="downloadReportPDFJS" size="large" icon="md-download" type="primary">Descargar reporte mensual</Button>
            </div>

            <div class="doctor-report--pdf">

                <table v-if="HasInfo" id="element-to-convert" style="background-color: white; margin-bottom: 30px; color: black; width:800px">
                    <tr>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="6" style="padding-left: 50px; padding-bottom: 15px">
                            <img style="width: 65px" class="roche-logo" src="/images/logo-roche.png" alt="">
                            <img style="width: 80px; margin-left: 15px" class="logo" src="/images/logo-por-ti.png">
                        </td>
                        <td colspan="6" style="padding-right: 25px"></td>
                    </tr>
                    <tr>
                        <td colspan="12">
                            <img style="height: 70px; width: 100%" src="/images/pleca.png" />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" style="padding: 30px 50px 10px; font-size: 13px;">
                            <h2 style="font-weight: normal">Reporte de seguimientos a pacientes</h2>
                            <br>
                            <br>
                            <p style="color: #006fb9">Encuentre a continuación el reporte mensual de seguimiento de sus pacientes.</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12">
                            <div style="padding: 0 35px; display: flex; align-items:center">
                                <img style="width: 30px; margin-left: 15px" class="logo" src="/images/logo-por-ti-corazon.png">
                            <h2 style="margin-left: 10px; font-weight: normal; color: #006fb9">
                                Mes: <span style="text-transform: capitalize;">{{ report.month | month_name  }}</span> {{report.year }}
                                </h2>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12">
                            <p>&nbsp;</p>
                        </td>
                    </tr>

                    <tr v-for="(data, i) in report.patients_data" :key="`data_${i}`">
                        <td colspan="12">
                            <table style="width: 100%">
                                <tr>
                                    <td style="width: 8.3%;"></td>
                                    <td style="width: 8.3%;"></td>
                                    <td style="width: 8.3%;"></td>
                                    <td style="width: 8.3%;"></td>
                                    <td style="width: 8.3%;"></td>
                                    <td style="width: 8.3%;"></td>
                                    <td style="width: 8.3%;"></td>
                                    <td style="width: 8.3%;"></td>
                                    <td style="width: 8.3%;"></td>
                                    <td style="width: 8.3%;"></td>
                                    <td style="width: 8.3%;"></td>
                                    <td style="width: 8.3%;"></td>
                                </tr>
                                <tr>
                                    <td colspan="12" style="padding: 30px 50px">
                                        <div style="width: 100%; height: 3px; background-color: #006fb9; position: relative;">
                                            <p style="position: absolute; padding: 5px 10px 5px 0px; background: white; left: 0px; top: 50%;
                                    transform:translateY(-50%); font-weight: bold; color: #006fb9;">Paciente {{ i + 1 }}</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr style="font-size: 12px; page-break-inside: avoid;">
                                    <td style="padding-left: 50px; margin: 0 5px;" colspan="2">
                                        <p style="margin: 0 5px; color: #535d70; page-break-inside: avoid;"><strong>Folio</strong></p>
                                        <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.folio }}</strong></p>
                                    </td>
                                    <td colspan="5">
                                        <p style="margin: 0 5px; color: #535d70; page-break-inside: avoid;"><strong>Paciente</strong></p>
                                        <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.name }}</strong></p>
                                    </td>
                                    <td colspan="5" style="padding-right: 50px">
                                        <p style="margin: 0 5px; color: #535d70; page-break-inside: avoid;"><strong>Diagnóstico</strong></p>
                                        <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.diagnosis }}</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 5px"></td>
                                </tr>
                                <tr style="font-size: 12px; page-break-inside: avoid;">
                                    <td style="padding-left: 50px;" colspan="3">
                                        <p style="margin: 0 5px; color: #535d70"><strong>Medicamento</strong></p>
                                        <p
                                            style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.product }}</strong></p>
                                    </td>
                                    <td colspan="2">
                                        <p style="margin: 0 5px; color: #535d70"><strong>Dosis</strong></p>
                                        <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.payload.informacion.dosis }}mg</strong></p>
                                    </td>
                                    <td colspan="2">
                                        <p style="margin: 0 5px; color: #535d70"><strong>Frecuencia</strong></p>
                                        <p
                                            style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.payload.informacion.frecuencia
                                            }}</strong></p>
                                    </td>
                                    <td colspan="5" style="padding-right: 50px">
                                        <p style="margin: 0 5px; color: #535d70"><strong>Última aplicación de medicamento</strong></p>
                                        <p style="margin: 0 5px; color: #bdc1c8"><strong>{{
                                                data.payload.informacion.fechaAplicacionMedicamento || "&nbsp;" }}</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="12" style="padding: 15px 50px">
                                        <div style="width: 100%; height: 3px; background-color: #bdc1c8;"></div>
                                    </td>
                                </tr>
                                <!-- Patient services -->
                                <template v-if="data.services && data.services.length > 0">
                                    <tr style="font-size: 12px; page-break-inside: avoid;">
                                        <td style="padding-left: 50px;" colspan="8">
                                            <p style="margin: 0 5px; color: #535d70"><strong>Estudios clínicos</strong></p>
                                        </td>
                                        <td colspan="4">
                                            <p style="margin: 0 5px; color: #535d70"><strong>Fecha de realización</strong></p>
                                        </td>
                                    </tr>
                                    <tr v-for="(service, i) in data.services" :key="`service_${i}`" style="font-size: 12px; page-break-inside: avoid;">
                                        <td style="padding-left: 50px;" colspan="8">
                                            <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ service.type }}</strong></p>
                                        </td>
                                        <td colspan="4">
                                            <p
                                                style="margin: 0 5px; color: #bdc1c8"><strong>{{ service.date | moment("DD/MM/YYYY")
                                                }}</strong></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="12" style="padding: 15px 50px">
                                            <div style="width: 100%; height: 3px; background-color: #bdc1c8;"></div>
                                        </td>
                                    </tr>
                                </template>

                                <!-- Centro de infusión -->
                                <template v-if=" data.payload.informacion.ciCentroInfusion !== null &&
                                data.payload.informacion.fechaCentroInfusion ">

                                    <tr style="font-size: 12px; page-break-inside: avoid;">
                                        <td style="padding-left: 50px;" colspan="4">
                                            <p style="margin: 0 5px; color: #535d70"><strong>Intusion en Cl</strong></p>
                                        </td>
                                        <td colspan="4">
                                            <p style="margin: 0 5px; color: #535d70"><strong>Fecha de aplicacion en Cl</strong></p>
                                        </td>
                                        <td style="padding-right: 50px;" colspan="4">
                                            <p v-if=" data.payload.reporte_post_infusion " style="margin: 0 5px; color: #535d70; margin-bottom: 5px"><strong>Reporte Post
                                                Infusion</strong></p>
                                        </td>
                                    </tr>
                                    <tr style="font-size: 12px;">
                                        <td style="padding-left: 50px;" colspan="4">
                                            <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.payload.informacion.ciCentroInfusion }}</strong></p>
                                        </td>
                                        <td colspan="4">
                                            <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.payload.informacion.fechaCentroInfusion }}</strong></p>
                                        </td>
                                        <td colspan="4">
                                            <p style="margin: 0 5px; color: #bdc1c8">
                                                <template v-if=" data.payload.reporte_post_infusion ">
                                                    <a style="padding: 5px 10px; background-color: black; color: white; border-radius: 5px; font-weight: bold;
                                            font-size: 10px"
                                                       :href="data.payload.reporte_post_infusion"
                                                       target="_blank">
                                                        <img style="width: 13px" src="/images/descargar-reporte.png" alt="">
                                                        Descargar
                                                    </a>
                                                </template>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="12" style="padding: 15px 50px">
                                            <div style="width: 100%; height: 3px; background-color: #bdc1c8;"></div>
                                        </td>
                                    </tr>

                                </template>

                                <template v-if=" data.payload.informacion.fechaOrginalObsequio !== null ||
                                 data.payload.informacion.fechaInfusiondomicilio !== null ||
                                 data.payload.informacion.fechaBonificacionComercial !== null ">
                                    <tr style="font-size: 12px; page-break-inside: avoid;">
                                        <td v-if=" data.payload.informacion.fechaOrginalObsequio !== null " style="padding-left: 50px;"
                                            colspan="4">
                                            <p style="margin: 0 5px; color: #535d70"><strong>Entrega de original de obsequio</strong></p>
                                            <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.payload.informacion.fechaOrginalObsequio }}</strong></p>
                                        </td>
                                        <td v-if=" data.payload.informacion.fechaInfusiondomicilio !== null " :class="{
                                            'padding-left-50': data.payload.informacion.fechaOrginalObsequio === null
                                        }"
                                            colspan="4">
                                            <p style="margin: 0 5px; color: #535d70"><strong>Infusión a domicilo</strong></p>
                                            <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.payload.informacion.fechaInfusiondomicilio }}</strong></p>
                                        </td>
                                        <td v-if=" data.payload.informacion.fechaBonificacionComercial !== null " style="padding-right: 50px;"
                                            colspan="4">
                                            <p style="margin: 0 5px; color: #535d70"><strong>Bonificación comercial de medicamento</strong></p>
                                            <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.payload.informacion.fechaBonificacionComercial }}</strong></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="12" style="padding: 15px 50px">
                                            <div style="width: 100%; height: 3px; background-color: #bdc1c8;"></div>
                                        </td>
                                    </tr>

                                </template>

                                <template v-if=" data.payload.informacion.fechaProgramaMetabolico !== null ||
                                data.payload.informacion.fechaRehabilitacionPulmonar !== null  ">
                                    <tr style="font-size: 12px;" page-break-inside: avoid;>
                                        <td style="padding-left: 50px;" colspan="4">
                                            <p style="margin: 0 5px; color: #535d70"><strong>Programa de apoyo metabólico</strong></p>
                                            <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.payload.informacion.fechaProgramaMetabolico }}</strong></p>
                                        </td>
                                        <td colspan="4">
                                            <p style="margin: 0 5px; color: #535d70"><strong>Programa de rehabilitación pulmonar</strong></p>
                                            <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.payload.informacion.fechaRehabilitacionPulmonar }}</strong></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="12" style="padding: 15px 50px">
                                            <div style="width: 100%; height: 3px; background-color: #bdc1c8;"></div>
                                        </td>
                                    </tr>
                                </template>

                                <template v-if=" data.payload.informacion.fechaCapacitacionManejoAplicacionPresencial !== null ||
                                data.payload.informacion.fechaRehabilitacionFisica !== null ||
                                data.payload.informacion.fechaCapacitacionManejoVirtual !== null  ">

                                    <tr style="font-size: 12px;">
                                        <td v-if=" data.payload.informacion.fechaCapacitacionManejoAplicacionPresencial !== null " style="padding-left: 50px;" colspan="4">
                                            <p style="margin: 0 5px; color: #535d70"><strong>Capacitación y manejo de aplicación presencial</strong></p>
                                        </td>
                                        <td v-if=" data.payload.informacion.fechaRehabilitacionFisica !== null " colspan="4">
                                            <p style="margin: 0 5px; color: #535d70"><strong>Rehabilitación física</strong></p>
                                        </td>
                                        <td v-if=" data.payload.informacion.fechaCapacitacionManejoVirtual !== null " style="padding-right: 50px;" colspan="4">
                                            <p style="margin: 0 5px; color: #535d70"><strong>Capacitación y mannejo de aplicación virtual</strong></p>
                                        </td>
                                    </tr>
                                    <tr style="font-size: 12px;">
                                        <td v-if=" data.payload.informacion.fechaCapacitacionManejoAplicacionPresencial !== null " style="padding-left: 50px;" colspan="4">
                                            <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.payload.informacion.fechaCapacitacionManejoAplicacionPresencial }}</strong></p>
                                        </td>
                                        <td v-if=" data.payload.informacion.fechaRehabilitacionFisica !== null " colspan="4">
                                            <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.payload.informacion.fechaRehabilitacionFisica }}</strong></p>
                                        </td>
                                        <td v-if=" data.payload.informacion.fechaCapacitacionManejoVirtual !== null " style="padding-right: 50px;" colspan="4">
                                            <p style="margin: 0 5px; color: #bdc1c8"><strong>{{ data.payload.informacion.fechaCapacitacionManejoVirtual !== null }}</strong></p>
                                        </td>
                                    </tr>

                                </template>

                                <tr v-if=" report.patients_data.length !== i+1 ">
                                    <td colspan="12">
                                        <div style="width: 100%; background-color: #006fb9; min-height: 35px; display: flex; align-items:
                                         center; justify-content: center; margin: 15px 0; align-items: center;">
                                            <img style="height: 23px;" src="/images/footer-reporte-logo.png" alt="">
                                        </div>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td colspan="12">
                                        <p>&nbsp;</p>
                                    </td>
                                </tr>

                                <!--<div class="html2pdf__page-break"></div>-->
                            </table>

                        </td>

                    </tr>


                </table>

                <table v-if="!HasInfo" id="element-to-convert" style="background-color: white; color: black;">
                    <tr>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                        <td style="width: 8.3%;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="6" style="padding-left: 50px; padding-bottom: 15px">
                            <img style="width: 65px" class="roche-logo" src="/images/logo-roche.png" alt="">
                            <img style="width: 80px; margin-left: 15px" class="logo" src="/images/logo-por-ti.png">
                        </td>
                        <td colspan="6" style="padding-right: 25px"></td>
                    </tr>
                    <tr>
                        <td colspan="12">
                            <img style="height: 70px; width: 100%" src="/images/pleca.png" />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" style="padding: 30px 50px 10px; font-size: 13px;">
                            <h2 style="font-weight: normal">Reporte de seguimientos a pacientes</h2>
                            <br>
                            <br>
                            <p style="color: #006fb9">Encuentre a continuación el reporte mensual de seguimiento de sus pacientes.</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12">
                            <div style="padding: 0 35px; display: flex; align-items:center">
                                <img style="width: 30px; margin-left: 15px" class="logo" src="/images/logo-por-ti-corazon.png">
                                <h2 style="margin-left: 10px; font-weight: normal; color: #006fb9">
                                    Mes: <span style="text-transform: capitalize;">{{ report.month | month_name  }}</span> {{report.year }}
                                </h2>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 5px 50px" colspan="12">
                            <div style="width: 100%; border: 1px solid black; border-radius: 6px; padding: 15px; font-size: 13px">
                                <p>No existe suficiente información reciente de parte de sus pacientes para generar el reporte completo del
                                    último mes. Esto es posible debido a que sus pacientes no recibieron algún tratamiento de parte del programa
                                Roche POR TI.</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12">
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <div style="height: 500px"></div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12">
                            <br>
                            <div style="width: 100%; background-color: #006fb9; min-height: 55px; display: flex; align-items: center;
                            justify-content: center;">
                                <img style="width: 70px;" src="/images/footer-reporte-logo.png" alt="">
                            </div>
                        </td>
                    </tr>

                    <!-- Patient end -->
                </table>

            </div>

        </template>

    </div>
</template>

<script>
    import html2pdf from "html2pdf.js"
    //import VueHtml2pdf from 'vue-html2pdf'

    export default {
        data(){
            return {
                detected_OS: '',
                pdfOptions: {
                    margin: 0,
                    //filename: window.uuid.v4() + ".pdf",
                    // pagebreak: { mode: 'avoid-all' },
                    image: {type: 'jpeg', quality: 1},
                    html2canvas: {dpi: 192, scale: 3, useCORS: true, letterRendering: true},
                    jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait', compress: true},
                },
                loading: true,
                show_with_data: false,
                report: null,
            }
        },
        computed: {
            HasInfo(){
                return this.report?.patients_data?.length > 0
            }
        },
        methods: {
            downloadReport(){
                this.$refs.html2Pdf.generatePdf()
            },
            downloadReportPDFJS(){
                html2pdf().set(this.pdfOptions).from( document.getElementById('element-to-convert') )
                    .save(window.uuid.v4() + ".pdf")


                /*html2pdf(document.getElementById('element-to-convert'), {
                    margin: 0,
                    filename: window.uuid.v4() + ".pdf",
                    image: {type: 'jpeg', quality: 1},
                    html2canvas: {dpi: 72, letterRendering: true},
                    jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'},
                    callback: (pdfObject) => {
                        var number_of_pages = pdfObject.internal.getNumberOfPages()
                        var pdf_pages = pdfObject.internal.pages
                        var myFooter = "Footer info"

                        console.log(number_of_pages, pdf_pages, myFooter)

                        for (var i = 1; i < pdf_pages.length; i++) {
                            // We are telling our pdfObject that we are now working on this page
                            pdfObject.setPage(i)

                            pdfObject.text("my header text", 10, 10)

                            // The 10,200 value is only for A4 landscape. You need to define your own for other page sizes
                            pdfObject.text(myFooter, 10, 200)
                        }
                    }
                })*/
            },
            getOS(){
                var userAgent = window.navigator.userAgent,
                    platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
                    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
                    os = null;

                if (macosPlatforms.indexOf(platform) !== -1) {
                    os = 'Mac OS';
                } else if (iosPlatforms.indexOf(platform) !== -1) {
                    os = 'iOS';
                } else if (windowsPlatforms.indexOf(platform) !== -1) {
                    os = 'Windows';
                } else if (/Android/.test(userAgent)) {
                    os = 'Android';
                } else if (/Linux/.test(platform)) {
                    os = 'Linux';
                }

                this.detected_OS = os
                this.updatePdfOptions()
            },
            updatePdfOptions(){
                if( this.detected_OS === 'iOS' ){
                    this.pdfOptions.html2canvas.scale = 1
                    this.pdfOptions.html2canvas.dpi = 96
                }
            },
            fetchReportData(uuid){
                /* global axios */
                axios.post(this.$root.API_URL+"/get-report-info", { token: uuid })
                    .then(response => {
                        if( response.data.success ){
                            this.report = response.data.report
                            this.loading = false
                        }
                    })
                    .catch(() => {
                        this.$router.push('/404')
                    })
            }
        },
        mounted() {
            this.getOS()

            let uuid = this.$route.params.uid
            this.fetchReportData(uuid)
            //if( uuid === 'f0452926-d3ee-4a57-8ede-f63d411433b2' ) this.show_with_data = true
            //else this.show_with_data = false
        }
    }
</script>

<style lang="scss">
    .doctor-report--container{
        width: 100vw;
        height: 100vh;
        top: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #ececec;
        z-index: 99;
        overflow: auto;
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .doctor-report--header{
        width: 700px;
        margin: 30px 30px 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & p {
            color: black;
            text-align: center;
            margin-bottom: 15px;
        }
    }

    .doctor-report--pdf{
        width: 800px;
    }


    .padding-left-50{
        padding-left: 50px;
    }

    @media(max-width: 850px){
        .doctor-report--pdf{
            width: 90%;
            table {
                width: 90%;
            }
        }

        .doctor-report--header{
            width: 90%;
        }
    }

    @media print
    {
        .no-print, .no-print *
        {
            display: none !important;
        }
    }
</style>