<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h1 class="section-title">Servicios de adherencia</h1>

            <p><strong>Fomentamos la adherencia con:</strong></p>
            <br>

            <div @click="$router.push('/doctor/services/patients/aditionals/adherencia/inicio-tratamiento')" class="pathology-menu-button">
                <img class="scale-3" src="/images/menu/doctors/inicio-tratamiento.png" alt="">
                <div>
                    <p>Inicio de tratamiento y/o adherencia</p>
                    <p style="font-weight: normal !important;"><small>(Acceso original de obsequio)</small></p>
                </div>
            </div>

            <div @click="$router.push('/doctor/services/patients/aditionals/adherencia/centros-infusion')" class="pathology-menu-button">
                <img class="" src="/images/menu/doctors/aplicacion-en-centre.png" alt="">
                <p>Aplicación en centros de infusión</p>
            </div>

            <div @click="$router.push('/doctor/services/patients/aditionals/adherencia/capacitacion-medica')" class="pathology-menu-button">
                <img class="" src="/images/doctors-icons/Capacitacion-Medica-Continua.png" alt="">
                <p>Capacitación médica continua en el manejo de medicamento vía virtual</p>
            </div>

            <div @click="$router.push('/doctor/services/patients/aditionals/adherencia/infusion')" class="pathology-menu-button">
                <img class="" src="/images/doctors-icons/Infusion-a-domicilio.png" alt="">
                <p>Infusión a domicilio</p>
            </div>

            <div @click="$router.push('/doctor/services/patients/aditionals/adherencia/induccion')" class="pathology-menu-button">
                <img class="scale" src="/images/doctors-icons/Induccion.png" alt="">
                <p>Programa de Inducción</p>
            </div>

            <br>
            <hr>
            <br>

            <div v-for="service in Services" :key="'additional_service_'+service.id">
                <template v-if="service.category == 'legend'">
                    <div class="service-list-item">
                        <img :src="service.icon" alt="">
                        <p>{{ getLegendTitle(service.name) }}</p>
                    </div>

                    <div v-for="child in getChildsToList(service)" :key="'additional_service_'+service.id+'_child_'+child.id" style="margin-left: 70px" class="service-list-item">
                        <img :src="child.icon" alt="">
                        <p v-html="child.name"></p>
                    </div>
                    
                    <template v-if="service.name == 'Exámenes médicos:'">
                        <div style="margin-left: 70px" class="service-list-item">
                            <img src="/images/doctors-icons/Resonancia.png" alt="">
                            <p>Resonancia magnética</p>
                        </div>
                        <div style="margin-left: 70px" class="service-list-item">
                            <img src="/images/doctors-icons/Volumetri.png" alt="">
                            <p>Volumetría</p>
                        </div>
                    </template>
                </template>

                <template v-else>
                    <div class="service-list-item">
                        <img :src="service.icon" alt="">
                        <p v-html="service.name"></p>
                    </div>
                </template>
            </div>

            <!-- <div class="service-list-item">
                <img src="/images/doctors-icons/Tomografia.png" alt="">
                <p>Tomografía</p>
            </div>

            <div class="service-list-item">
                <img src="/images/doctors-icons/Sdesecuenciaciongenomico.png" alt="">
                <p>Servicio de secuenciación genómico</p>
            </div>

            <div class="service-list-item">
                <img src="/images/doctors-icons/Ultrasoniudos-para-monitorios.png" alt="">
                <p>Ultrasonidos para monitoreo de daño articular: rodilla, codo y tobillo</p>
            </div>

            <div class="service-list-item">
                <img src="/images/doctors-icons/Tiempodepromoplastina.png" alt="">
                <p>Tiempo de tromboplastina</p>
            </div>

            <div class="service-list-item">
                <img src="/images/doctors-icons/CuantificacionFVIII.png" alt="">
                <p>Cuantificación FVIII</p>
            </div>

            <div class="service-list-item">
                <img src="/images/doctors-icons/CuantificadordeinhibidorFVIII.png" alt="">
                <p>Cuantificador de Inhibidor FVIII </p>
            </div>

            <div class="service-list-item">
                <img src="/images/doctors-icons/DiagnosticoMolecular.png" alt="">
                <p>Diagnóstico molecular</p>
            </div>

            <div class="service-list-item">
                <img src="/images/doctors-icons/Estudios.png" alt="">
                <p>Exámenes médicos:</p>
            </div>

            <div style="margin-left: 50px" class="service-list-item">
                <img style="width: 25px" src="/images/doctors-icons/Hepatitis.png" alt="">
                <p style="font-size: 0.9em">Hepatitis ABC</p>
            </div>

            <div style="margin-left: 50px" class="service-list-item">
                <img style="width: 25px" src="/images/doctors-icons/Perfil.png" alt="">
                <p style="font-size: 0.9em">Perfil hepático I</p>
            </div>

            <div style="margin-left: 50px" class="service-list-item">
                <img style="width: 25px" src="/images/doctors-icons/Biometria.png" alt="">
                <p style="font-size: 0.9em">Biometría hemática</p>
            </div>

            <div style="margin-left: 50px" class="service-list-item">
                <img style="width: 25px" src="/images/doctors-icons/Quimica.png" alt="">
                <p style="font-size: 0.9em">Química sanguínea de 4 elementos</p>
            </div>

            <br> -->

            <p>Servicios adicionales y definidos de acuerdo a cada servicio disponible para cada línea terapéutica.</p>

            <!-- <div class="service-list-item">
                <img src="/images/doctors-icons/Resonancia.png" alt="">
                <p>Resonancia magnética</p>
            </div>

            <div class="service-list-item">
                <img src="/images/doctors-icons/Volumetri.png" alt="">
                <p>Volumetría</p>
            </div> -->
            
        </TemplateContent>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                services: [],
            }
        },
        computed: {
            Services(){
                return window._.sortBy(this.services, 'wa_list_order')
            }
        },
        methods: {
            getServices(){
                window.axios.post(this.$root.API_URL+ `/content/get-additionals`)
                .then(response => {
                    if(response.data.success){
                        this.services = response.data.services
                    }
                })
            },
            getChildsToList(parent){
                return window._.filter(parent.services, (service) => {
                    return service.can_be_listed == 1
                })
            },
            getLegendTitle(name){
                if(name == 'Hematología:') return 'Diagnóstico de hematología:'
                else if(name == 'Molecular:') return 'Diagnóstico molecular:'
                else return name
            }
        },
        mounted(){
            this.getServices()
        }
    }
</script>

<style lang="scss">
    .service-list-item{
        display: flex;
        align-items: center;
        margin: 15px 20px;
        &.to-left{
            margin-left: 0 !important;
        }
        &.child{
            margin-left: 70px !important;
        }
        & img {
            width: 37px;
        }
        & p {
            margin-left: 15px;
            /* font-weight: bold; */
        }
    }
</style>