<template>
    <div class="footer">
        <!-- HEMOPHILIA -->
        <Row v-if="this.user && this.user.pathology && this.user.pathology.name.includes('Hemofilia')" :gutter="10" type="flex" align="middle" style="margin-top: 20px;">
            <i-col span="14">
                <p :style="{ 'color': color || 'white' }" style="font-size: 10px">La Federación de Hemofilia de la República Mexicana, a través de su Grupo de Asesores Voluntarios Médicos, revisó y aprobó el contenido de esta app.</p>
            </i-col>
            <i-col span="10">
                <img style="width: 110px" src="/images/logo-hemofilia.png" alt="">
            </i-col>
        </Row>

        <!-- PATIENTS and HOME -->
        <p v-if="isPatient && this.$route.fullPath.includes('/dashboard')" :style="{ 'color': color || 'white' }" style="font-size: 10px; margin-top: 10px">El programa POR TI le ofrece servicios complementarios de apoyo sin costo, diseñados para dar seguimiento al tratamiento diagnosticado por su médico tratante. Estos materiales son de carácter informativo únicamente y de ninguna forma reemplazan y/o sustituyen el consejo médico. Los servicios complementarios serán de adopción voluntaria y no están condicionados a la venta, consumo o uso de productos. El médico tratante será en todo momento, quien determine el mejor tratamiento para el paciente. Le invitamos a permanecer en contacto con su médico.</p>

        <!-- DOCTORS and HOME -->
        <template v-if="isDoctor && this.$route.fullPath.includes('/dashboard')">
            <!-- <img src="/images/Banner-REA-INAI.png" style="float:left; max-width: 150px; margin-right: 15px" alt=""> -->
            <br>
            <router-link style="color: white; font-size: 15px; font-weight: bold; text-decoration: underline;" to="/avisolegal">Aviso legal</router-link>
            <br>
            <p :style="{ 'color': color || 'white' }" style="font-size: 10px; margin-top: 10px; text-align: center;">El programa POR TI  ofrece servicios complementarios de apoyo sin costo para sus pacientes, diseñados para dar seguimiento al tratamiento diagnosticado por usted como médico tratante. Estos materiales son de carácter informativo únicamente y de ninguna forma reemplazan y/o sustituyen el consejo médico. Los servicios complementarios serán de adopción voluntaria y no están condicionados a la venta, consumo o uso de productos. Usted como médico tratante será en todo momento, quien determine el mejor tratamiento para el paciente.</p>
        </template>

        <!-- PATIENTS and HOME or SCHEDULE -->
        <p v-if="isPatient && this.$route.fullPath.includes('/dashboard') || this.$route.fullPath.includes('/schedule') && !this.$route.fullPath.includes('/schedule/nurse')" :style="{ 'color': color || 'white' }" style="font-size: 10px; margin-top: 10px">Roche se reserva el derecho de modicar o cancelar en cualquier momento los apoyos, informando con la debida anticipación a los participantes del mismo.</p>

        <!-- PATIENTS SCHEDULE ON HEMOFILIA -->
        <template v-if="this.user &&  this.user.pathology">
            <p v-if="isPatient && this.$route.fullPath.includes('/schedule/nurse') && this.user.pathology.name.includes('Hemofilia')" :style="{ 'color': color || 'white' }" style="font-size: 10px; margin-top: 10px">Roche se reserva el derecho de modicar o cancelar en cualquier momento los apoyos, informando con la debida anticipación a los participantes del mismo.</p>
        </template>

        <!-- DOCTORS and HOME or SERVICES or MY PATIENTS -->
        <p v-if="isDoctor && this.$route.fullPath.includes('/dashboard') || this.$route.fullPath.includes('/doctor/services') || this.$route.fullPath.includes('/doctor/my-patient')" :style="{ 'color': color || 'white' }" style="font-size: 10px; margin-top: 10px">Roche se reserva el derecho de modificar o cancelar en cualquier momento los apoyos, informando con la debida anticipación a los participantes del mismo.</p>

        <!-- PATIENTS and RED DE ENFERMERAS - ARTRITIS/ANEMIA -->
        <template v-if="this.user && this.user.pathology">
            <template v-if="isPatient && this.$route.fullPath.includes('/schedule/nurse')">
                <template v-if="this.user.pathology.name.includes('Artritis') || this.user.pathology.name.includes('Anemia')">
                    <p :style="{ 'color': color || 'white' }" style="font-size: 10px; margin-top: 10px">La frecuencia y restricciones serán detalladas por el equipo del programa POR TI al contactarse contigo.</p>
                </template>
            </template>
        </template>


        <!-- GENERAL -->
        <!-- <p :style="{ 'color': color || 'white' }" style='font-size: 10px; margin: 10px 0; font-weight: bold; text-align: center; color: white;'><br><b>El programa de apoyo a pacientes POR TI no reemplaza y/o sustituye el seguimiento con su médico. Ante cualquier duda consulte a su médico.</b></p> -->
        <p :style="{ 'color': color || 'white' }" style="margin: 10px 0;">Derechos Reservados, Productos Roche, S.A. de C.V.  México, {{ new Date().getFullYear() }}. Roche es una marca registrada propiedad de F. Hoffman-La Roche Ltd. Prohibida su reproducción parcial o total.</p>

        <template>
            <p :style="{ 'color': color || 'white' }">No. aviso COFEPRIS para Profesionales de la Salud: 203300202C4009</p>
            <p :style="{ 'color': color || 'white' }">Número de Veeva: M-MX-00001237</p>
        </template>
    </div>
</template>

<script>
    /* global _ */
    import { mapGetters } from "vuex"

    export default {
        props: {
            color: String,
            pathology: { required: false, default: false }
        },
        computed: {
            ...mapGetters([
                "user",
            ]),
            isDoctor(){
                if( this.user == null ) return false

                let ri = _.findIndex(this.user.roles, (r) => {
                    return r.name == "Doctor"
                })
                return ri != -1 ? true : false
            },
            isPatient(){
                if( this.user == null ) return false

                let ri = _.findIndex(this.user.roles, (r) => {
                    return r.name == "Paciente"
                })
                return ri != -1 ? true : false
            }
        }
    }
</script>

<style lang="sass">
    .footer
        // position: absolute
        // bottom: 0
        // right: 0
        // left: 0
        width: 100%
        padding: 10px 25px
        font-size: 8px
        color: white
        text-align: center
</style>