<template>
    <Modal class="usgs-modal" v-model="show" title="" :footer-hide="true">
        <div style="margin: 2em 2em 0em 2em">
            <center>
                <h2 style="text-decoration: underline;">DIRECTORIO USG ROCHE</h2>
            </center>
        </div>
        <div style="background-color: transparent !important; margin-top: 10px" class="patientes-extra-s">
            <p style="font-size: 0.9em; text-align: center;">Horario de atención: <br>Lunes a Viernes, de 9:00 a.m. a 6:30 p.m., <br>Sábado de 8:00 a 11:00 a.m.</p>
        </div>
        <hr>
        <div style="height: 25px"></div>
        
        <div style="margin: 0 1em">
            <Input prefix="ios-search" v-model="search" placeholder="Búsqueda por nombre o estado" style="width: 100%" />
            <!-- <div style="height: 15px"></div> -->

            <div v-for="usg in USGs" :key="'usg_list_'+usg.id" class="patientes-extra-s" style="color: white !important; padding: 1em;">
                <p style="margin-bottom: 5px">
                    <span style="font-weight: bold">Médico asociado: </span>
                    {{ usg.doctor }}
                </p>
                <div style="margin-bottom: 5px">
                    <span style="font-weight: bold">Dirección: </span>
                    <div v-html="usg.address"></div>
                </div>
                <p style="margin-bottom: 5px">
                    <span style="font-weight: bold">Estado: </span>
                    {{ usg.state }}
                </p>
                <p>
                    <span style="font-weight: bold">Observaciones: </span>
                    {{ usg.obs }}
                </p>

                <div style="margin: 1em 0">
                    <Button style="margin-top: 10px; font-size: 1em" long size="large" type="primary" @click="emit(usg)">Seleccionar</Button>
                </div>
            </div>
        </div>

        <template v-if="USGs.length == 0">
            <div style="margin: 2em">
                <center>
                    <h3>No hay USGs que mostrar</h3>
                </center>
            </div>
        </template>
    </Modal>
</template>

<script>
    export default {
        data(){
            return {
                usgs: [],
                show: false,
                search: '',
            }
        },
        computed: {
            USGs(){
                if( this.search == '' ) return this.usgs
                else {
                    return window._.filter(this.usgs, (usg) => {
                        return usg.doctor.toUpperCase().includes(this.search.toUpperCase()) || usg.state.toUpperCase().includes(this.search.toUpperCase())
                    })
                }
            }
        },
        methods: {
            getUSGs(){
                window.axios.post(this.$root.API_URL+ `/content/get-usgs`)
                .then(response => {
                    if(response.data.success){
                        this.usgs = response.data.usgs
                    }
                })
            },
            emit(usg){
                this.$emit('selected', usg)
                this.show = false
            }
        },
        mounted(){
            
            this.getUSGs()

            window.EventBus.$on('doctor-service--show-usg-directory', () => {
                this.show = true
            })

        }
    }
</script>