<template>
    <Grid/>
</template>

<script>
    import Grid from "./../components/Landing/Grid"

    export default {
        components: {
            Grid,
        } 
    }
</script>