<template>
    <div style="background-color: white; color: #006fb9;">
        <ServiceContent footer_color="#006fb9">
            <div slot="header" class="image-header">
                <img style="width: 100%;" src="/images/doctor-services/Gestoria-de-Aseguradora.png" alt="">
            </div>
            <p class="service-title">Gestión de aseguradora</p>
            
            <Loading v-if="loading" />
            <template v-else>
                <div class="ptn-dyn-cont">
                    <p v-html="MainDescription"></p>
                </div>
                
                <br>
                <div v-for="file in files" :key="`insurance_files_list_${file.id}`" class="insurance-file--container">
                    <div class="insurance-file--icon">
                        <img style="width: 20px" src="/images/doctor-services/insurance-files/documento.png" alt="">
                    </div>
                    <div class="insurance-file--title">
                        {{ file.name }}
                    </div>
                    <div class="insurance-file--actions">
                        <img @click="download(file)" style="width: 30px" src="/images/doctor-services/insurance-files/descargar.png" alt="">
                        <img @click="share(file)" style="width: 30px" src="/images/doctor-services/insurance-files/compartir.png" alt="">
                    </div>
                </div>
                <br>
                <p class="txt-black txt-center">Tipo de servicio: Base y disponible para todos los pacientes.</p>
            </template>

        </ServiceContent>

        <el-dialog class="doctor-dialog" title="" :visible.sync="show_share_popup" width="90%">
            <h2 style="text-align: center; word-break: break-word; color: white; font-weight: bold;">Compartir información</h2>
            <br>
            <h3 style="text-align: center; word-break: break-word; color: white; font-weight: normal;">Comparta este link dando clic en el botón "Copiar enlace"</h3>
            <br>
            <Input class="share-input" disabled v-model="share_popup_link" size="large" />
            <br><br>
            <Button @click="copyToClipboard" long type="primary">Copiar enlace</Button>

        </el-dialog>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                loading: true,
                files: [],
                share_popup_link: '',
                show_share_popup: false,
            }
        },
        computed: {
            MainDescription(){
                let ti = window._.findIndex(this.texts, text => text.code == 'insurance.mainDescription')
                return ti != -1 ? this.texts[ti].content : ""
            },
        },
        methods: {
            fetchTexts(){
                window.axios.post(this.$root.API_URL+ `/content/dynamic-texts`, { code: 'insurance' })
                .then(response => {
                    if(response.data.success){
                        this.texts = response.data.texts
                        this.getFiles()
                    }
                })
            },
            getFiles(){
                window.axios.post(this.$root.API_URL + '/content/get-insurance-files')
                .then(response => {
                    if( response.data.success ){
                        this.files = response.data.files
                        this.loading = false
                    }
                })
            },
            copyToClipboard(){
                navigator.clipboard.writeText(this.share_popup_link)
                this.$Message.success('Enlace copiado exitosamente');
            },
            share(file){
                this.share_popup_link = file.share_url
                this.show_share_popup = true
            },
            download(file){
                if( file.type == 'extra' ){
                    // window.open(file.download_url, '_blank');
                    const d_link = document.createElement('a');
                    d_link.href = file.download_url;
                    d_link.setAttribute('download', file.name); //or any other extension
                    document.body.appendChild(d_link);
                    d_link.click();
                }
                else {
                    window.axios.post(this.$root.API_URL+ `/content/download-insurance-files/${file.id}`, {}, {
                        responseType: 'blob',
                    })
                    .then( response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download',  file.attachment_name); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(() => {
                        // console.log('error')
                        this.$Message.error('El archivo no pudo ser descargado');
                    })
                }
            }
        },
        mounted(){
            this.fetchTexts()
        }
    }
</script>

<style lang="scss">
    .insurance-file{
        &--container{
            position: relative;
            display: flex;
            margin-bottom: 20px;
        }
        &--icon{
            position: relative;
            margin-top: 3px;
        }
        &--title{
            padding: 0 1em 0 0.5em;
            font-size: 1em;
            flex-grow: 2;
            font-style: italic;
        }
        &--actions{
            display: flex;
            align-items: center;
            position: relative;
            & img{
                margin: 0.5em;
                cursor: pointer;
            }
        }
    }

.share-input.ivu-input-wrapper > .ivu-input{
        background: transparent;
        color: white;
        text-align: center;
        font-style: italic;
        font-size: 1.1em !important;
    }
</style>