<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h1 class="section-title">Nuestro portafolio de soluciones y programas</h1>

            <template v-if="loading">
                <Loading />
            </template>
            <template v-else>

                <div class="program-title">
                    <img src="/images/programs-title.png" alt="">
                    <h1 class="section-title" v-html="$productNameFormat(product.name)"></h1>
                </div>

                <p class="txt-justified" v-html="product.description"></p>
                <br><br>

                <Diagnosis v-if="product.diagnosis" :product="product" :files="files" :download-file-description="DownloadFileDescription"
                           :has-diagnosis-files="HasDiagnosisFiles"
                            :attach-file-description="AttachFileDescription" />

                <Adherence :product="product" :has-adherence-files="HasAdherenceFiles" :files="files"
                           :download-file-description="DownloadFileDescription"
                           :attach-file-description="AttachFileDescription" v-if="product.adherence" />

                <Bonus v-if="product.bonus" :oncopassport="product.oncopassport" :integralpass="product.integralpass" />

                <el-dialog class="doctor-dialog" title="" :visible.sync="show_share_contact_popup" width="90%">
                    <h2 style="text-align: center; word-break: break-word; color: white; font-weight: bold;">Compartir información</h2>
                    <br>
                    <center>
                        <img style="width: 100%; max-width: 850px" src="/images/TarjetaContacto.png" alt="">
                    </center>
                    <br>
                    <Button @click="onShareContactImage" style="margin-bottom: 25px" long type="primary">Copiar enlace</Button>
                    <a class="ivu-btn ivu-btn-primary ivu-btn-long" href="/images/TarjetaContacto.png" download>Descargar</a>

                </el-dialog>

                <el-dialog class="doctor-dialog" title="" :visible.sync="show_request_success_dialog" width="90%">
                    <h2 style="text-align: center; word-break: break-word; color: white; font-weight: normal;">
                        Con el objetivo de brindarle estos servicios en tiempo y forma a su paciente, el call center especializado se
                        pondrá en contacto con él de acuerdo a su seleccion de información.
                    </h2>
                </el-dialog>

            </template>

        </TemplateContent>
    </div>
</template>

<script>
    /* global axios */

    import Bonus from './components/Bonus'
    import Diagnosis from './components/Diagnosis'
    import Adherence from './components/Adherence'

    export default {
        data(){
            return {
                texts: [],
                loading: true,
                product: null,
                files: [],
                show_share_contact_popup: false,
                show_request_success_dialog: false,
            }
        },
        computed: {
            DownloadFileDescription(){
                let ti = window._.findIndex(this.texts, text => text.code === 'programs.download.file')
                return ti != -1 ? this.texts[ti].content : ""
            },
            AttachFileDescription(){
                let ti = window._.findIndex(this.texts, text => text.code === 'programs.attach.file')
                return ti != -1 ? this.texts[ti].content : ""
            },
            HasDiagnosisFiles(){
                return this.files.diagnosis.length > 0 ?? false
            },
            HasAdherenceFiles(){
                return this.files.adherence.length > 0 ?? false
            }
        },
        methods: {
            fetchProduct(slug){
                this.product = null
                this.files = []
                axios.post(this.$root.API_URL+`/content/v2/get-product/${slug}`).then(response => {
                    if(response.data.success){
                        this.product = response.data.product
                        this.files = response.data.files
                        this.loading = false
                    }
                    else {
                        // In case the product is disabled
                        this.$router.replace('/404')
                    }
                })
                .catch(() => {
                    this.$router.replace('/404')
                })
            },
            fetchTexts(){
                window.axios.post(this.$root.API_URL+ `/content/dynamic-texts`, { code: 'programs' })
                    .then(response => {
                        if(response.data.success){
                            this.texts = response.data.texts
                        }
                    })
            },
            onShareContactImage(){
                navigator.clipboard.writeText("https://drive.google.com/file/d/142zqgjoLpniiuetZ2dPK4gBWXNaQtHsU/view?usp=sharing")
                this.$Message.success('Enlace copiado exitosamente');
            },
        },
        mounted() {
            this.fetchTexts()
            this.fetchProduct( this.$route.params.slug )


            window.EventBus.$on('programs-details--show-service-request-popup', () => {
                this.show_request_success_dialog = true
            })

            window.EventBus.$on('programs-details--show-contact-popup', () => {
                this.show_share_contact_popup = true
            })

        },
        components: {
            Bonus,
            Diagnosis,
            Adherence,
        }
    }
</script>