<template>
    <div>
        <p><b>Servicios a los cuales su Paciente tiene acceso:</b></p>
        <ul class="prg-services-list">

            <template v-if="medicine == 'Enspryng'">
                <li>
                    <b>Diagnóstico</b>
                    <p style="margin-top: 10px">Estudios para apoyar en el diagnóstico oportuno (previo al uso de la terapia).</p>
                    <div class="ul-items-childs">
                        <div class="child-item">
                            <div class="child-item title">
                                <p class="title">Pruebas de imagen:</p>
                                <div class="child-subitems">
                                    <div class="child-subitem-itm">
                                        <p>Resonancia magnética: Gadolinio 1 dosis hasta 70 kg</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>Resonancia magnética: Gadolinio 2 dosis a partir de 71 kg</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>RM: Cráneo</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>RM: Nervios ópticos</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>RM: Cervical</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>RM: Dorsal</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="child-item">
                            <div class="child-item title">
                                <p class="title">Pruebas de gabinete:</p>
                                <div class="child-subitems">
                                    <div class="child-subitem-itm">
                                        <p>Pruebas de función hepática</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>Perfil de lípidos</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>Biometría hemática</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>Perfil de hepatitis B</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>Quantiferón</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>Acuaporina 4</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <b>Adherencia</b>
                    <div class="ul-items-childs">
                        <div class="child-item">
                            <div class="child-item title">
                                <p class="title">Pruebas de imagen:</p>
                                <div class="child-subitems">
                                    <div class="child-subitem-itm">
                                        <p>Resonancia magnética: Gadolinio 1 dosis hasta 70 kg</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>Resonancia magnética: Gadolinio 2 dosis a partir de 71 kg</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>RM: Cráneo</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>RM: Nervios ópticos</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>RM: Cervical</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>RM: Dorsal</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="child-item">
                            <div class="child-item title">
                                <p class="title">Pruebas de gabinete:</p>
                                <div class="child-subitems">
                                    <div class="child-subitem-itm">
                                        <p>Pruebas de función hepática</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>Perfil de lípidos</p>
                                    </div>
                                    <div class="child-subitem-itm">
                                        <p>Biometría hemática</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </template>
            <template v-else>
                <DiagnosisList v-if="Diagnosis.length > 0 || medicine == 'Alecensa'" :services="Diagnosis" :product="medicine" />
                
                <AcccessList v-if="Access.length > 0" :services="Access" />
                    
                <AdditionalsList v-if="Additionals.length > 0" :services="Additionals" :product="medicine" />

                <!-- <BaseList v-if="services.base.length > 0" :services="services.base" /> -->
            </template>
                
        </ul>
        <br>

        <!-- Copy for each medicine --->
        <template v-if="medicine == 'Alecensa' ">
            <p>Sector público (previa aprobación de la institución) y privado: disponible con todos los servicios.</p>
        </template>

        <template v-if="medicine == 'Gazyva' ">
            <p>Para los servicios de adherencia, diagnóstico y acceso aplica lo siguiente:</p>
            <p><strong>Sector público (previa aprobación de la institución):</strong> con todos los servicios.</p>
            <p><strong>Sector privado:</strong> con todos los servicios. </p>
        </template>

        <template v-if="medicine == 'Kadcyla' || medicine == 'Perjeta' ">
            <p>Para los servicios de adherencia, diagnóstico y acceso aplica lo siguiente:</p>
            <p><strong>Sector público (previa aprobación de la institución):</strong> todos los servicios con original de obsequio excepto Ecocardiogramas y OncoPassport.</p>
            <p><strong>Sector privado:</strong> todos los servicios excepto original de obsequio y pruebas de Inmunohistoquímica.</p>
        </template>

        <template v-if="medicine == 'Mircera' ">
            <p><strong>Sector privado:</strong> disponible con todos los servicios.</p>
        </template>

        <template v-if="medicine == 'Ocrevus' ">
            <p>Para los servicios de adherencia y diagnóstico aplica lo siguiente:</p>
            <p><strong>Sector privado:</strong> con todos los servicios.</p>
        </template>

        <template v-if="medicine == 'Roactemra' ">
            <p>Para los servicios de adherencia y acceso aplica lo siguiente:</p>
            <p><strong>Sector privado:</strong> disponible con todos los servicios.</p>
        </template>

        <template v-if="medicine == 'Tecentriq (R)' ">
            <p>Para los servicios de adherencia, diagnóstico y acceso aplica lo siguiente:</p>
            <p><strong>Sector público (previa aprobación de la institución):</strong> todos los servicios excepto aplicación en centros de infusión, Inmunohistoquímica para el marcador NTRK y OncoPassport.</p>
            <p><strong>Sector privado:</strong> todos los servicios.</p>
        </template>

        <template v-if="medicine == 'Herceptin' ">
            <p>Para los servicios de diagnóstico aplica lo siguiente:</p>
            <p><strong>Sector público:</strong> Pruebas de inmunohistoquímica</p>
            <p><strong>Sector privado:</strong> OncoPassport.</p>
        </template>

        <br>
        <br>
    </div>
</template>

<script>
    // import BaseList from './components/Base'
    import AcccessList from './components/Access'
    import DiagnosisList from './components/Diagnosis'
    import { mapGetters } from 'vuex'
    import AdditionalsList from './components/Additional'

    export default {
        props: {
            medicine: { required: true, type: String },
        },
        computed: {
            ...mapGetters({
                services: 'patient_services'
            }),
            Base(){
                return window._.filter(this.services.base, a => a.can_be_listed == true )
            },
            Additionals(){
                return window._.filter(this.services.additionals, a => a.can_be_listed == true )
            },
            Diagnosis(){
                return window._.filter(this.services.diagnosis, a => a.can_be_listed == true )
            },
            Access(){
                return window._.filter(this.services.access, a => a.can_be_listed == true )
            }
        },
        components: {
            // BaseList,
            AcccessList,
            DiagnosisList,
            AdditionalsList,
        }
    }
</script>

<style lang="scss">
    div.ul-items-childs{
        padding: 5px 0;
        margin-left: 15px;
        & .child-item{
            position: relative;
            margin-bottom: 5px;
            &::before { 
                content: '';
                position: absolute;
                border-color: white;
                border-style: solid;
                border-width: 1px;
                height: 0.5em;
                border-radius: 5px;
                top: 1.45em;
                left: 0.6em;
                margin-top: -1em;
                width: 0.5em;
            }
            & p {
                margin-left: 35px;
                & p {
                    margin-left: 0 !important;
                }
            }
            /* &.title {
                margin-left: 10px;
                &::before{
                    content: none !important;
                }
                & .title {
                    margin-left: 0px;
                    margin: 10px 0;
                }
            } */
            & .child-subitems{
                margin-left: 30px;
                & .child-subitem-itm{
                    position: relative;
                    margin-bottom: 5px;
                    &::before { 
                        content: '';
                        position: absolute;
                        background-color: white;
                        border-style: solid;
                        border-width: 1px;
                        height: 0.5em;
                        top: 1.45em;
                        left: 0.6em;
                        margin-top: -1em;
                        width: 0.5em;
                    }
                    & p {
                        margin-left: 35px;
                        & p {
                            margin-left: 0 !important;
                        }
                    }
                }
            }
            &.no-list-style{
                &::before{
                    display: none;
                }
                & p {
                    margin-left: 10px !important;
                }
            }
        }
    }
</style>