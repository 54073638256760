<template>
    <TemplateContent>
        <h1 class="section-title">Aviso de Privacidad</h1>
        
        <div class="privacy-content">

            <p><b>Última revisión:</b> 19 de diciembre de 2019</p>
            <p><b>Versión:</b> 04</p>
            <br>

            <p>En cumplimiento a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento, PUNTO PEN S.A DE C.V., en lo sucesivo PEN, con domicilio en Versalles No. 19 Piso 4 Col. Juárez Delegación Cuauhtémoc Código Postal 06600, Ciudad de México, hace de su conocimiento nuestra política sobre el manejo de privacidad de datos personales y datos personales sensibles, como responsables de recabar, registrar, almacenar, procesar, revisar, modificar, actualizar, administrar, compartir, resguardar, analizar, corregir  y  en su caso, eliminar la información personal, recopilada por el personal de “PEN”.</p>

            <p class="subtitle">Tipo de información que se recopila.</p>
            <p>El personal de “PEN”, recaba información y datos que pueden identificarle de manera razonable, existiendo diferentes categorías de datos que se enuncian de forma enunciativa más no limitativa y de los cuales Usted está de acuerdo en proporcionar:</p>
            <p>A) Datos de Identificación: Nombre o nombres y apellidos, domicilio sea particular o fiscal, teléfono fijo y/o de celular, correo electrónico, firma, fecha de nacimiento, edad.</p>
            <p>B) Datos Sensibles.</p>

            <p class="subtitle">Datos Personales Sensibles</p>
            <p>Le informamos que, para cumplir con las finalidades previstas en este aviso de privacidad, serán recabados y tratados datos personales sensibles, como aquellos que refieren a aspectos sensibles como: síntomas, antecedentes de salud, recetas, diagnóstico de la enfermedad que padece, información de su póliza de seguro de gastos médicos mayores</p>
            <p>Nos comprometemos a que los mismos serán tratados bajo las más estrictas medidas de seguridad que garanticen su confidencialidad. La información que se solicita y se almacena en la base de datos que tiene esta empresa y la información que usted proporcione deberá ser veraz y completa y responderá en todo momento por los datos proporcionados y en ningún caso “PEN” será responsable por la falsedad de los mismos.</p>

            <p class="subtitle">Finalidades relacionadas al recabo de datos personales y datos personales sensibles:</p>
            <p>Sus datos personales y datos personales sensibles, serán utilizados para las siguientes finalidades:</p>
            <p>1)	Afiliación al programa y prestación de servicios de atención médica, gestión de citas para los servicios del programa, Servicios de infusión en centros con convenio, aplicación de medicamento, atención de enfermería, planes de adherencia al tratamiento, análisis de laboratorio, radiología e imagen, valoración psicológica y nutricional y demás fines relacionados con servicios de atención a la salud.</p>
            <p>2)	Conservación de registros para seguimiento de la atención médica, farmacovigilancia y tratamiento de servicios en el futuro y en general para dar seguimiento a cualquier relación contractual.
            </p>

            <p class="subtitle">Transferencia de datos personales y datos personales sensibles:</p>
            <p>En caso de ser aplicable, sus datos personales y datos personales sensibles podrán ser transferidos a la aseguradora con quien tenga contratado una póliza de seguro de gastos médicos, así como al tercero que lleve a cabo la prestación de los servicios mencionados en la finalidad 1) anterior, conservando  en  todo  momento  PEN  la  responsabilidad  y carácter de responsable del tratamiento de sus datos personales.</p>

            <p class="subtitle">Derechos de los Titulares de Datos Personales.</p>
            <p>Usted, como Titular de sus Datos Personales, o en su caso, su representante legal, podrá(n) ejercer los derechos de Acceso, Rectificación, Cancelación y Oposición al tratamiento, manejo e intercambio de su información personal; que por sus iniciales, son conocidos comúnmente como Derechos ARCO.</p>

            <p class="subtitle">Derecho de ACCESO:</p>
            <p>Usted, tiene derecho a obtener sus datos personales de la base de datos que se maneja en esta empresa, así como la información relativa al tratamiento de los mismos.</p>

            <p class="subtitle">Derecho de RECTIFICACIÓN:</p>
            <p>Es el derecho que le otorga la Ley para que se corrijan sus datos personales en nuestra base de datos. Aplica cuando los datos son incorrectos, imprecisos, incompletos o están desactualizados.</p>

            <p class="subtitle">Derecho de CANCELACIÓN:</p>
            <p>Es la facultad que le otorga la Ley para que solicite la cancelación de sus datos en nuestra base de datos. Esta solicitud es procedente cuando su información personal ya no es necesaria para las actividades relacionadas por las cuales se recopiló dicha información.</p>

            <p class="subtitle">Derecho de OPOSICIÓN:</p>
            <p>Consiste en la facultad que tiene para solicitar a “PEN” al correo electrónico privacidad@pen.com.mx que se abstenga de tratar sus datos personales en determinadas situaciones, por ejemplo, para fines publicitarios.</p>

            <p class="subtitle">Mecanismo para el Tratamiento de Datos Personales</p>
            <p>Todo el tratamiento de sus datos personales estará sujeto al consentimiento expreso que nos manifieste: verbalmente, por escrito o por medios electrónicos, ópticos o por cualquier otra tecnología. Es importante tener en cuenta que el ejercicio de cada uno de estos derechos es independiente de cualquiera de los otros Derechos ARCO, es decir, no es necesario agotar uno para ejercer alguno de los otros tres.</p>
            <p>El mecanismo que se ha implementado para el ejercicio de sus derechos ARCO será a través de la presentación de la solicitud respectiva a:</p>
            <br>
            <p><i>Coordinación del Comité de Seguridad de Información Responsable de Datos Personales</i></p>
            <p><i><b>Punto Pen, S.A. de C.V.</b></i></p>
            <p><i>Versalles 19 Colonia Juárez, alcaldía Cuauhtémoc, C.P. 06600, Ciudad de México.</i></p>
            <p>Correo electrónico: <a style="color: white; text-decoration: underline;" href="mailto:privacidad@pen.com.mx">privacidad@pen.com.mx</a></p>
            <p>Para ejercer cualquiera de sus Derechos ARCO, Usted podrá utilizar el mecanismo que se ha implementado, proporcionando los siguientes datos:</p>
            <br>
            <ul>
                <li>Nombre y correo electrónico y/o domicilio para recibir respuestas y comunicados.</li>
                <li>Precisar los datos personales a los cuales quieres tener acceso, o que desea sean rectificados, o que deben ser cancelados, o a que datos específicos se opone al tratamiento.</li>
                <li>Cualquier otro elemento o documento que facilite la localización de sus datos personales, como fecha en los proporcionó.  (NOTA:  La  información  de  este  punto  no es obligatoria, sin embargo, nos es útil para darle una pronta y mejor respuesta sobre la información que solicita).</li>
                <li>•Exponer la	razón o	motivo del porqué solicita el ejercicio	de alguno de sus Derechos ARCO.</li>
                <li>Anexar copia de Identificación Oficial del paciente, o en su caso, del titular de la patria potestad del paciente.</li>
                <li>En caso de no ser el titular quien  presente  la solicitud, será necesario presentar los siguientes documentos:</li>
                <li>El documento que acredite la existencia de la representación, es decir, instrumento público o carta poder firmada ante dos testigos, junto con identificación del titular y del representante (credencial del IFE, pasaporte vigente, cédula profesional o documento migratorio).</li>
                <li>Para el caso de menores de edad, los documentos para acreditar la representación legal de este, serán: acta de nacimiento y credencial con fotografía del menor (la otorgada por la institución académica a donde acuda), Pasaporte vigente, o cualquier otra que cuente con fotografía del mismo, además que al acudir a presentar los documentos para su cotejo respectivo, la firma del documento que se anexará a la solicitud como “Acreditación de representación legal” en  el cual bajo protesta de decir verdad, se manifiesta ser el responsable del menor;</li>
                <li>Para el caso de interdictos, los documentos para acreditar la representación legal serán: acta de interdicto (resolución judicial) y credencial con fotografía de la persona que ostente esta situación jurídica, ya sea, credencial del INE, pasaporte vigente, cédula profesional o documento migratorio.</li>
            </ul>
            <br>
            <p>Es importante conservar la constancia de la solicitud que presentó para cualquier aclaración.</p>
            <p>Si Usted no desea ejercer cualquiera de sus Derechos ARCO a través del mecanismo propuesto, podrá realizarla por medio de escrito libre presentado en las oficinas de esta empresa, proporcionando la siguiente información:</p>
            <br>
            <ul>
                <li>Nombre y correo electrónico y/o domicilio para recibir respuestas y comunicados.</li>
                <li>Precisar los datos personales a los cuales quieres tener acceso, o que desea sean rectificados, o que deben ser cancelados, o a que datos específicos se opone al tratamiento.</li>
                <li>Cualquier otro elemento o documento que facilite la localización de sus datos personales, como fecha en los proporcionó. (NOTA: La información de este punto no es obligatoria, sin embargo, nos es útil para darle una pronta    y   mejor   respuesta   sobre   la   información   que  solicita).</li>
                <li>Exponer   la   razón    o    motivo    del    porqué    solicita   el    ejercicio    de    alguno    de    sus    Derechos    ARCO.</li>
                <li>Anexaral al escrito copia de Identificación Oficial del paciente, o en su caso, del titular de la patria potestad del paciente. En caso de no ser el titular quien presente la solicitud, será necesario presentar los siguientes documentos:</li>
                <li>•El documento que acredite la existencia de la representación, es decir, instrumento público o carta poder firmada ante dos testigos, junto con identificación del titular y del representante (credencial del IFE, pasaporte vigente, cédula profesional o documento migratorio).</li>
                <li>Para el caso de menores de edad, los documentos para acreditar la representación legal de este, serán: acta de nacimiento y credencial con fotografía del menor (la otorgada por la institución académica a donde acuda), Pasaporte vigente, o cualquier otra que cuente con fotografía del mismo, además que al acudir a presentar los documentos para su cotejo respectivo, la firma del documento que se anexará a la solicitud como “Acreditación de representación legal” en  el cual bajo protesta de decir verdad, se manifiesta ser el responsable del menor;</li>
                <li>Para el caso de interdictos, los documentos para acreditar la representación legal serán: acta de interdicto (resolución judicial) y credencial con fotografía de la persona que ostente esta situación jurídica, ya sea, credencial del IFE, pasaporte vigente, cédula profesional o documento migratorio.</li>
            </ul>

            <p class="subtitle">Tiempo de respuesta del ejercicio de sus derechos ARCO</p>
            <p>Una vez presentada la solicitud, “PEN” cuenta con un plazo máximo de 20 días hábiles para responder si su solicitud es o no procedente, y en caso de ser procedente 15 días hábiles más para hacer efectivo el ejercicio del o los derechos que solicitó, en caso de que resulten procedentes. Los plazos podrán ser ampliados por una sola vez y por un periodo igual cuando existan hechos que lo justifiquen.</p>
            <p>El ejercicio que Usted puede ejercer sobre sus derechos ARCO es gratuito, y únicamente deberá cubrir el costo por reproducción en copias u otros formatos y en su caso de los gastos de envío. En caso de que volviera a ejercer cualquiera de los derechos en un plazo menor a doce meses, el costo no podrá exceder del equivalente a tres días de salario mínimo vigente en la Ciudad de México. Si se realizaran modificaciones sustanciales al Aviso de Privacidad y surgen situaciones que ameriten nuevamente el ejercicio de un mismo derecho en menos de 12 meses, no habrá costo.</p>

            <p class="subtitle">Transferencia de datos personales:</p>
            <p><b>“PEN”</b>, podrá dar a conocer y transferir sus datos personales dentro y fuera del país, a cualquier institución de la salud, así como a algún tercero que le provean de servicios necesarios para su debida atención médica. En dichos supuestos, le informamos que “PEN” adoptará las medidas necesarias para que las personas que tengan acceso a sus datos personales cumplan con nuestra política de privacidad, así como con los principios de protección de datos personales establecidos en la Ley antes citada.</p>
            <p><b>“PEN”</b> se compromete a no transferir su información personal a terceros sin su consentimiento, salvo por las siguientes excepciones:</p>
            <ul>
                <li>Cuando la transferencia sea necesaria para la prevención o el diagnóstico médico, la prestación de asistencia sanitaria, tratamiento  médico  o  la  gestión  de  servicios  sanitarios</li>
                <li>Cuando  la  transferencia   sea   legalmente   exigida para    la    salvaguarda    de    un    interés    público    o para     la     procuración     o     administración     de justicia.</li>
                <li>Cuando la transferencia sea precisa para el reconocimiento, ejercicio  o  defensa  de  un  derecho  en  proceso  judicial</li>
                <li>Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el responsable y el titular, sea el caso de las Aseguradoras</li>
            </ul>

            <p class="subtitle">Revocación del Consentimiento:</p>
            <p>En todo momento, Usted puede revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales, o ejercer sus derechos ARCO, para ello es necesario que presente por escrito su petición al correo privacidad@pen. com.mx o las instalaciones de PEN. La obligación de acceso a la información, se dará por cumplida cuando se pongan a su disposición como titular sus datos personales; o bien, mediante la expedición de copias simples, documentos electrónicos o cualquier otro medio que “PEN” le provea dicha información.</p>
            <p>En el caso de que el titular que solicite el acceso, a los datos, sea una persona que presume es el responsable y ésta resulta no serlo, bastará con que así se le indique al titular por escrito o por correo electrónico, para tener por cumplida la solicitud.</p>
            <p><b>“PEN”</b> podrá negar el acceso a los datos personales, la rectificación, cancelación o concesión de la oposición al tratamiento de los mismos, en los siguientes supuestos</p>
            <ul>
                <li>Cuando el solicitante no sea el titular de los datos personales, o   el   representante   no   esté   debidamente   acreditado</li>
                <li>Cuando   los   datos   del   paciente    realizan    la   solicitud, no     se     encuentren     en     nuestra     base     sus     datos.</li>
                <li>Cuando    se   afecten    datos    personales    de   un  tercero</li>
                <li>Cuando   exista     algún     impedimento     legal     o resolución     de     autoridad     competente     que      restrinja el     ejercicio     de     alguno     de     los     derechos     ARCO</li>
                <li>Cuando la solicitud de que se  trate  (rectificación,  cancelación u oposición) haya sido previamente realizada. “PEN” limitará el uso de los datos personales y no estará obligada a cancelar los mismos de nuestra base de datos cuando:</li>
            </ul>
            <ol type="i">
                <li>Deban ser tratados por disposición legal;</li>
                <li>Sean necesarios para proteger los intereses jurídicamente tutelados del titular;</li>
                <li>Sean     necesarios     para      realizar      una   acción en función del interés público;</li>
                <li>Sean   necesarios   para   cumplir   con   una obligación legalmente adquirida por el titular, o</li>
                <li>Sean objeto de tratamiento para la prevención o el diagnóstico médico o la gestión de servicios de salud; siempre que dicho tratamiento se realice por un profesional de la salud sujeto a un deber de secreto.</li>
            </ol>
            <br>

            <p class="subtitle">Cambios al Aviso de Privacidad</p>
            <p>El responsable se reserva el derecho de actualizar periódicamente el presente Aviso para reflejar los cambios de nuestras prácticas de información. En caso de actualización, la misma se dará a conocer a través del portal de internet </p>
            <a style="color: white; text-decoration: underline; font-size: 0.8em;" href="www.pen.com.mx/privacidadporti01">www.pen.com.mx/privacidadporti01</a>
            <p>En caso de que el Paciente que se inscribirá al Programa que se describe en el presente Aviso de Privacidad, sea menor de edad o se encuentre sujeto a algún tipo de tutela o interdicción, la persona que lo registra y autoriza el tratamiento de los datos personales y datos personales sensibles del paciente, deberá acreditar que cuenta con la representación legal suficiente del paciente, haciendo llegar a PEN la siguiente documentación.</p>
            <ul>
                <li>Menores de edad: Acta de nacimiento y algún documento que identifique al menor (credencial de escuela, pasaporte, CURP, etc); e identificación de ambos padres.</li>
                <li>Sujetos a tutela: Acta de nacimiento y algún documento que identifique al menor (credencial de escuela, pasaporte, CURP etc); identificación del tutor, y resolución judicial en la que se otorgue la tutela del paciente.</li>
            </ul>
            <br>
            <p>Mediante su firma, Usted autoriza a PUNTO PEN, S.A. de C.V., con domicilio en Versalles No. 19 Piso 4 Col. Juárez Delegación  Cuauhtémoc  Código  Postal  06600,   Ciudad de México, al tratamiento de los datos personales y datos personales sensibles del  paciente</p> <p>_______________________________________________________________,</p>   	
            <p>para las finalidades descritas en el presente Aviso de Privacidad. La información y documentación que Usted está presentando, será tratada con la más alta confidencialidad, y se utilizará única y exclusivamente para la finalidad de identificarlo a Usted y al paciente en relación con el Programa.
            </p>
            <br>

            <p>Nombre del padre, madre o tutor</p>
            <p>_______________________________________________________________</p>
            <p>Fecha</p>
            <p>_______________________________________________________________</p>
            <p>Firma del padre, madre o tutor</p>
            <p>_______________________________________________________________</p>
            <br>
            <p><b>Consentimiento:</b></p>
            <p>Nombre y Firma del paciente</p>
            <p>_______________________________________________________________</p>
            <p>Fecha</p>
            <p>_______________________________________________________________</p>

            <br>
            <img src="/images/Banner-REA-INAI.png" style="width: 100%; max-width: 350px" alt="">
            <br>
            <Button size="large" style="margin-top: 15px" long type="primary" @click="downloadFile">Descarga Aviso de Privacidad</Button>
            <br><br><br><br>
        </div>
    </TemplateContent>
</template>

<script>
    export default {
        methods: {
            downloadFile(){
                window.open('/files/aviso_privacidad_2019_v4.docx', '_blank')
            }
        }
    }
</script>

<style lang="sass">
    .privacy-content 
        & > p
            text-align: justify
            font-size: 0.8em
            &.subtitle
                font-weight: bold
                font-size: 0.9em
                text-align: center
                margin: 15px 0
        & ul, ol
            list-style-position: inside
            & li
                font-size: 0.8em
                margin: 10px 0
</style>