<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h1 class="section-title">Seguimiento de mis Pacientes</h1>
            
            <template v-if="loading">
                <Loading/>
            </template>
            <template v-else>
                <template v-if="patients.length > 0">
                    <Form ref="patientForm" :model="patient" :rules="ruleInline">
                        <FormItem prop="name">
                            <p class="form-label">Nombre de mi Paciente</p>
                            <AutoComplete size="large" placement="bottom-end" v-model="patient.name" :data="patients_name" :filter-method="filterPatients" placeholder="" style="width:100%">
                                <Option v-for="item in FilteredPatients" :value="item" :key="'patient_'+item">{{ item }}</Option>
                            </AutoComplete>
                        </FormItem>
                    </Form>
                    <br>
                    <Button @click="watchDetails" long type="primary">Continuar</Button>
                </template>
                <template v-else>
                    <Empty icon="md-person" title="Parece que no tiene ningún paciente asociado"/>
                </template>
            </template>
        </TemplateContent>
    </div>
</template>

<script>
    /* global _, axios */
    export default {
        data(){
            return {
                patient: {
                    id: "",
                    name: "",
                },
                loading: true,
                patients: [],
                medicine: "",
                medicines: [
                    "Alecensa",
                    "Avastin",
                    "Erivedge",
                    "Gazyva",
                    "Hemlibra",
                    "Herceptin",
                    "Kadcyla",
                    "Mabthera",
                    "Mircera",
                    "Ocrevus",
                    "Perjeta",
                    "Pulmozyme",
                    "Roactemra",
                    "Tarceva",
                    "Tecentriq",
                    "Xovluza",
                    "Zelboraf",
                ],
                patients_name: [],
                ruleInline: {
                    name: [{ required: true, message: 'Nombre es requerido', trigger: 'change' }],
                }
            }
        },
        computed: {
            FilteredPatients(){
                if(this.patient.name == ""){
                    return this.patients_name
                }
                else {
                    return _.filter(this.patients_name, (patient) => {
                        return patient.toUpperCase().indexOf(this.patient.name.toUpperCase) !== -1;
                    }).slice(0, 5)
                }
            },
        },
        methods: {
            getPatients(){
                axios.post(this.$root.API_URL+"/doctors/get-patients").then(response => {
                    if(response.data.success){
                        this.patients = response.data.patients
                        _.each(response.data.patients, (p) => {
                            this.patients_name.push(p.name)
                        })
                        this.loading = false
                    }
                })
            },
            filterPatients (value, option) {
                return option.toUpperCase().indexOf(value.toUpperCase()) !== -1
            },
            getPatientSelectedId(){
                let pi = _.findIndex(this.patients, (patient) => {
                    return patient.name == this.patient.name
                })
                return pi != -1 ? this.patients[pi].id : -1
            },
            watchDetails(){
                this.$refs['patientForm'].validate().then(valid => {
                    if(valid){
                        let id = this.getPatientSelectedId()
                        if(id != -1){
                            this.$store.dispatch("SET_KEEP_SERVICES_ALIVE", false)
                            this.$router.push(`/doctor/my-patient/${id}`)
                        } 
                        else {
                            this.$Message.error({
                                content: "Por favor, selecciona el Paciente del listado..",
                                duration: 6,
                            })
                        }
                    }
                })
            },
        },
        mounted(){
            this.getPatients()
        }
    }
</script>