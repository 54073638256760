<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h2>Programa de Diagnóstico de Ultrasonidos</h2>
            <template v-if="loading">
                <Loading/>
            </template>
            <template v-else>
                <i-form class="doctor-service-form" ref="form" :model="form" :rules="ruleInline" inline >
                    <br>
                    <h3>Datos del Médico</h3>
                    <br>
                    <div id="doctor-form-part">
                        <!-- Doctor name -->
                        <FormItem label="Nombre completo:" prop="doctor_name">
                            <i-input type="text" v-model="form.doctor_name" disabled placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Doctor Professional ID -->
                        <FormItem label="Cédula profesional:"  prop="doctor_professional_id">
                            <i-input type="text" v-model="form.doctor_professional_id" placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Doctor phone -->
                        <FormItem label="Teléfono:"  prop="doctor_phone">
                            <i-input ref="doctor_phone" type="number" v-model="form.doctor_phone" placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Doctor phone -->
                        <FormItem label="E-mail:"  prop="doctor_email">
                            <i-input type="text" v-model="form.doctor_email" disabled placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Doctor Hospital -->
                        <FormItem style="margin-top: -5px" label="Hospital en el que labora:" class="block"  prop="doctor_hospital">
                            <i-input type="text" v-model="form.doctor_hospital" placeholder="" style="width: 100%"></i-input>
                        </FormItem>
                        <hr>
                        <br>
                    </div>
                    <h3>Datos del Paciente</h3>
                    <p style="margin-top: 10px; font-size: 0.9em">Estimado médico le agradecemos proporcionar la información de su paciente para coordinar la realización de este estudio. </p>
                    <br>

                    <div id="patient-form-part">
                        <!-- Patient name initials -->
                        <Row :gutter="16">
                            <i-col :xs="5" :sm="5" :md="3" :lg="2">
                                <FormItem label="Iniciales" ></FormItem>
                            </i-col>
                            <i-col :xs="6" :sm="6" :md="4" :lg="4">
                                <FormItem label="" prop="patient_initials_first_last_name" class="with-footer-legend">
                                    <i-input type="text" v-model="form.patient_initials_first_last_name" placeholder="" style="width: 100%"></i-input>
                                    <p class="form-footer-legend center">Apellido paterno</p>
                                </FormItem>
                            </i-col>
                            <i-col :xs="6" :sm="6" :md="4" :lg="4">
                                <FormItem label="" prop="patient_initials_second_last_name" class="with-footer-legend">
                                    <i-input type="text" v-model="form.patient_initials_second_last_name" placeholder="" style="width: 100%"></i-input>
                                    <p class="form-footer-legend center">Apellido materno</p>
                                </FormItem>
                            </i-col>
                            <i-col :xs="6" :sm="6" :md="4" :lg="4">
                                <FormItem label="" prop="patient_initials_name" class="with-footer-legend">
                                    <i-input type="text" v-model="form.patient_initials_name" placeholder="" style="width: 100%"></i-input>
                                    <p class="form-footer-legend center">Nombre(s)</p>
                                </FormItem>
                            </i-col>
                        </Row>
                        
                        <!-- Patient name -->
                        <FormItem label="Nombre completo:"  prop="patient_name">
                            <i-input type="text" v-model="form.patient_name" disabled placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Patient phone 1 -->
                        <FormItem label="Teléfono de contacto 1:"  prop="patient_phone_1">
                            <i-input type="text" v-model="form.patient_phone_1" placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Patient phone 2 -->
                        <FormItem label="Teléfono de contacto 2:"  prop="patient_phone_2">
                            <i-input type="text" v-model="form.patient_phone_2" placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Patient email -->
                        <FormItem label="E-mail de contacto:"  prop="patient_email">
                            <i-input type="text" v-model="form.patient_email" disabled placeholder="" style="width: 100%"></i-input>
                        </FormItem>
                        <div style="height: 5px;"></div>

                        <!-- Patient gender -->
                        <Row :gutter="16">
                            <i-col :xs="5" :sm="5" :md="3" :lg="2">
                                <FormItem label="Género" ></FormItem>
                            </i-col>
                            <i-col span="12">
                                <FormRadioGroup @callback="validateGenderRadio">
                                    <FormRadio v-model="form.patient_gender" mvalue="F" label="F" />
                                    <FormRadio v-model="form.patient_gender" mvalue="M" label="M" />
                                </FormRadioGroup>
                                <div style="margin-top: -5px" class="radio-group-error-text" v-if="radio_validation.gender == false">Requerido</div>
                            </i-col>
                        </Row>
                        <div style="height: 5px;"></div>

                        <!-- Patient birthdate -->
                        <Row :gutter="16">
                            <i-col :xs="11" :sm="11" :md="6" :lg="4">
                                <FormItem label="Fecha de nacimiento"></FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem label="" prop="patient_birth_day" class="with-footer-legend">
                                    <InputNumber :max="31" v-model="form.patient_birth_day" :formatter="formatWithZero" placeholder="DD" style="width: 100%"></InputNumber>
                                    <p class="form-footer-legend center">Día</p>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem label="" prop="patient_birth_month" class="with-footer-legend">
                                    <InputNumber :max="12" v-model="form.patient_birth_month" :formatter="formatWithZero" placeholder="MM" style="width: 100%"></InputNumber>
                                    <p class="form-footer-legend center">Mes</p>
                                </FormItem>
                            </i-col>
                            <i-col span="5">
                                <FormItem label="" prop="patient_birth_year" class="with-footer-legend">
                                    <i-input type="text" v-model="form.patient_birth_year" placeholder="AAAA" style="width: 100%"></i-input>
                                    <p class="form-footer-legend center">Año</p>
                                </FormItem>
                            </i-col>
                        </Row>
                        <div style="height: 5px;"></div>

                        <!-- <Row :gutter="16">
                            <i-col span="10"> -->
                                <!-- Patient weight -->
                                <!-- <Row :gutter="16">
                                    <i-col span="8">
                                        <FormItem label="Peso:"></FormItem>
                                    </i-col>
                                    <i-col span="16">
                                        <FormItem label=""  prop="patient_weight">
                                            <i-input type="text" v-model="form.patient_weight" placeholder="" style="width: 100%"></i-input>
                                        </FormItem>
                                    </i-col>
                                </Row> -->
                            <!-- </i-col>
                            <i-col span="14"> -->
                                <!-- Patient height -->
                                <!-- <Row :gutter="16">
                                    <i-col span="8">
                                        <FormItem label="Estatura:"></FormItem>
                                    </i-col>
                                    <i-col span="8">
                                        <FormItem label=""  prop="patient_height_m" class="with-footer-legend">
                                            <i-input type="text" v-model="form.patient_height_m" placeholder="" style="width: 100%"></i-input>
                                            <p class="form-footer-legend center">m</p>
                                        </FormItem>

                                    </i-col>
                                    <i-col span="8">
                                        <FormItem label=""  prop="patient_height_cm" class="with-footer-legend">
                                            <i-input type="text" v-model="form.patient_height_cm" placeholder="" style="width: 100%"></i-input>
                                            <p class="form-footer-legend center">cm</p>
                                        </FormItem>
                                    </i-col>
                                </Row> -->
                            <!-- </i-col>
                        </Row> -->

                        <!-- Patient city -->
                        <!-- <FormItem label="Ciudad:"  prop="patient_city">
                            <i-input type="text" v-model="form.patient_city" placeholder="" style="width: 100%"></i-input>
                        </FormItem> -->

                        <!-- Patient state -->
                        <!-- <FormItem label="Estado:"  prop="patient_state">
                            <i-input type="text" v-model="form.patient_state" placeholder="" style="width: 100%"></i-input>
                        </FormItem> -->

                        <!-- Patient contacto -->
                        <!-- <FormItem label="Contacto/Tutor:"  prop="patient_contact">
                            <i-input type="text" v-model="form.patient_contact" placeholder="" style="width: 100%"></i-input>
                        </FormItem> -->

                        <!-- Patient hemophilia type -->
                        <Row>
                            <i-col :xs="9" :sm="9" :md="6" :lg="4">
                                <FormItem label="Tipo de hemofilia" ></FormItem>
                            </i-col>
                            <i-col :xs="15" :sm="15" :md="5" :lg="5">
                                <FormRadio v-model="form.patient_hemophilia_type" mvalue="Grave (<1%)" label="Grave (<1%)" label_position="right" @callback="validateHemophiliaType" />
                            </i-col>
                            <i-col :xs="13" :sm="13" :md="7" :lg="7">
                                <FormRadio v-model="form.patient_hemophilia_type" mvalue="Moderada (>1% a <5%)" label="Moderada (>1% a <5%)" label_position="right" @callback="validateHemophiliaType" />
                            </i-col>
                            <i-col :xs="10" :sm="10" :md="5" :lg="5" offset="1">
                                <FormRadio v-model="form.patient_hemophilia_type" mvalue="Leve (>5% y <40%)" label="Leve (>5% y <40%)" label_position="right" @callback="validateHemophiliaType" />
                            </i-col>
                            <i-col v-if="radio_validation.hemophilia_type == false" span="24">
                                <div class="radio-group-error-text">Requerido</div>
                            </i-col>
                        </Row>
                        <br>

                        <!-- Patient anual rate -->
                        <FormItem label="Tasa anual de sangrado (números de sangrados reportados entre número de meses):"  prop="patient_annual_rate" class="block">
                            <i-input type="text" v-model="form.patient_annual_rate" placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Patient Quantification -->
                        <Row>
                            <i-col :xs="15" :sm="10" :md="7" :lg="5">
                                <FormItem label="Cuantificación de inhibidores:"></FormItem>
                            </i-col>
                            <i-col span="9">
                                <FormRadioGroup @callback="validatePatientQuantification">
                                    <FormRadio v-model="form.patient_quantification" mvalue="Sí" label="Sí" />
                                    <FormRadio v-model="form.patient_quantification" mvalue="No" label="No" />
                                </FormRadioGroup>
                            </i-col>
                            <i-col v-if="radio_validation.quantification == false" span="24">
                                <div class="radio-group-error-text" style="text-align: right; margin-right: 65px;">Requerido</div>
                                <br>
                            </i-col>
                        </Row>

                        <!-- Patient UB -->
                        <FormItem label="UB:" prop="patient_ub">
                            <i-input type="text" v-model="form.patient_ub" placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Patient last dose -->
                        <Row :gutter="16">
                            <i-col span="24">
                                <FormItem style="margin-bottom: 10px;" label="¿Cuándo fue la última dosis de FVIII?" ></FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem label="" prop="patient_last_dose_day" class="with-footer-legend">
                                    <InputNumber :max="31" v-model="form.patient_last_dose_day" :formatter="formatWithZero" placeholder="DD" style="width: 100%"></InputNumber>
                                    <p class="form-footer-legend center">Día</p>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem label="" prop="patient_last_dose_month" class="with-footer-legend">
                                    <InputNumber :max="12" v-model="form.patient_last_dose_month" :formatter="formatWithZero"  placeholder="MM" style="width: 100%"></InputNumber>
                                    <p class="form-footer-legend center">Mes</p>
                                </FormItem>
                            </i-col>
                            <i-col span="5">
                                <FormItem label="" prop="patient_last_dose_year" class="with-footer-legend">
                                    <i-input type="text" v-model="form.patient_last_dose_year" placeholder="AAAA" style="width: 100%"></i-input>
                                    <p class="form-footer-legend center">Año</p>
                                </FormItem>
                            </i-col>
                            <i-col span="4" offset="1">
                                <FormItem label="" prop="patient_last_dose_hour" class="with-footer-legend">
                                    <InputNumber :max="23" v-model="form.patient_last_dose_hour" :formatter="formatWithZero" placeholder="HH" style="width: 100%"></InputNumber>
                                    <p class="form-footer-legend center">&nbsp;</p>
                                </FormItem>
                            </i-col>
                            <i-col span="1">
                                <div style="position: relative;">
                                    <p style="font-size: 23px;">:</p>
                                </div>
                            </i-col>
                            <i-col span="4">
                                <FormItem label="" prop="patient_last_dose_minutes" class="with-footer-legend">
                                    <InputNumber :max="59" v-model="form.patient_last_dose_minutes" :formatter="formatWithZero" placeholder="MM" style="width: 100%"></InputNumber>
                                    <p class="form-footer-legend center"></p>
                                    <p style="position: absolute; top: 34px; left: -88px;" class="form-footer-legend center">Hora (Formato 24 horas)</p>
                                </FormItem>
                            </i-col>
                        </Row>

                        <!-- Patient current treatment -->
                        <FormItem label="Tratamiento actual (especifique):"  prop="patient_current_treatment" class="block">
                            <i-input type="text" v-model="form.patient_current_treatment" placeholder="" style="width: 100%"></i-input>
                        </FormItem>
                        <br>
                        
                        <hr>
                        <br>

                        <!-- Request date -->
                        <div class="patientes-extra-s">
                            <div style="height: 10px;"></div>
                            <Row :gutter="16">
                                <i-col :xs="11" :sm="11" :md="6" :lg="4">
                                    <FormItem label="Fecha de solicitud"></FormItem>
                                </i-col>
                                <i-col span="4">
                                    <FormItem label="" prop="request_date_day" class="with-footer-legend">
                                        <InputNumber :max="31" v-model="form.request_date_day" :formatter="formatWithZero" placeholder="DD" style="width: 100%"></InputNumber>
                                        <p class="form-footer-legend center">Día</p>
                                    </FormItem>
                                </i-col>
                                <i-col span="4">
                                    <FormItem label="" prop="request_date_month" class="with-footer-legend">
                                        <InputNumber :max="12" v-model="form.request_date_month" :formatter="formatWithZero" placeholder="MM" style="width: 100%"></InputNumber>
                                        <p class="form-footer-legend center">Mes</p>
                                    </FormItem>
                                </i-col>
                                <i-col span="5">
                                    <FormItem label="" prop="request_date_year" class="with-footer-legend">
                                        <i-input type="text" v-model="form.request_date_year" placeholder="AAAA" style="width: 100%"></i-input>
                                        <p class="form-footer-legend center">Año</p>
                                    </FormItem>
                                </i-col>
                            </Row>
                        </div>

                    </div>
                    <br>
                    <h3>Marcar la prueba requerida</h3>
                    <br>
                    <div id="form-part">
                        <p style="font-size: 0.9em;"><strong>Nombre de la prueba: Ultrasonido</strong></p>
                        <br>

                        <!-- UG type -->
                        <Row>
                            <i-col span="8">
                                <FormItem label="Ultrasonidos:"></FormItem>
                            </i-col>
                            <i-col span="9">
                                <FormRadioGroup @callback="validateUGType">
                                    <FormRadio v-model="form.ug_type" mvalue="Inicial" label="Inicial" label_position="right" />
                                    <FormRadio v-model="form.ug_type" mvalue="Seguimiento" label="Seguimiento" label_position="right" />
                                </FormRadioGroup>
                            </i-col>
                            <i-col v-if="radio_validation.ug_type == false" span="24">
                                <div class="radio-group-error-text" style="text-align: right; margin-right: 65px;">Requerido</div>
                            </i-col>
                        </Row>
                    </div>

                    <!-- Requested region -->
                    <div class="patientes-extra-s">
                        <div style="height: 10px;"></div>
                        <CheckboxGroup v-model="form.requested_region" @on-change="validateRequestedRegion">
                            <Row :gutter="16">
                                <i-col span="24">
                                    <p style="font-size: 1.2em"><strong>Región solicitada:</strong></p>
                                    <br>
                                </i-col>
                                <i-col span="12">
                                    <Checkbox class="hem-region" label="Rodilla derecha">
                                        <img src="/images/services-form/rodilla-derecha.png" alt="">
                                        <span style="margin-left: 55px" class="label">Rodilla derecha</span>
                                    </Checkbox>
                                </i-col>
                                <i-col span="12">
                                    <Checkbox class="hem-region" label="Rodilla izquierda">
                                        <img src="/images/services-form/rodilla-izquierda.png" alt="">
                                        <span style="margin-left: 55px" class="label">Rodilla izquierda</span>
                                    </Checkbox>
                                </i-col>
                                <i-col span="12">
                                    <Checkbox class="hem-region" label="Codo derecho">
                                        <img src="/images/services-form/codo-derecho.png" alt="">
                                        <span style="margin-left: 55px" class="label">Codo derecho</span>
                                    </Checkbox>
                                </i-col>
                                <i-col span="12">
                                    <Checkbox class="hem-region" label="Codo izquierda">
                                        <img src="/images/services-form/codo-izquierdo.png" alt="">
                                        <span style="margin-left: 55px" class="label">Codo izquierda</span>
                                    </Checkbox>
                                </i-col>
                                <i-col span="12">
                                    <Checkbox class="hem-region" label="Tobillo derecho">
                                        <img src="/images/services-form/tobillo-derecho.png" alt="">
                                        <span style="margin-left: 55px" class="label">Tobillo derecho</span>
                                    </Checkbox>
                                </i-col>
                                <i-col span="12">
                                    <Checkbox class="hem-region" label="Tobillo izquierdo">
                                        <img src="/images/services-form/tobillo-izquierdo.png" alt="">
                                        <span style="margin-left: 55px" class="label">Tobillo izquierdo</span>
                                    </Checkbox>
                                </i-col>
                                <i-col span="24">
                                    <div style="height: 20px;"></div>
                                    <FormItem label="Otro:" prop="requested_region_other" :rules="{ required: RequiredRequestedRegionOther, message: 'Requerido', trigger: 'change' }" :show-message="RequiredRequestedRegionOther">
                                        <i-input type="text" v-model="form.requested_region_other" placeholder="" style="width: 100%"></i-input>
                                    </FormItem>
                                </i-col>
                                <i-col v-if="radio_validation.requested_region == false" span="24">
                                    <div class="radio-group-error-text" style="text-align: center;">Requerido</div>
                                </i-col>
                            </Row>
                        </CheckboxGroup>
                    </div>
                    <hr>
                    <br>
                    <p class="font-size: 0.9em"><strong>Expreso tanto mi consentimiento como el del paciente que recibirá el servicio para realizar los estudios necesarios y contar con el resultado de los mismos.</strong></p>
                    <br>
                    <div style="width: 100%">
                        <FormRadio v-model="accepted" :mvalue="true" label="Acepto y comprendo los términos del servicio solicitado" label_position="right" :label_style="{ 'text-align': 'left', 'line-height': '21px', 'margin-top': '-5px', 'margin-left' : '15px', 'font-size': '0.9em' }"/>
                    </div>
                    <br><br>
                    <Button :disabled="!accepted" long size="large" type="primary" :loading="is_loading" @click="handleSubmit">Finalizar y enviar solicitud</Button>
                    <div style="height: 25px"></div>
                    <Button style="background-color: #103c8d" long size="large" type="primary" @click="handleCancel">Cancelar solicitud</Button>
                    <br><br>
                    <div style="font-size: 0.6em; text-align: center;">
                        <p style="margin-bottom: 5px">MATERIAL DE APOYO DE CARÁCTER INFORMATIVO. El programa POR TI le ofrece servicios complementarios de apoyo sin costo, diseñados para dar seguimiento al tratamiento diagnosticado por su médico tratante. Estos materiales son de carácter informativo únicamente y de ninguna forma reemplazan y/o sustituyen el consejo médico. Los servicios complementarios serán de adopción voluntaria y no están condicionados a la venta, consumo o uso de productos. El médico tratante será en todo momento, quien determine el mejor tratamiento para el paciente. Le invitamos a permanecer en contacto con su médico. Material para ser entregado al paciente exclusivamente por el Profesional de la Salud. </p>
                        <p>M-MX-00002171</p>
                    </div>
                </i-form>
            </template>
        </TemplateContent>
    </div>
</template>

<script>
    import form from './form'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [form],
        data(){
            return {
                loading: true,
                patient: {},
                accepted: false,
                form: {
                    doctor_name: '',
                    doctor_professional_id: '',
                    doctor_phone: '',
                    doctor_email: '',
                    doctor_hospital: '',

                    patient_initials_name: '',
                    patient_initials_first_last_name: '',
                    patient_initials_second_last_name: '',
                    patient_name: '',
                    patient_phone_1: '',
                    patient_phone_2: '',
                    patient_email: '',
                    patient_gender: '',
                    patient_birth_day: null,
                    patient_birth_month: null,
                    patient_birth_year: null,
                    patient_hemophilia_type: '',
                    patient_annual_rate: '',
                    patient_quantification: '',
                    patient_ub: '',
                    patient_last_dose_day: null,
                    patient_last_dose_month: null,
                    patient_last_dose_year: null,
                    patient_last_dose_hour: null,
                    patient_last_dose_minutes: null,
                    patient_current_treatment: '',
                    // patient_weight: '',
                    // patient_height_m: '',
                    // patient_height_cm: '',
                    // patient_city: '',
                    // patient_state: '',
                    // patient_contact: '',

                    request_date_day: null,
                    request_date_month: null,
                    request_date_year: null,
                    ug_type: '',
                    requested_region: [],
                    requested_region_other: '',
                },
                ruleInline: {
                    doctor_name: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                    doctor_professional_id: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                    doctor_phone: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                        
                    ],
                    doctor_email: [
                        { required: true, type: 'email', message: 'Requerido', trigger: 'change' },
                    ],
                    doctor_hospital: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_initials_first_last_name: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[A-Za-z]/ },
                    ],
                    patient_initials_second_last_name: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[A-Za-z]/ },
                        { max: 3, message: 'Máximo tres caracteres' }
                    ],
                    patient_initials_name: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[A-Za-z]/ },
                        { max: 3, message: 'Máximo tres caracteres' }
                    ],
                    patient_name: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_phone_1: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_phone_2: [
                        { required: false, trigger: 'change' },
                    ],
                    patient_email: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_birth_day: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    patient_birth_month: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    patient_birth_year: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[0-9]*$/ },
                        { min: 4, message: '4 dígitos', trigger: 'change' },
                        { max: 4, message: '4 dígitos', trigger: 'change' },
                    ],
                    patient_annual_rate: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_ub: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_last_dose_day: [
                        { required: true, type: 'number', message: 'Requerido', trigger: 'change' },
                    ],
                    patient_last_dose_month: [
                        { required: true, type: 'number', message: 'Requerido', trigger: 'change' },
                    ],
                    patient_last_dose_year: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[0-9]*$/ },
                        { min: 4, message: '4 dígitos', trigger: 'change' },
                        { max: 4, message: '4 dígitos', trigger: 'change' },
                    ],
                    patient_last_dose_hour: [
                        { required: true, type: 'number', message: 'Requerido', trigger: 'change' },
                    ],
                    patient_last_dose_minutes: [
                        { required: true, type: 'number', message: 'Requerido', trigger: 'change' },
                    ],
                    patient_current_treatment: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    // patient_weight: [
                    //     { required: true, message: 'Requerido', trigger: 'change' },
                    // ],
                    // patient_height_m: [
                    //     { required: true, message: 'Requerido', trigger: 'change' },
                    // ],
                    // patient_height_cm: [
                    //     { required: true, message: 'Requerido', trigger: 'change' },
                    // ],
                    // patient_city: [
                    //     { required: true, message: 'Requerido', trigger: 'change' },
                    // ],
                    // patient_state: [
                    //     { required: true, message: 'Requerido', trigger: 'change' },
                    // ],
                    // patient_contact: [
                    //     { required: true, message: 'Requerido', trigger: 'change' },
                    // ],
                    request_date_day: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    request_date_month: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    request_date_year: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /[0-9]/ },
                        { min: 4, message: '4 dígitos', trigger: 'change' },
                        { max: 4, message: '4 dígitos', trigger: 'change' },
                    ],
                },
                is_loading: false,
                radio_validation: {
                    gender: null,
                    ug_type: null,
                    quantification: null,
                    hemophilia_type: null,
                    requested_region: null,
                },
                diagnosis_services_related: [],
                additionals_services_related: [],
            }
        },
        computed: {
            ...mapGetters([
                'patient_services',
            ]),
            RequiredRequestedRegionOther: {
                get(){
                    return this.form.requested_region == ''
                },
                set(newValue){
                    return newValue
                }
            }
        },
        methods: {
            getPatient(){
                window.axios.post(this.$root.API_URL+ `/doctors/get-patient/${this.$route.params.id}`)
                .then(response => {
                    if(response.data.success){
                        this.patient = response.data.patient

                        if( this.$route.query.fill ) this.fillForm()
                        else {
                            let doctor = response.data.doctor
                            this.form.doctor_name = doctor.name
                            this.form.doctor_professional_id = doctor.professional_id
                            this.form.doctor_phone = doctor.phone
                            this.form.doctor_email = doctor.user.email
    
                            this.form.patient_name = this.patient.name
                            this.form.patient_phone_1 = this.patient.phone
                            this.form.patient_phone_2 = this.patient.additional_phone
                            this.form.patient_email = this.patient.email
    
    
                        }

                        this.loading = false
                        this.is_loading = false
                    }
                })
            },
            validateGenderRadio(){
                this.radio_validation.gender = this.form.patient_gender != ''
            },
            validateHemophiliaType(){
                this.radio_validation.hemophilia_type = this.form.patient_hemophilia_type != ''
            },
            validatePatientQuantification(){
                this.radio_validation.quantification = this.form.patient_quantification != ''
            },
            validateUGType(){
                this.radio_validation.ug_type = this.form.ug_type != ''
            },
            validateRequestedRegion(){
                if( this.form.requested_region_other != '' ){
                    this.radio_validation.requested_region = true
                    return true
                }
                this.radio_validation.requested_region = this.form.requested_region.length > 0
                this.RequiredRequestedRegionOther = this.form.requested_region.length > 0
                this.$forceUpdate()
            },
            handleSubmit(){

                this.validateGenderRadio()

                this.validateHemophiliaType()

                this.validatePatientQuantification()

                this.validateUGType()

                this.validateRequestedRegion()

                let radio_validate = Object.values(this.radio_validation).indexOf(false) > -1

                this.$refs['form'].validate((valid) => {
                    if (valid && !radio_validate) {
                        
                        this.is_loading = true
                        let category = this.$route.query.category

                        let sis = window._.findIndex(this.patient_services[category], (service) => {
                            return service.name == "Ultrasonidos para monitoreo de daño articular: rodilla, codo y tobillo"
                        })

                        if( sis != -1 ){

                            let current_service = this.patient_services[category][sis]
                            let new_item = {
                                ...current_service,
                                checked: true,
                                form_data: this.form,
                            }
                            this.$store.dispatch('SET_PATIENT_SERVICE', {
                                category: category,
                                service: new_item
                            })

                            this.$router.push(`/doctor/my-patient/${this.patient.id}`)
                        }
                        else {
                            this.is_loading = false
                            this.$Message.error('No se pudo completar la acción');
                        }

                    } else {
                        this.$Message.error('Completa la información faltante');
                    }
                })
            },
            handleCancel(){
                let category = this.$route.query.category

                let sis = window._.findIndex(this.patient_services[category], (service) => {
                    return service.name == "Ultrasonidos para monitoreo de daño articular: rodilla, codo y tobillo"
                })

                if( sis != -1 ){
                    let current_service = this.patient_services[category][sis]
                    let new_item = {
                        ...current_service,
                        checked: false,
                        form_data: null,
                    }

                    this.$store.dispatch('SET_PATIENT_SERVICE', {
                        category: category,
                        service: new_item
                    })

                    this.$router.push(`/doctor/my-patient/${this.patient.id}`)
                }
            },
            fillForm(){
                let category = this.$route.query.category

                let sis = window._.findIndex(this.patient_services[category], (service) => {
                    return service.name == "Ultrasonidos para monitoreo de daño articular: rodilla, codo y tobillo"
                })

                if( sis != -1 ){
                    let current_service = this.patient_services[category][sis]

                    if( current_service && current_service.form_data != null ){
                        this.form = current_service.form_data
                        // this.form.doctor_phone = 
                        this.accepted = true
                    }
                }
            }
        },
        mounted(){
            this.getPatient()
        },
    }
</script>