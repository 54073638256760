<template>
    <li>
        <b>Adherencia</b>
        <div class="ul-items-childs">
            <div v-for="(additional, i) in Services" :key="'a_'+i">
                <template v-if="additional.category == 'legend'">
                    <div class="child-item title">
                        <p class="title">{{ getLegendTitle(additional.name) }}</p>
                        <div class="child-subitems">
                            <div v-for="(child, i) in additional.services" :key="'cc_'+i">
                                    <div v-if="child.can_be_listed == true" class="child-subitem-itm">
                                        <p>{{ child.name }}</p>
                                    </div>
                                </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="child-item" :class="additional.wa_data.class || {}">
                        <p v-html="additional.name"></p>
                        <div v-if="additional.wa_data" style="font-size: 0.8em; margin-top: 5px; margin-left: 35px;" v-html="additional.wa_data.footer_legend"></div>
                    </div>

                    
                </template>
            </div>

            <template v-if="product == 'Pulmozyme'">
                <div style="margin-top: 5px; font-size: 0.8em">
                    <p>Aplican para sector público y privado.</p>
                </div>
            </template>
        </div>
    </li>
</template>

<script>
    export default {
        props: {
            services: { required: true, type: [Object, Array] },
            product: { required: true, type: String },
        },
        computed: {
            Services(){
                let ss = window._.each(this.services, (service) => {
                    if(typeof service.pivot.pivot != 'object'){
                        service.pivot.pivot = JSON.parse(service.pivot.pivot || "{\n\t\"order\": 99999\n}" )
                    }
                })

                return window._.sortBy(ss, ['pivot.pivot.order'])
            },
        },
        methods: {
            getLegendTitle(name){
                if(name == 'Hematología:') return 'Diagnóstico de hematología:'
                else if(name == 'Molecular:') return 'Diagnóstico molecular:'
                else return name
            }
        }
    }
</script>