<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h3>Solicitud de estudio de evaluación ecográfica para artritis reumatoide</h3>
            <template v-if="loading">
                <Loading/>
            </template>
            <template v-else>
                <i-form class="doctor-service-form" ref="form" :model="form" :rules="ruleInline" inline >
                    <br>
                    <h4>Datos del Médico</h4>
                    <br>
                    <div id="doctor-form-part">
                        <!-- Doctor name -->
                        <FormItem label="Nombre completo:" prop="doctor_name">
                            <i-input type="text" v-model="form.doctor_name" disabled placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Doctor Professional ID -->
                        <FormItem label="Cédula profesional:"  prop="doctor_professional_id">
                            <i-input type="text" v-model="form.doctor_professional_id" placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Doctor phone -->
                        <FormItem label="Teléfono:"  prop="doctor_phone">
                            <i-input ref="doctor_phone" type="number" v-model="form.doctor_phone" placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Doctor phone -->
                        <FormItem label="E-mail:"  prop="doctor_email">
                            <i-input type="text" v-model="form.doctor_email" disabled placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <hr>
                        <br>
                    </div>
                    <h4>Datos del Paciente</h4>
                    <p style="margin-top: 10px; font-size: 0.9em">Estimado médico le agradecemos proporcionar la información de su paciente para coordinar la realización de este estudio. </p>
                    <br>
                    <div id="patient-form-part">

                        <!-- Patient name -->
                        <FormItem label="Nombre completo:"  prop="patient_name">
                            <i-input type="text" v-model="form.patient_name" disabled placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Patient phone 1 -->
                        <FormItem label="Teléfono de contacto 1:"  prop="patient_phone_1">
                            <i-input type="text" v-model="form.patient_phone_1" placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Patient phone 2 -->
                        <FormItem label="Teléfono de contacto 2:"  prop="patient_phone_2">
                            <i-input type="text" v-model="form.patient_phone_2" placeholder="" style="width: 100%"></i-input>
                        </FormItem>

                        <!-- Patient email -->
                        <FormItem label="E-mail de contacto:"  prop="patient_email">
                            <i-input type="text" v-model="form.patient_email" disabled placeholder="" style="width: 100%"></i-input>
                        </FormItem>
                        <div style="height: 5px;"></div>

                        <!-- Patient gender -->
                        <Row :gutter="16">
                            <i-col :xs="5" :sm="5" :md="3" :lg="2">
                                <FormItem label="Género" ></FormItem>
                            </i-col>
                            <i-col span="12">
                                <FormRadioGroup @callback="validateGenderRadio">
                                    <FormRadio v-model="form.patient_gender" mvalue="F" label="F" />
                                    <FormRadio v-model="form.patient_gender" mvalue="M" label="M" />
                                </FormRadioGroup>
                                <div style="margin-top: -5px" class="radio-group-error-text" v-if="radio_validation.gender == false">Requerido</div>
                            </i-col>
                        </Row>
                        <div style="height: 5px;"></div>

                        <!-- Patient birthdate -->
                        <Row :gutter="16">
                            <i-col :xs="11" :sm="11" :md="6" :lg="4">
                                <FormItem label="Fecha de nacimiento"></FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem label="" prop="patient_birth_day" class="with-footer-legend">
                                    <InputNumber :max="31" v-model="form.patient_birth_day" :formatter="formatWithZero" placeholder="DD" style="width: 100%"></InputNumber>
                                    <p class="form-footer-legend center">Día</p>
                                </FormItem>
                            </i-col>
                            <i-col span="4">
                                <FormItem label="" prop="patient_birth_month" class="with-footer-legend">
                                    <InputNumber :max="12" v-model="form.patient_birth_month" :formatter="formatWithZero" placeholder="MM" style="width: 100%"></InputNumber>
                                    <p class="form-footer-legend center">Mes</p>
                                </FormItem>
                            </i-col>
                            <i-col span="5">
                                <FormItem label="" prop="patient_birth_year" class="with-footer-legend">
                                    <i-input type="text" v-model="form.patient_birth_year" placeholder="AAAA" style="width: 100%"></i-input>
                                    <p class="form-footer-legend center">Año</p>
                                </FormItem>
                            </i-col>
                        </Row>
                        <div style="height: 5px;"></div>
                        <br>

                        <hr>
                        <br>

                        <!-- <Row :gutter="16">
                            <i-col span="10"> -->
                                <!-- Patient weight -->
                                <!-- <Row :gutter="16">
                                    <i-col span="8">
                                        <FormItem label="Peso:"></FormItem>
                                    </i-col>
                                    <i-col span="16">
                                        <FormItem label=""  prop="patient_weight">
                                            <i-input type="text" v-model="form.patient_weight" placeholder="" style="width: 100%"></i-input>
                                        </FormItem>
                                    </i-col>
                                </Row> -->
                            <!-- </i-col>
                            <i-col span="14"> -->
                                <!-- Patient height -->
                                <!-- <Row :gutter="16">
                                    <i-col span="8">
                                        <FormItem label="Estatura:"></FormItem>
                                    </i-col>
                                    <i-col span="8">
                                        <FormItem label=""  prop="patient_height_m" class="with-footer-legend">
                                            <i-input type="text" v-model="form.patient_height_m" placeholder="" style="width: 100%"></i-input>
                                            <p class="form-footer-legend center">m</p>
                                        </FormItem>

                                    </i-col>
                                    <i-col span="8">
                                        <FormItem label=""  prop="patient_height_cm" class="with-footer-legend">
                                            <i-input type="text" v-model="form.patient_height_cm" placeholder="" style="width: 100%"></i-input>
                                            <p class="form-footer-legend center">cm</p>
                                        </FormItem>
                                    </i-col>
                                </Row> -->
                            <!-- </i-col>
                        </Row> -->

                        <!-- Patient city -->
                        <!-- <FormItem label="Ciudad:"  prop="patient_city">
                            <i-input type="text" v-model="form.patient_city" placeholder="" style="width: 100%"></i-input>
                        </FormItem> -->

                        <!-- Patient state -->
                        <!-- <FormItem label="Estado:"  prop="patient_state">
                            <i-input type="text" v-model="form.patient_state" placeholder="" style="width: 100%"></i-input>
                        </FormItem> -->

                        <!-- Patient contacto -->
                        <!-- <FormItem label="Contacto/Tutor:"  prop="patient_contact">
                            <i-input type="text" v-model="form.patient_contact" placeholder="" style="width: 100%"></i-input>
                        </FormItem> -->

                        <!-- Patient obs -->
                        <!-- <FormItem label="Características clínicas relevantes:"  prop="patient_obs" class="block"> 
                            <i-input type="textarea" v-model="form.patient_obs" placeholder="" rows="5" style="width: 100%"></i-input>
                        </FormItem> -->

                        <!-- <br> -->
                        <p style="font-size: 0.9em; text-align: center;"><strong>
                            Estimado médico, a continuación encuentre el directorio de centros autorizados para estudio. Le pedimos indicar a su paciente el centro de preferencia, o bien, pedir al paciente que seleccione el centro que mejor le acomode.
                        </strong></p>

                        <div style="margin: 1em">
                            <Button long size="large" type="primary" @click="showUSGDirectory">Directorio</Button>
                        </div>
                        

                        <!-- Request date -->
                        <div class="patientes-extra-s">
                            <div style="height: 10px;"></div>
                            <Row :gutter="16">
                                <i-col :xs="11" :sm="11" :md="6" :lg="4">
                                    <FormItem label="Fecha de solicitud"></FormItem>
                                </i-col>
                                <i-col span="4">
                                    <FormItem label="" prop="request_date_day" class="with-footer-legend">
                                        <InputNumber :max="31" v-model="form.request_date_day" :formatter="formatWithZero" placeholder="DD" style="width: 100%"></InputNumber>
                                        <p class="form-footer-legend center">Día</p>
                                    </FormItem>
                                </i-col>
                                <i-col span="4">
                                    <FormItem label="" prop="request_date_month" class="with-footer-legend">
                                        <InputNumber :max="12" v-model="form.request_date_month" :formatter="formatWithZero" placeholder="MM" style="width: 100%"></InputNumber>
                                        <p class="form-footer-legend center">Mes</p>
                                    </FormItem>
                                </i-col>
                                <i-col span="5">
                                    <FormItem label="" prop="request_date_year" class="with-footer-legend">
                                        <i-input type="text" v-model="form.request_date_year" placeholder="AAAA" style="width: 100%"></i-input>
                                        <p class="form-footer-legend center">Año</p>
                                    </FormItem>
                                </i-col>
                            </Row>

                            <p style="font-size: 0.7em; margin-top: -15px">NOTA: El estudio solicitado comprende la evolución ecográfica de 7 regiones articulares (carpo; 2, 3, MCF; 2, 3 IFP y 2, 5 MTF en mano y pie dominantes).</p>
                            <br>
                            <Row :gutter="16">
                                <i-col span="12">
                                    <center>
                                        <div style="height: 10px;"></div>
                                        <img style="width: 65%; max-width: 180px" src="/images/services-form/Mano-1.png" alt="">
                                        <div style="height: 10px;"></div>
                                    </center>
                                </i-col>
                                <i-col span="12">
                                    <center>
                                        <div style="height: 10px;"></div>
                                        <img style="width: 65%; max-width: 180px" src="/images/services-form/Pie-1.png" alt="">
                                        <div style="height: 10px;"></div>
                                    </center>
                                </i-col>
                                <i-col span="12">
                                    <center>
                                        <div style="height: 10px;"></div>
                                        <img style="width: 65%; max-width: 180px" src="/images/services-form/Mano-1.2.png" alt="">
                                        <div style="height: 10px;"></div>
                                    </center>
                                </i-col>
                                <i-col span="12">
                                    <center>
                                        <div style="height: 10px;"></div>
                                        <img style="width: 65%; max-width: 180px" src="/images/services-form/Pie-1.2.png" alt="">
                                        <div style="height: 10px;"></div>
                                    </center>
                                </i-col>
                                <i-col span="12">
                                    <center>
                                        <p style="font-size: 0.6em"><strong>Mano</strong></p>
                                        <p style="font-size: 0.6em">Tendón extensor</p>
                                        <p style="font-size: 0.6em">Cubital del carpo</p>
                                    </center>
                                </i-col>
                                <i-col span="12">
                                    <center>
                                        <p style="font-size: 0.6em"><strong>Pie</strong></p>
                                        <p style="font-size: 0.6em">Tendón tibal posterior</p>
                                    </center>
                                </i-col>
                            </Row>
                            <br>
                        </div>

                    </div>
        
                    <hr>
                    <br>
                    <p class="font-size: 0.9em"><strong>Expreso tanto mi consentimiento como el del paciente que recibirá el servicio para realizar los estudios necesarios y contar con el resultado de los mismos.</strong></p>
                    <br>
                    <div style="width: 100%">
                        <FormRadio v-model="accepted" :mvalue="true" label="Acepto y comprendo los términos del servicio solicitado" label_position="right" :label_style="{ 'text-align': 'left', 'line-height': '21px', 'margin-top': '-5px', 'margin-left' : '15px', 'font-size': '0.9em' }"/>
                    </div>
                    <br><br>
                    <Button :disabled="!accepted" long size="large" type="primary" :loading="is_loading" @click="handleSubmit">Finalizar y enviar solicitud</Button>
                    <div style="height: 25px"></div>
                    <Button style="background-color: #103c8d" long size="large" type="primary" @click="handleCancel">Cancelar solicitud</Button>
                    <br><br>
                    <div style="font-size: 0.6em; text-align: center;">
                        <p style="margin-bottom: 5px">El programa de diagnóstico POR TI le ofrece servicios diseñados para facilitar el diagnóstico oportuno en acompañamiento con su Médico Tratante, quien decidirá las pruebas de gabinete/estudios clínicos más adecuados para usted. Dichos servicios son sin costo para el paciente, médico y/o cuidador.</p>
                        <p>Este material es de uso exclusivo del programa de Roche POR TI, queda prohibida su reproducción parcial o total.</p>
                        <p style="margin-bottom: 5px">El médico tratante será, en todo momento, quien determine el mejor tratamiento para el paciente. Le invitamos a permanecer en contacto con su médico.</p>
                        <p>M-MX-00002360</p>
                    </div>
                </i-form>

                <USGDirectory @selected="setUSG"/>
            </template>
        </TemplateContent>
    </div>
</template>

<script>
    import form from './form'
    import USGDirectory from './USGDirectory'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [form],
        data(){
            return {
                loading: true,
                patient: {},
                accepted: false,
                form: {
                    doctor_name: '',
                    doctor_professional_id: '',
                    doctor_phone: '',
                    doctor_email: '',

                    patient_name: '',
                    patient_phone_1: '',
                    patient_phone_2: '',
                    patient_email: '',
                    patient_gender: '',
                    patient_birth_day: null,
                    patient_birth_month: null,
                    patient_birth_year: null,
                    // patient_weight: '',
                    // patient_height_m: '',
                    // patient_height_cm: '',
                    // patient_city: '',
                    // patient_state: '',
                    // patient_contact: '',

                    usg: [],

                    request_date_day: null,
                    request_date_month: null,
                    request_date_year: null,
                },
                ruleInline: {
                    doctor_name: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                    doctor_professional_id: [
                        { required: true, message: 'Requerido', trigger: 'change' }
                    ],
                    doctor_phone: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                        
                    ],
                    doctor_email: [
                        { required: true, type: 'email', message: 'Requerido', trigger: 'change' },
                    ],
                    patient_name: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_phone_1: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_phone_2: [
                        { required: false, trigger: 'change' },
                    ],
                    patient_email: [
                        { required: true, message: 'Requerido', trigger: 'change' },
                    ],
                    patient_birth_day: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    patient_birth_month: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    patient_birth_year: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[0-9]*$/ },
                        { min: 4, message: '4 dígitos', trigger: 'change' },
                        { max: 4, message: '4 dígitos', trigger: 'change' },
                    ],
                    // patient_weight: [
                    //     { required: true, message: 'Requerido', trigger: 'change' },
                    // ],
                    // patient_height_m: [
                    //     { required: true, message: 'Requerido', trigger: 'change' },
                    // ],
                    // patient_height_cm: [
                    //     { required: true, message: 'Requerido', trigger: 'change' },
                    // ],
                    // patient_city: [
                    //     { required: true, message: 'Requerido', trigger: 'change' },
                    // ],
                    // patient_state: [
                    //     { required: true, message: 'Requerido', trigger: 'change' },
                    // ],
                    // patient_contact: [
                    //     { required: true, message: 'Requerido', trigger: 'change' },
                    // ],
                    // patient_obs: [
                    //     { required: false, message: 'Requerido', trigger: 'change' },
                    // ],
                    request_date_day: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    request_date_month: [
                        { required: true, type: 'integer', message: 'Requerido', trigger: 'change', pattern: /[1-9]/ },
                    ],
                    request_date_year: [
                        { required: true, message: 'Requerido', trigger: 'change', pattern: /^[0-9]*$/ },
                        { min: 4, message: '4 dígitos', trigger: 'change' },
                        { max: 4, message: '4 dígitos', trigger: 'change' },
                    ],
                },
                is_loading: false,
                radio_validation: {
                    gender: null,
                },
                diagnosis_services_related: [],
                additionals_services_related: [],
            }
        },
        computed: {
            ...mapGetters([
                'patient_services',
            ]),
        },
        methods: {
            getPatient(){
                let self = this

                window.axios.post(this.$root.API_URL+ `/doctors/get-patient/${this.$route.params.id}`)
                .then(response => {
                    if(response.data.success){
                        this.patient = response.data.patient

                        if( this.$route.query.fill ) {
                            this.fillForm()
                            this.form.doctor_phone = response.data.doctor.phone
                        }
                        else {
                            let doctor = response.data.doctor
                            this.form.doctor_name = doctor.name
                            this.form.doctor_professional_id = doctor.professional_id
                            this.form.doctor_phone = doctor.phone
                            this.form.doctor_email = doctor.user.email
    
                            this.form.patient_name = this.patient.name
                            this.form.patient_phone_1 = this.patient.phone
                            this.form.patient_phone_2 = this.patient.additional_phone
                            this.form.patient_email = this.patient.email
    
    
                        }

                        this.loading = false
                        this.is_loading = false

                        setTimeout(() => {

                            // Unlock doctor's phone
                            if( self.form.doctor_phone == null ) window.$(self.$refs['doctor_phone'].$el).find('input').removeAttr('disabled')

                        }, 1000)
                    }
                })
            },
            validateGenderRadio(){
                this.radio_validation.gender = this.form.patient_gender != ''
            },
            showUSGDirectory(){
                 window.EventBus.$emit('doctor-service--show-usg-directory')
            },
            setUSG(usg){
                this.form.usg = usg
            },
            handleSubmit(){

                this.validateGenderRadio()

                let radio_validate = Object.values(this.radio_validation).indexOf(false) > -1

                this.$refs['form'].validate((valid) => {
                    if (valid && !radio_validate) {

                        if( this.form.usg.length == 0 ){
                            this.$Message.error('Selecciona un USG');

                        }else {
                            this.is_loading = true
                            let category = this.$route.query.category
    
                            let sis = window._.findIndex(this.patient_services[category], (service) => {
                                return service.name == "Ultrasonidos articulares"
                            })
    
                            if( sis != -1 ){
    
                                let current_service = this.patient_services[category][sis]
                                let new_item = {
                                    ...current_service,
                                    checked: true,
                                    form_data: this.form,
                                }
                                this.$store.dispatch('SET_PATIENT_SERVICE', {
                                    category: category,
                                    service: new_item
                                })
    
                                let scroll = window.scrollY || 0
                                this.$router.push(`/doctor/my-patient/${this.patient.id}?scroll=${scroll}`)
                            }
                            else {
                                this.is_loading = false
                                this.$Message.error('No se pudo completar la acción');
                            }
                        }
                    } else {
                        this.$Message.error('Completa la información faltante');
                    }
                })
            },
            handleCancel(){
                let category = this.$route.query.category

                let sis = window._.findIndex(this.patient_services[category], (service) => {
                    return service.name == "Ultrasonidos articulares"
                })

                if( sis != -1 ){
                    let current_service = this.patient_services[category][sis]
                    let new_item = {
                        ...current_service,
                        checked: false,
                        form_data: null,
                    }

                    this.$store.dispatch('SET_PATIENT_SERVICE', {
                        category: category,
                        service: new_item
                    })

                    this.$router.push(`/doctor/my-patient/${this.patient.id}`)
                }
            },
            fillForm(){
                let category = this.$route.query.category

                let sis = window._.findIndex(this.patient_services[category], (service) => {
                    return service.name == "Ultrasonidos articulares"
                })

                if( sis != -1 ){
                    let current_service = this.patient_services[category][sis]

                    if( current_service && current_service.form_data != null ){
                        this.form = current_service.form_data
                        this.form.doctor_phone = 
                        this.accepted = true
                    }
                }
            }
        },
        mounted(){
            this.getPatient()
        },
        components: {
            USGDirectory,
        }
    }
</script>