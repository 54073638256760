<template>
    <div class="pathology-template">
        <div class="header">
            <!-- <div class="img">
                <img src="images/pathology/header-photo.png" alt="">
            </div> -->
            <h3 v-html="title"></h3>
            <p>{{ subtitle }}</p>
        </div>
        <div ref="background" v-if="background" class="background" :style="{ ...BackgroundStyle }"></div>
        <Row style="padding-top: 20px; padding-bottom: 50px">
            <slot name="before_content" />
            <Column span="20" offset="2">
                <slot/>
            </Column>
        </Row>
        <TemplateFooter color="black" :pathology="true"/>
    </div>
</template>

<script>
    export default {
        props: {
            title: String,
            subtitle: { required: false, type: String },
            background: { required: false, type: Object }
        },
        computed: {
            BackgroundStyle(){
                let item = this.background
                //item.style['background-image'] = 'url('+this.$root.APP_URL+'/'+item.url+')';
                item.style['height'] = `${item.style['height']}px`
                return item.style;
            }
        }
    }
</script>

<style lang="sass">
    .pathology-template
        position: relative
        min-height: calc(100vh - 85px)
        background-color: rgb(233,233,233)
        padding-bottom: 45px
        & .header
            position: relative
            padding: 20px
            background-color: #00AFD7
            & h3
                // padding-left: 80px
                //line-height: 1
            & p
                padding-left: 80px
                line-height: 1
            & .img
                position: absolute
                top: 5px
                z-index: 5
                & img
                    width: 75px
        & .background
            width: 100%
            background-repeat: no-repeat
            background-position: center
            background-size: cover

</style>