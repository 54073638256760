<template>
    <div style="background-color: white; color: #006fb9;">
        <ServiceContent footer_color="#006fb9">
            <div slot="header" class="image-header">
                <img style="width: 100%;" src="/images/doctor-services/CMC-Taller.png" alt="">
            </div>
            <p class="service-title">Capacitación médica continua en el manejo de medicamento vía virtual</p>
            <p class="txt-black">Esta capacitación es para pacientes, cuidadores, enfermeras y HCPs.</p>
            <br>
            <template v-if="target == 'all'">

                <Carousel v-model="items" dots="outside" :radius-dot="true" class="arrows-outside blue-dots">

                    <CarouselItem v-touch:swipe.left="swipeHandlerLeft" v-touch:swipe.right="swipeHandlerRight">
                        <div class="cp-me-item">
                            <h3 class="txt-center txt-black">Preparación y aplicación Mircera<sup>&reg;</sup></h3>
                            <br>
                            <p class="txt-black"><strong>Fechas:</strong> Primer martes de cada mes</p>
                            <p class="txt-black"><strong>Horario.</strong> 17:00 horas</p>
                            <br>
                            <p class="txt-black"><strong>Vía:</strong> Unirse a la reunión de Zoom:</p>
                            <a style="overflow-wrap: break-word;" target="_blank" href="https://us02web.zoom.us/j/84806696988?pwd=NDkxNG9rUjNtL25jWlpFOTdkdnZDUT09">https://bit.ly/3aRB9ZK</a>
                            <p class="txt-black"><strong>ID:</strong> 848 0669 6988</p>
                            <p class="txt-black"><strong>Contraseña:</strong> 89282</p>
                        </div>
                    </CarouselItem>

                    <CarouselItem v-touch:swipe.left="swipeHandlerLeft" v-touch:swipe.right="swipeHandlerRight">
                        <div class="cp-me-item">
                            <h3 class="txt-center txt-black">Aplicación Roactemra<sup>&reg;</sup> SC</h3>
                            <br>
                            <p class="txt-black"><strong>Fechas:</strong> Primer martes de cada mes</p>
                            <p class="txt-black"><strong>Horario.</strong> 18:10 horas</p>
                            <br>
                            <p class="txt-black"><strong>Vía:</strong> Unirse a la reunión de Zoom:</p>
                            <a style="overflow-wrap: break-word;" target="_blank" href="https://us02web.zoom.us/j/82503412707?pwd=dmNyN2p1MU15aCtxdHRZdjFBZGhSdz09">https://bit.ly/3d65K8K</a>
                            <p class="txt-black"><strong>ID:</strong> 825 0341 2707</p>
                            <p class="txt-black"><strong>Contraseña:</strong> 470630</p>
                        </div>
                    </CarouselItem>

                    <CarouselItem v-touch:swipe.left="swipeHandlerLeft" v-touch:swipe.right="swipeHandlerRight">
                        <div class="cp-me-item">
                            <h3 class="txt-center txt-black">Capacitación de aplicación y manejo de medicamento Hemlibra<sup>&reg;</sup></h3>
                            <br>
                            <p class="txt-black"><strong>Fechas:</strong> Los jueves de cada semana</p>
                            <p class="txt-black"><strong>Horario.</strong> 18:00 horas</p>
                            <br>
                            <p class="txt-black"><strong>Vía:</strong> Unirse a la reunión de Zoom:</p>
                            <a style="overflow-wrap: break-word;" target="_blank" href="https://us02web.zoom.us/j/87654005321?pwd=WDlQV09tSHpFRzk5RDFSMUp1cWVKdz09">https://bit.ly/2LGl9RE</a>
                            <p class="txt-black"><strong>ID:</strong> 876 5400 5321</p>
                            <p class="txt-black"><strong>Contraseña:</strong> 163399</p>
                        </div>
                    </CarouselItem>

                    <CarouselItem v-touch:swipe.left="swipeHandlerLeft" v-touch:swipe.right="swipeHandlerRight">
                        <div class="cp-me-item">
                            <h3 class="txt-center txt-black">Aplicación móvil HER2min</h3>
                            <br>
                            <p class="txt-black">Te invitamos a conocer la aplicación de HER2min donde se ilustra la preparación de Perjeta<sup>&reg;</sup>, Kadcyla<sup>&reg;</sup> y Herceptin<sup>&reg;</sup>.</p>
                            <br>
                            <p class="txt-black">Descárgala a continuación para:</p>
                            <br>
                            <p class="txt-black">iOS: <a target="_blank" href="https://apple.co/3mpysnr">https://apple.co/3mpysnr</a></p>
                            <p class="txt-black">Android: <a target="_blank" href="https://bit.ly/3fMnUOb">https://bit.ly/3fMnUOb</a></p>
                            <br>
                            <p class="txt-black">Está enfocada para HCPs y enfermeras.</p>
                            <p class="txt-black">No tiene costo y se puede utilizar sin internet.</p>
                        </div>
                    </CarouselItem>

                </Carousel>

            </template>

            <template v-if="target == 'Hemlibra'">
                <div class="cp-me-item">
                    <h3 class="txt-center txt-black">Capacitación de aplicación y manejo de medicamento Hemlibra<sup>&reg;</sup></h3>
                    <br>
                    <p class="txt-black"><strong>Fechas:</strong> Los jueves de cada semana</p>
                    <p class="txt-black"><strong>Horario.</strong> 18:00 horas</p>
                    <br>
                    <p class="txt-black"><strong>Vía:</strong> Unirse a la reunión de Zoom:</p>
                    <a style="overflow-wrap: break-word;" target="_blank" href="https://us02web.zoom.us/j/87654005321?pwd=WDlQV09tSHpFRzk5RDFSMUp1cWVKdz09">https://bit.ly/2LGl9RE</a>
                    <p class="txt-black"><strong>ID:</strong> 876 5400 5321</p>
                    <p class="txt-black"><strong>Contraseña:</strong> 163399</p>
                    <br>
                    <p class="txt-black txt-center">Nota: con flexibilidad en base a la disponibilidad del Profesional de la Salud.</p>
                </div>
            </template>

            <template v-if="target == 'Roactemra'">
                <div class="cp-me-item">
                    <h3 class="txt-center txt-black">Aplicación Roactemra<sup>&reg;</sup> SC</h3>
                    <br>
                    <p class="txt-black"><strong>Fechas:</strong> Primer martes de cada mes</p>
                    <p class="txt-black"><strong>Horario.</strong> 18:10 horas</p>
                    <br>
                    <p class="txt-black"><strong>Vía:</strong> Unirse a la reunión de Zoom:</p>
                    <a style="overflow-wrap: break-word;" target="_blank" href="https://us02web.zoom.us/j/82503412707?pwd=dmNyN2p1MU15aCtxdHRZdjFBZGhSdz09">https://bit.ly/3d65K8K</a>
                    <p class="txt-black"><strong>ID:</strong> 825 0341 2707</p>
                    <p class="txt-black"><strong>Contraseña:</strong> 470630</p>
                </div>
            </template>

            <template v-if="target == 'Mircera'">
                <div class="cp-me-item">
                    <h3 class="txt-center txt-black">Preparación y aplicación Mircera<sup>&reg;</sup></h3>
                    <br>
                    <p class="txt-black"><strong>Fechas:</strong> Primer martes de cada mes</p>
                    <p class="txt-black"><strong>Horario.</strong> 17:00 horas</p>
                    <br>
                    <p class="txt-black"><strong>Vía:</strong> Unirse a la reunión de Zoom:</p>
                    <a style="overflow-wrap: break-word;" target="_blank" href="https://us02web.zoom.us/j/84806696988?pwd=NDkxNG9rUjNtL25jWlpFOTdkdnZDUT09">https://bit.ly/3aRB9ZK</a>
                    <p class="txt-black"><strong>ID:</strong> 848 0669 6988</p>
                    <p class="txt-black"><strong>Contraseña:</strong> 89282</p>
                </div>
            </template>

            <template v-if="target == 'Perjeta' || target == 'Kadcyla' || target == 'Herceptin'">
                <div class="cp-me-item">
                    <h3 class="txt-center txt-black">Aplicación móvil HER2min</h3>
                    <br>
                    <p class="txt-black">Te invitamos a conocer la aplicación de HER2min donde se ilustra la preparación de Perjeta<sup>&reg;</sup>, Kadcyla<sup>&reg;</sup> y Herceptin<sup>&reg;</sup>.</p>
                    <br>
                    <p class="txt-black">Descárgala a continuación para:</p>
                    <br>
                    <p class="txt-black">iOS: <a target="_blank" href="https://apple.co/3mpysnr">https://apple.co/3mpysnr</a></p>
                    <p class="txt-black">Android: <a target="_blank" href="https://bit.ly/3fMnUOb">https://bit.ly/3fMnUOb</a></p>
                    <br>
                    <p class="txt-black">Está enfocada para HCPs y enfermeras.</p>
                    <p class="txt-black">No tiene costo y se puede utilizar sin internet.</p>
                </div>
            </template>
            
            <br>
            <p class="txt-black txt-center">Tipo de servicio: Adicional y definido de acuerdo a cada servicio disponible para cada línea terapéutica.</p>
        </ServiceContent>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                items: 0,
                target: "",
            }
        },
        methods: {
            swipeHandlerLeft(){
                if(this.items <= 2) this.items += 1
                else this.items = 0
            },
            swipeHandlerRight(){
                if(this.items != 0 && this.items <= 2) this.items -= 1
                else this.items = 0
            },
        },
        mounted(){
            this.target = this.$route.query.target || "all"
        }
    }
</script>

<style lang="scss">
    .cp-me-item{
        padding: 25px;
    }

    .arrows-outside.ivu-carousel{
        & .ivu-carousel-arrow{
            margin: 0 -40px !important;
        }
        & .ivu-carousel-arrow{
            background: transparent !important;
            color: #008FD5 !important;
            &:hover{
                background: rgba(#008FD5, 0.3) !important;
                color: #008FD5 !important;
            }
        }
    }

    .blue-dots.ivu-carousel{
        & .ivu-carousel-dots li.ivu-carousel-active > button.radius{
            background: #008FD5 !important;
        }
        & .ivu-carousel-dots li button{
            background: rgba(#008FD5, 0.6) !important;

        }
    }
</style>