<template>
    <div class="empty-content">
        <Icon size="80" :type="icon" />
        <h2>{{ title }}</h2>
        <p>{{ content }}</p>
    </div>
</template>

<script>
    export default {
        props: {
            icon: { required: false, type: String, default: 'md-image' },
            title: { required: false, type: String, default: 'Esta sección no tiene contenido' },
            content: { required: false, type: String, default: '' }
        }
    }
</script>

<style lang="sass" scoped>
    .empty-content
        text-align: center
</style>