<template>
    <div style="background-color: white; color: #006fb9;">
        <ServiceContent footer_color="#006fb9">
            <div slot="header" class="image-header">
                <img style="width: 100%;" src="/images/doctor-services/Aplicacion-CentroInfusion.png" alt="">
            </div>
            <p class="service-title">Aplicación en centros de infusión</p>
            <ul class="service">
                <li>
                    <span>Contamos con una robusta red de centros de infusión a nivel nacional los cuales cuentan con los más altos elementos médicos, de enfermería, instalación, calidad y atención personalizada al paciente.</span>
                </li>
                <template v-if="target != 'Hemlibra'">
                    <li>
                        <span>En estos centros podemos realizar la aplicación intravenosa <template v-if="target != 'Ocrevus'">y/o subcutánea,</template> de acuerdo a la instrucción del médico tratante.</span>
                    </li>
                    <li>
                        <span>La selección del centro de infusión es a elección del médico tratante en acuerdo previo con el paciente.</span>
                    </li>
                    <li>
                        <span>De manera constante buscamos los más altos estándares de calidad y seguridad para el paciente.</span>
                    </li>
                    <li>
                        <span>Descubre los centros de infusión del Programa Por Ti con los que tenemos convenio para cubrir el costo de la infusión.</span>
                    </li>
                </template>
            </ul>
            <br>
            <p class="txt-black txt-center">Tipo de servicio: Adicional y definido de acuerdo a cada servicio disponible para cada línea terapéutica.</p>
        </ServiceContent>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                target: '',
            }
        },
        mounted(){
            this.target = this.$route.query.target || 'all'
        }
    }
</script>