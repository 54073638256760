import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        pages: [],
        doctor_signup: null,
        services_base: [],
        patient_services: [],
        services_diagnosis: [],
        keep_services_alive: false,
        services_additionals: [],
    },
    mutations: {
        SET_USER: ( state, user ) => {
			state.user = user
        },
        SET_PAGES: ( state, pages ) => {
			state.pages = pages
        },
        UPDATE_USER_FIELD: (state, { field, value }) => {
            state.user[field] = value
        },
        SET_DOCTOR_SIGNUP: ( state, data ) => {
			state.doctor_signup = data
        },
        SET_SERVICES_DIAGNOSIS: (state, service) => {
            state.services_diagnosis = service
        },
        SET_PATIENT_SERVICES: (state, services) => {
            state.patient_services = services
        },
        SET_PATIENT_SERVICE: (state, payload) => {
            if ( payload.parent_id != undefined) {
                let i = window._.findIndex(state.patient_services[payload.category][payload.parent_id].services, s => s.id == payload.service.id)
                if( i != -1 ) state.patient_services[payload.category][payload.parent_id].services[i] = payload.service
            }
            else {
                let i = window._.findIndex(state.patient_services[payload.category], s => s.id == payload.service.id)
                if( i != -1 ) state.patient_services[payload.category][i] = payload.service
            }
        },
        SET_KEEP_SERVICES_ALIVE: (state, keep) => {
            state.keep_services_alive = keep
        },
    },
    actions: {
        SET_USER: ({ commit }, { user }) => {
			commit("SET_USER", user)
        },
        SET_PAGES: ({ commit }, { pages }) => {
			commit("SET_PAGES", pages)
        },
        UPDATE_USER_FIELD: ( { commit }, { field, value } ) => {
            commit("UPDATE_USER_FIELD", { field: field, value: value })
        },
        SET_DOCTOR_SIGNUP: ({ commit }, { data }) => {
			commit("SET_DOCTOR_SIGNUP", data)
        },
        SET_SERVICES_DIAGNOSIS: ({ commit }, service) => {
            commit('SET_SERVICES_DIAGNOSIS', service)
        },
        SET_PATIENT_SERVICES: ({ commit }, services) => {
            commit('SET_PATIENT_SERVICES', services)
        },
        SET_PATIENT_SERVICE: ({ commit }, payload) => {
            commit('SET_PATIENT_SERVICE', payload)
        },
        SET_KEEP_SERVICES_ALIVE: ({ commit }, keep) => {
            commit('SET_KEEP_SERVICES_ALIVE', keep)
        },
    },
    getters: {
        user: state => state.user,
        pages: state => state.pages,
        doctor_signup: state => state.doctor_signup,
        services_base: state => state.services_base,
        patient_services: state => state.patient_services,
        services_diagnosis: state => state.services_diagnosis,
        keep_services_alive: state => state.keep_services_alive,
        services_additionals: state => state.services_additionals,
	}
})
