<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h1 class="section-title">Videos informativos</h1>
            
            <Input prefix="ios-search" v-model="search" placeholder="Buscar video" style="width: 100%" />
            <br><br>
            <div style="height: 45px; overflow: auto; white-space: nowrap;">
                <Tag v-for="(tag, i) in tags" :key="`tags_i_${i}`" size="large" :color="isTagSelected(tag) ? 'primary' : 'default'" @click.native="toggleTag(tag)">{{ tag }}</Tag>
            </div>

            <Row>
                <i-col v-for="item in Items" :key="`content_item_${item.id}`" :xs="24" :sm="24" :md="12" :lg="8">
                    <div class="content--video-img">
                        <p class="content--video-img--title">{{ item.title }}</p>
                        <template v-if="item.type == 'video'">
                            <div class="content--video-img--media">
                                <youtube :video-id="item.url" player-width="100%" />
                            </div>
                        </template>

                        <template v-if="item.type == 'image'">
                            <div class="content--video-img--media">
                                <img :src="item.the_url" alt="">
                            </div>
                        </template>
                        <p class="content--video-img--description">{{ item.description }}</p>
                    </div>
                </i-col>
            </Row>
        </TemplateContent>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                tags: [],
                search: '',
                content: [],
                selected_tags: [],
            }
        },
        computed: {
            Items(){
                if( this.search == '' ) return this.ItemsWithTags
                else {
                    return window._.filter(this.ItemsWithTags, (item) => {
                        return item.title.toUpperCase().includes(this.search.toUpperCase()) || item.description.toUpperCase().includes(this.search.toUpperCase())
                    })
                }
            },
            ItemsWithTags(){
                return window._.filter(this.content, (item) => {
                    return this.selected_tags.every(i => item.the_tags.includes(i))
                })
            }
        },
        methods: {
            getVideos(){
                window.axios.post(this.$root.API_URL+ `/content/get-videos`)
                .then(response => {
                    if(response.data.success){
                        this.content = response.data.content
                    }
                })
            },
            getTags(){
                window.axios.post(this.$root.API_URL+ `/content/get-tags`, { type: 'doctor-video' })
                .then(response => {
                    if(response.data.success){
                        this.tags = response.data.tags
                    }
                })
            },
            isTagSelected(tag){
                let ii = window._.findIndex(this.selected_tags, (t) => {
                    return t == tag
                })

                return ii != -1
            },
            toggleTag(tag){
                let ii = window._.findIndex(this.selected_tags, (t) => {
                    return t == tag
                })

                if( ii == -1 ) this.selected_tags.push(tag)
                else this.selected_tags.splice(ii, 1)
            }
        },
        mounted(){
            this.getTags()
            this.getVideos()
        }
    }
</script>