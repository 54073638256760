<template>
    <TemplateContent>
        <h1 class="section-title">Recuperar contraseña</h1>
         <Form ref="resetForm" :model="reset" :rules="ruleInline">
            <FormItem prop="user">
                <p class="form-label">Usuario</p>
                <Input size="large" type="email" v-model="reset.user" placeholder=""/>
            </FormItem>
            <FormItem>
                <Button :loading="loading" size="large" style="margin-top: 15px" long type="primary" @click="resetPassword">Recuperar</Button>
                <Button :loading="loading" size="small" style="margin-top: 15px; float: right; color: white;" type="text" @click="$router.push('/sign-in')">O puedes iniciar sesión</Button>
            </FormItem>
        </Form>
    </TemplateContent>
</template>

<script>
    export default {
        data(){
            return {
                loading: false,
                reset: {
                    user: "",
                },
                ruleInline: {
                    user: [
                        {
                            required: true, 
                            message: 'Usuario es requerido', 
                            trigger: 'blur' 
                        }
                    ],
                },
            }
        },
        methods: {
            resetPassword(){
                this.$refs['resetForm'].validate(valid => {
                    if(valid){
                        this.loading = true
                        /*global axios */
                        axios.post(this.$root.API_URL+"/auth/password-reset", { email: this.reset.user, type: "pwa" })
                        .then(response => {
                            if(response.data.success){
                                this.reset.user = ""
                                this.loading = false
                                this.$Message.success("La solicitud fue recibida exitosamente. Revisa tu correo electrónico para seguir con el proceso...")
                            }
                            else {
                                this.$Message.error(response.data.message)
                                this.loading = false
                            }
                        })
                        .catch(() => {
                            this.$Message.error({
                                content: "Algo salió mal",
                                duration: 6,
                            })
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
</script>