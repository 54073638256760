<template>
    <div>
        <div class="app-nav">
            <div v-if="user != null" class="menu-button">
                <Button v-if="$route.fullPath.includes('/dashboard')" @click="drawer=true" type="text" shape="circle" icon="ios-menu"/>
                <Button v-else @click="goBack" type="text" shape="circle" icon="md-arrow-back"/>
            </div>
            <div class="divider"></div>
            <img @click="$router.push('/')" class="roche-logo" src="/images/logo-roche.png" alt="">
            <img @click="$router.push('/')" class="logo" src="/images/logo-por-ti.png">
            <div class="divider"></div>
            <div v-if="user != null" class="menu-button">
                <Badge :count="user.notification_count">
                    <img @click="$router.push('/notifications')" style="width: 30px; height: 30px" src="/images/header/notification-icon.png" alt="">
                </Badge>
            </div>
        </div>
        <Drawer placement="left" v-model="drawer">
            <div class="drawer-top">
                <img class="logo" src="/images/logo-por-ti.png">
            </div>
            <div v-if="user != null" class="drawer-user">
                <div class="avatar">
                    <Avatar size="small" style="background-color: #1a3180">{{ user.name | avatarName }}</Avatar>
                </div>
                <div class="info">
                    <p class="name">{{ user.name }}</p>
                    <p class="code">{{ user.email }}</p>
                </div>
                <iButton @click="logout" type="text" shape="circle" icon="md-exit"/>
            </div>
            <template v-if="user != null">
                <router-link class="drawer-item" to="/doctor/dashboard">
                    <Icon size="17" type="md-home" />
                    <p>Inicio</p>
                </router-link>
                <router-link v-if="isPatient" class="drawer-item" to="/profile">
                    <Icon size="17" type="md-person" />
                    <p>Perfil</p>
                </router-link>
            </template>
        </Drawer>
    </div>
</template>

<script>
    /* global axios, _ */
    import { mapGetters } from "vuex"

    export default {
        data(){
            return {
                drawer: false,
            }
        },
        computed: {
            ...mapGetters([
                "user",
            ]),
            isPatient(){
                let ri = _.findIndex(this.user.roles, (r) => {
                    return r.name == "Paciente"
                })
                return ri != -1 ? true : false
            }
        },
        methods: {
            logout(){
                let self = this

                axios.post(this.$root.API_URL+"/auth/signOut")
                .then(response => {
                    if(response.data.success){
                        window.dexie.config.get({ name: 'API_TOKEN' }, e => {
                            if (e) window.dexie.config.delete(e.id)
                        })
                        setTimeout(() => {
                            self.$store.dispatch("SET_USER", { user: null })
                            self.$router.push("/")
                            self.drawer = false
                        }, 500)
                    }
                })
                .catch(() => {
                    this.$Message.error({
                        content: "Algo salió mal",
                        duration: 6,
                    })
                })
            },
            findNewNotifications(){
                if(this.user){
                    axios.post(this.$root.API_URL+"/users/get-notifications")
                    .then(response => {
                        let total = 0
                        _.each(response.data.notifications, (n) => {
                            if(!n.read) total += 1
                        })
    
                        if(total != this.user.notification_count){
                            this.$store.dispatch("UPDATE_USER_FIELD", {
                                field: 'notification_count',
                                value: total,
                            })
                        }
                    })
                }
            },
            goBack(){
                this.$router.go(-1)
            }
        },
        activated(){
            this.findNewNotifications()
        },
        mounted(){
            /*global EventBus*/
            let self = this

            EventBus.$on("toggle-drawer", (value) => {
                this.drawer = value
            })

            setTimeout(() => {
                self.findNewNotifications()
            }, 1000)
        }
    }
</script>

<style lang="sass">
    .divider
        flex: 1
        
    .app-nav
        display: flex
        align-items: center
        background-color: white !important
        width: 100%
        height: 64px
        & .logo
            width: 80px
            &.margin
                margin-left: -42px
        & .menu-button
            margin-right: 10px
        & .user
            display: flex
            justify-content: center
            align-items: center
            color: #515a6e !important
            & .info
                margin-right: 8px
        & .roche-logo
            width: 60px
            margin-right: 10px
            margin-left: -10px
    .drawer-top
        padding: 10px 0
        margin-bottom: 10px
        & img
            width: 150px
    .drawer-item
        padding: 8px 0
        display: flex
        align-items: center
        cursor: pointer
        color: #515a6e !important
        & .ivu-icon
            margin-right: 15px
        & p
            font-size: 15px

    .drawer-user
        padding: 0 0 15px 0
        border-bottom: 1px solid #ececec
        display: flex
        justify-content: center
        align-items: center
        margin-bottom: 15px
        & p
            line-height: 1.6
            // text-align: right
        & .info
            flex: 1
            margin-left: 7px
            & .name
                font-weight: bold
                font-size: 0.9em
            & .code
                font-size: 0.8em
                color: grey
    
    .transferIconRotate
        transform: rotate(90deg)
    
    .ivu-badge
        display: flex !important

    .ivu-badge-count
        background: #02128f !important
</style>
