<template>
    <Grid/>
</template>

<script>
    import Grid from "./../../components/Doctor/MyPatients/Details/components/Forms/Grid"

    export default {
        components: {
            Grid,
        }    
    }
</script>