<template>
    <li>
        <b>Diagnóstico</b>
        
        <template v-if="product == 'Alecensa'">
            <p style="padding-left: 20px; padding-top: 5px;">Roche ofrece en conjunto con el Consorcio Farmacéutico pruebas de inmunohistoquímica para diagnosticar a los pacientes con cáncer de pulmón de células no pequeñas adenocarcinoma. Los marcadores incluidos son: EFGR, ALK, ROS1, KRAS y PDL-1. Le pedimos comunicarse al 55 3888 6976 para recibir mayor información.</p>
        </template>

        <template v-else>
            <p style="margin-top: 10px">Estudios para apoyar en el diagnóstico oportuno (previo al uso de la terapia).</p>
            <div class="ul-items-childs">
                <div v-for="(dig, i) in Services" :key="'dig_'+i">
                    <template v-if="dig.category == 'legend'">
                        <div class="child-item title">
                            <p class="title">{{ getLegendTitle(dig.name) }}</p>
                            <div class="child-subitems">
                                <div v-for="(child, i) in dig.services" :key="'cc_'+i">
                                    <div v-if="child.can_be_listed == true" class="child-subitem-itm">
                                        <p>{{ child.name }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="child-item">
                            <p v-html="dig.name"></p>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </li>
</template>

<script>
    export default {
        props: {
            product: { required: true, type: String },
            services: { required: true, type: [Object, Array] },
        },
        computed: {
            Services(){
                let ss = window._.each(this.services, (service) => {
                    if(typeof service.pivot.pivot != 'object'){
                        service.pivot.pivot = JSON.parse(service.pivot.pivot || "{\n\t\"order\": 99999\n}" )
                    }
                })

                return window._.sortBy(ss, ['pivot.pivot.order'])
            },
        },
        methods: {
            getLegendTitle(name){
                if(name == 'Hematología:') return 'Diagnóstico de hematología:'
                else if(name == 'Molecular:') return 'Diagnóstico molecular:'
                else return name
            }
        }
    }
</script>