<template>
    <div>
        <div class="pathology-grid">
            <div class="header doctor"></div>
        </div>
        <TemplateContent>
            <h1 class="section-title">Asociaciones para pacientes</h1>
            <p>Las asociaciones para pacientes organizan encuentros y pláticas con profesionales de la salud y pacientes que padecen la misma patología.</p>
            <br>
            <p>Estas comunidades facilitan al paciente compartir experiencias con otras personas, entendimiento de la enfermedad y su integración de la sociedad.</p>
            <br>
            <p>A continuación encontrará las asociaciones disponibles para sus pacientes:</p>
            <br>
            <Input prefix="ios-search" v-model="search" placeholder="Buscar" style="width: 100%" />
            <br><br>
            <div style="height: 45px; overflow: auto; white-space: nowrap;">
                <Tag v-for="(tag, i) in tags" :key="`tags_i_${i}`" size="large" :color="isTagSelected(tag) ? 'primary' : 'default'" @click.native="toggleTag(tag)">{{ tag }}</Tag>
            </div>

            <Row>
                <i-col v-for="item in Items" :key="`content_item_${item.id}`" :xs="24" :sm="24" :md="12" :lg="8">
                    <div :class="{ 'less-top': item.title == null }" class="content--video-img">
                        <p v-if="item.title != null" class="content--video-img--title">{{ item.title }}</p>
                        <div class="content--video-img--media">
                            <img :src="item.the_url" alt="">
                             <!-- <el-image style="width: 100%;" :src="item.the_url" :preview-src-list="[item.the_url]" /> -->
                            <div style="display: flex">
                                <p :ref="`item_sharable_id_${item.id}`" style="flex-grow: 1; margin-top: 10px" v-html="item.description " class="content--video-img--description"></p>
                                <!-- <div class="content--video-img--share">
                                    <Icon @click="share(item)" color="white" size="25" type="md-share-alt" />
                                </div> -->
                            </div>
                            <div @click="share(item)" style="padding: 15px 15px 0 15px; display: flex; align-items: center; justify-content: center;">
                                <p style="font-weight: bold; margin-right: 5px">Compartir</p>
                                <Icon color="white" size="25" type="md-share-alt" />
                            </div>
                        </div>
                    </div>
                </i-col>
            </Row>

            <el-dialog class="doctor-dialog" title="" :visible.sync="show_share_popup" width="90%">
                <h2 style="text-align: center; word-break: break-word; color: white; font-weight: bold;">Compartir información</h2>
                <br>
                <h3 style="text-align: center; word-break: break-word; color: white; font-weight: normal;">Comparta este link dando clic en el botón "Copiar enlace"</h3>
                <br>
                <Input class="share-input" disabled v-model="share_popup_link" size="large" />
                <br><br>
                <Button @click="copyToClipboard" long type="primary">Copiar enlace</Button>

            </el-dialog>
        </TemplateContent>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                tags: [],
                search: '',
                content: [],
                selected_tags: [],
                share_popup_link: '',
                show_share_popup: false,
            }
        },
        computed: {
            Items(){
                if( this.search == '' ) return this.ItemsWithTags
                else {
                    return window._.filter(this.ItemsWithTags, (item) => {
                        return item.title != null ? item.title.toUpperCase().includes(this.search.toUpperCase()) : false
                    })
                }
            },
            ItemsWithTags(){
                return window._.filter(this.content, (item) => {
                    return this.selected_tags.every(i => item.the_tags.includes(i))
                })
            }
        },
        methods: {
            getAssociations(){
                window.axios.post(this.$root.API_URL+ `/content/get-patient-associations`)
                .then(response => {
                    if(response.data.success){
                        this.content = response.data.associations
                    }
                })
            },
            getTags(){
                window.axios.post(this.$root.API_URL+ `/content/get-tags`, { type: 'associations' })
                .then(response => {
                    if(response.data.success){
                        this.tags = response.data.tags
                    }
                })
            },
            isTagSelected(tag){
                let ii = window._.findIndex(this.selected_tags, (t) => {
                    return t == tag
                })

                return ii != -1
            },
            toggleTag(tag){
                let ii = window._.findIndex(this.selected_tags, (t) => {
                    return t == tag
                })

                if( ii == -1 ) this.selected_tags.push(tag)
                else this.selected_tags.splice(ii, 1)
            },
            share(item){
                let href = window.$(this.$refs[`item_sharable_id_${item.id}`]).find('a').attr('href');
                if( href != '' ){
                    this.share_popup_link = href 
                    this.show_share_popup = true
                }
            },
            copyToClipboard(){
                navigator.clipboard.writeText(this.share_popup_link)
                this.$Message.success('Enlace copiado exitosamente');
            },
        },
        mounted(){
            this.getTags()
            this.getAssociations()
        }
    }
</script>

<style lang="scss">
    .less-top{
        margin-top: -25px !important;
    }
</style>