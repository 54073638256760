import Vue from 'vue'
import VueRouter from 'vue-router'

import multiguard from 'vue-router-multiguard';
import { auth, guest, doctor } from "./guards"

import DoctorReport from './../views/DoctorReport'

import SignIn from "./../views/SignIn"
import Landing from "./../views/Landing"
import Error404 from "./../views/404Error"
import Resources from "./../views/Resources"
import LegalGrid from './../views/Legal'
import PrivacyPolicy from "./../views/PrivacyPolicy"
import PasswordResetGrid from "./../views/PasswordResetGrid"
import PasswordResetSafe from "./../views/PasswordResetSafe"
import NotificationsGrid from "./../views/Notifications"
import PasswordResetForm from "./../views/PasswordResetForm"

import DoctorSignUp from "./../views/Doctor/SignUp"
import DoctorContact from "./../views/Doctor/Contact"
import DoctorServices from "./../views/Doctor/Services"
import DoctorDashboard from './../views/Doctor/Dashboard'
import DoctorMyPatients from "./../views/Doctor/MyPatients"
import DoctorSignUpPatient from "./../views/Doctor/PatientSignUp"
import DoctorsServicesAbout from './../views/Doctor/About'
import DoctorsServicesAccess from './../views/Doctor/ServicesAccess'
import DoctorMyPatientDetails from "./../views/Doctor/MyPatientDetails"
import DoctorMyPatientDetailsForm from "./../views/Doctor/MyPatientServiceForm"
import DoctorsServicesProgramDetails from "./../views/Doctor/ProgramDetails"

import DoctorsServicesPrograms from './../views/Doctor/Programs'
import DoctorsServicesPatients from './../views/Doctor/ServicesPatients'
import DoctorsServicesInduccion from './../views/Doctor/ServicesInduccion'
import DoctorsServicesDiagnosis from './../views/Doctor/ServicesDiagnosis'
import DoctorsServicesAdherencia from './../views/Doctor/ServicesAdherencia'
// import DoctorServicesCoachDeVida from "./../views/Doctor/ServicesCoachDeVida"
// import DoctorsServicesInformation from './../views/Doctor/ServicesInformation'
// import DoctorServicesLaboratories from './../views/Doctor/ServicesLaboratories'
import DoctorsServicesPatientsBase from './../views/Doctor/ServicesPatientsBase'
import ServicesPatientsAssociations from './../views/Doctor/ServicesPatientsAssociations'
import DoctorServicesRedDeEnfermeras from "./../views/Doctor/ServicesRedDeEnfermeras"
// import DoctorServicesLaboratoriesList from './../views/Doctor/ServicesLaboratoriesList'
import DoctorServicesApoyoDiagnostico from "./../views/Doctor/ServicesApoyoDiagnostico"
import DoctorServicesProgramaBienestar from "./../views/Doctor/ServicesProgramaBienestar"
import DoctorServicesCentrosDeInfusion from "./../views/Doctor/ServicesCentrosDeInfusion"
import DoctorServicesAtencionPacientes from "./../views/Doctor/ServicesAtencionPacientes"
import DoctorsServicesInformationVideos from './../views/Doctor/ServicesInformationVideos'
import DoctorsServicesInfusionDomicilio from './../views/Doctor/ServicesInfusionDomicilio'
import DoctorsServicesCapacitacionMedica from './../views/Doctor/ServicesCapacitacionMedica'
import DoctorsServicesInformationWithYou from './../views/Doctor/ServicesInformationWithYou'
import DoctorsServicesPatientsAditionals from './../views/Doctor/ServicesPatientsAditionals'
import DoctorServicesGestoriaAseguradora from "./../views/Doctor/ServicesGestoriaAseguradora"
import DoctorServicesInicioDeTratamiento from "./../views/Doctor/ServicesInicioDeTratamiento"
// import DoctorServicesAsesoriaNutricional from "./../views/Doctor/ServicesAsesoriaNutricional"
import DoctorsServicesInformationCommunity from './../views/Doctor/ServicesInformationCommunity'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'landing',
        component: Landing,
        beforeEnter: multiguard([guest]),
    },
    {
        path: '/reporte-medico/:uid',
        name: 'doctor-report',
        component: DoctorReport,
    },
    {
        path: '/reporte-doctor/:uid',
        name: 'doctor-report',
        component: DoctorReport,
    },
    {
        path: '/sign-in',
        name: 'sign-in',
        component: SignIn,
        beforeEnter: multiguard([guest]),
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: PasswordResetGrid,
        beforeEnter: multiguard([guest]),
    },
    {
        path: '/password-reset-safe',
        component: PasswordResetSafe,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/password-reset/:token',
        name: 'password-reset-form',
        component: PasswordResetForm,
        beforeEnter: multiguard([guest]),
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: NotificationsGrid,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/avisolegal',
        component: LegalGrid,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy,
        beforeEnter: multiguard([guest]),
    },
    {
        path: '/resources',
        name: 'resources',
        component: Resources,
        beforeEnter: multiguard([auth]),
    },
    {
        path: '/resources/info',
        component: DoctorsServicesInformationCommunity,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/resources/videos',
        component: DoctorsServicesInformationVideos,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/resources/associations',
        component: ServicesPatientsAssociations,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/resources/with-you',
        component: DoctorsServicesInformationWithYou,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/sign-up',
        component: DoctorSignUp,
        beforeEnter: multiguard([guest]),
    },
    {
        path: '/doctor/dashboard',
        name: 'dashboard',
        component: DoctorDashboard,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/contact',
        name: 'doctor-contact',
        component: DoctorContact,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services',
        component: DoctorServices,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/about',
        component: DoctorsServicesAbout,
        beforeEnter: multiguard([auth, doctor]),
    },
    // {
    //     path: '/doctor/services/information',
    //     component: DoctorsServicesInformation,
    //     beforeEnter: multiguard([auth, doctor]),
    // },
    {
        path: '/doctor/services/programs',
        component: DoctorsServicesPrograms,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/programs/:slug',
        component: DoctorsServicesProgramDetails,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/patients',
        component: DoctorsServicesPatients,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/patients/base',
        component: DoctorsServicesPatientsBase,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/patients/aditionals',
        component: DoctorsServicesPatientsAditionals,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/patients/aditionals/adherencia',
        component: DoctorsServicesAdherencia,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/patients/aditionals/adherencia/induccion',
        component: DoctorsServicesInduccion,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/patients/aditionals/adherencia/infusion',
        component: DoctorsServicesInfusionDomicilio,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/patients/aditionals/adherencia/capacitacion-medica',
        component: DoctorsServicesCapacitacionMedica,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/patients/aditionals/diagnosis',
        component: DoctorsServicesDiagnosis,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/patients/aditionals/access',
        component: DoctorsServicesAccess,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/patients/base/atencion-pacientes',
        component: DoctorServicesAtencionPacientes,
        beforeEnter: multiguard([auth, doctor]),
    },
    // {
    //     path: '/doctor/services/patients/base/coach-vida',
    //     component: DoctorServicesCoachDeVida,
    //     beforeEnter: multiguard([auth, doctor]),
    // },
    {
        path: '/doctor/services/patients/aditionals/adherencia/inicio-tratamiento',
        component: DoctorServicesInicioDeTratamiento,
        beforeEnter: multiguard([auth, doctor]),
    },
    // {
    //     path: '/doctor/services/patients/base/asesoria-nutricional',
    //     component: DoctorServicesAsesoriaNutricional,
    //     beforeEnter: multiguard([auth, doctor]),
    // },
    {
        path: '/doctor/services/patients/base/red-enfermeras',
        component: DoctorServicesRedDeEnfermeras,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/patients/aditionals/adherencia/apoyo-diagnostico',
        component: DoctorServicesApoyoDiagnostico,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/patients/base/gestoria-aseguradora',
        component: DoctorServicesGestoriaAseguradora,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/patients/base/programa-bienestar',
        component: DoctorServicesProgramaBienestar,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/services/patients/aditionals/adherencia/centros-infusion',
        component: DoctorServicesCentrosDeInfusion,
        beforeEnter: multiguard([auth, doctor]),
    },
    // {
    //     path: '/doctor/services/laboratories',
    //     component: DoctorServicesLaboratories,
    //     beforeEnter: multiguard([auth, doctor]),
    // },
    // {
    //     path: '/doctor/services/laboratories/list',
    //     component: DoctorServicesLaboratoriesList,
    //     beforeEnter: multiguard([auth, doctor]),
    // },
    {
        path: '/doctor/patient/sign-up',
        component: DoctorSignUpPatient,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/my-patients',
        component: DoctorMyPatients,
        beforeEnter: multiguard([auth, doctor]),
    },
    // {
    //     path: '/doctor/my-patient',
    //     component: DoctorMyPatientDetails,
    //     beforeEnter: multiguard([auth, doctor]),
    // },
    {
        path: '/doctor/my-patient/:id',
        name: 'PatientDetails',
        component: DoctorMyPatientDetails,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '/doctor/my-patient/:id/form/:formid',
        name: 'PatientServiceForm',
        component: DoctorMyPatientDetailsForm,
        beforeEnter: multiguard([auth, doctor]),
    },
    {
        path: '*',
        component: Error404,
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
